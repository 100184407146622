.divider {
	&.divider--transparent {
		border-bottom-color: transparent;
	}

	&.full {
		margin: 0;
		border: 0;
		height: unset;
	}
}
