@use 'lib/styles/defs/breakpoints';

/* shadow box values for teasers in navigation style container */
$navigation_shadow_first:
	0px 13px 16px -9px rgba(51, 51, 51, 0.08),
	-10px 0px 16px -10px rgba(51, 51, 51, 0.08),
	0px -8px 16px -13px rgba(51, 51, 51, 0.08);
$navigation_shadow_last:
	0px 13px 16px -9px rgba(51, 51, 51, 0.08),
	10px 0px 16px -13px rgba(51, 51, 51, 0.08),
	0px -8px 16px -13px rgba(51, 51, 51, 0.08);
$navigation_shadow_middle:
	0px 13px 16px -9px rgba(51, 51, 51, 0.08),
	0px -8px 16px -9px rgba(51, 51, 51, 0.08);

.cmp-container {
	z-index: auto;

	.z-index-0 & {
		z-index: 0;
	}
}

.cmp-container_fullbleed {
	left: 50%;
	margin-left: -50vw;
	margin-right: -50vw;
	max-width: 100vw;
	position: absolute;
	right: 50%;
	width: 100vw;
	z-index: -1;
	height: inherit;

	&.cmp-container_max_fullbleed {
		max-width: 1920px;
		@media screen and (min-width: 1920px) {
			margin-left: -960px;
			margin-right: -960px;
		}
	}
}

.cmp-container_regular {
	position: absolute;
	width: 100%;
	z-index: -1;
	height: inherit;
}

.cmp-container_stage {
	margin-top: -8px;
}

.cmp-container_height {
	position: relative;
	height: 100%;
}

.cmp-container_fullbleed,
.cmp-container_regular {
	overflow: hidden;

	video {
		object-fit: cover;
		height: 100%;
		width: 100%;
		min-width: 100%;
		min-height: 100%;
	}

	.background-video-img {
		min-width: 100%;
		width: 100%;
		height: inherit;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
	}

	.component-container.rounded-corners > div > & {
		border: 1px solid var(--sdx-color-gray-tint-7);
		border-radius: 12px;
	}

	.component-container.remove-border > div > & {
		border: none;
	}

	.component-container.add-hover .cmp-container:hover > & {
		border: 1px solid var(--sdx-color-gray-tint-7);
		border-color: var(--sdx-color-gray-tint-4);
		box-shadow: color-mix(in srgb, var(--sdx-color-gray) 16%, transparent) 0px 4px 16px 0px;
		transition: all 200ms ease-in-out 0s;
		cursor: pointer;
	}

	&.img-wrapper.has-image {
		background-size: cover;
		background-repeat: no-repeat;
	}
}

.bg-x-left {
	.cmp-container_fullbleed.has-image,
	.cmp-container_regular.has-image,
	.background-video-img {
		background-position-x: left;
	}
}

.bg-x-center {
	.cmp-container_fullbleed.has-image,
	.cmp-container_regular.has-image,
	.background-video-img {
		background-position-x: center;
	}
}

.bg-x-right {
	.cmp-container_fullbleed.has-image,
	.cmp-container_regular.has-image,
	.background-video-img {
		background-position-x: right;
	}
}

.bg-y-top {
	.cmp-container_fullbleed.has-image,
	.cmp-container_regular.has-image,
	.background-video-img {
		background-position-y: top;
	}
}

.bg-y-center {
	.cmp-container_fullbleed.has-image,
	.cmp-container_regular.has-image,
	.background-video-img {
		background-position-y: center;
	}
}

.bg-y-bottom {
	.cmp-container_fullbleed.has-image,
	.cmp-container_regular.has-image,
	.background-video-img {
		background-position-y: bottom;
	}
}

.bg-size-contain {
	.cmp-container_fullbleed.img-wrapper.has-image,
	.cmp-container_regular.img-wrapper.has-image {
		background-size: contain;
	}
}

.container-text-center {
	.cmp-container {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.container-text-bottom {
	.cmp-container {
		display: flex;
		justify-content: flex-end;
		align-items: flex-end;
	}
}

@mixin container-flex-style {
	flex-wrap: nowrap;
	.aem-Grid-newComponent {
		width: 100%;
		position: absolute;
		left: 0;
		top: initial;
		bottom: -40px;
		height: 20px;
	}
}

.component-container {
	// Container is a structural element, remove the inherent column margins
	&.aem-GridColumn {
		margin: 0;
	}

	&.outer_gutter {
		margin-left: -4px;
		margin-right: -4px;

		@media screen and (min-width: breakpoints.$breakpoint-md) {
			margin-left: -8px;
			margin-right: -8px;
		}

		@media screen and (min-width: breakpoints.$breakpoint-lg) {
			margin-left: -12px;
			margin-right: -12px;
		}

		.aem-Grid &.aem-GridColumn {
			width: calc(100% + 8px);

			@media screen and (min-width: breakpoints.$breakpoint-md) {
				width: calc(100% + 16px);
			}
			@media screen and (min-width: breakpoints.$breakpoint-lg) {
				width: calc(100% + 24px);
			}
		}
	}

	&.fixed-max-width .cmp-container {
		max-width: 1380px;
		margin: 0 auto;
	}

	.root > .aem-Grid > .responsivegrid &.no_gutter.no_gutter,
	.xp-fragment-container > .root > .aem-Grid &.no_gutter.no_gutter {
		[class*='aem-GridColumn'] {
			margin: 0 -1px -1px 0;
			padding: 0;

			[class*='sc-teaser'] {
				margin-bottom: 0;
			}
		}
	}

	&.flex,
	&.flex-large,
	&.flex-small {
		.aem-Grid {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;

			[class*='aem-GridColumn'] {
				float: none !important;

				[class*='sc-teaser'] {
					height: 100%;
				}

				.modHoverContainer {
					height: 100%;
				}

				.modUbcExchangeGenerator.modUrlPlaceholders {
					height: 100%;
					> div:not(.authorInfo) {
						height: inherit;
						> div.aem-Grid {
							height: inherit;
						}
					}
				}
			}
		}
	}

	&.flex {
		.aem-Grid {
			@media screen and (min-width: breakpoints.$breakpoint-md) {
				@include container-flex-style();
			}
		}
	}

	&.flex-large {
		.aem-Grid {
			@media screen and (min-width: breakpoints.$breakpoint-lg) {
				@include container-flex-style();
			}
		}
	}

	&.flex-small {
		.aem-Grid {
			@media screen and (min-width: breakpoints.$breakpoint-sm) {
				@include container-flex-style();
			}
		}
	}

	&.flex-ribbon {
		> div > div > .aem-Grid {
			@media screen and (min-width: breakpoints.$breakpoint-md) {
				display: flex;
				flex-direction: row;

				sdx-ribbon {
					display: flex;
					height: 100%;
					flex-direction: column;

					.ribbon-border {
						height: 100%;
					}
				}
			}
		}
	}

	&.flex-combo {
		.aem-Grid {
			[class*='aem-GridColumn'] {
				float: none !important;

				[class*='sc-teaser'] {
					height: 100%;
				}

				.modHoverContainer {
					height: 100%;
				}
			}

			@media screen and (min-width: breakpoints.$breakpoint-md) {
				@include container-flex-style();
			}
		}
	}

	&.wrap-text {
		&.flex-small {
			@media screen and (min-width: breakpoints.$breakpoint-sm) {
				.aem-Grid {
					flex-wrap: wrap;
				}
			}
		}

		&.flex {
			@media screen and (min-width: breakpoints.$breakpoint-md) {
				.aem-Grid {
					flex-wrap: wrap;
				}
			}
		}

		&.flex-large {
			@media screen and (min-width: breakpoints.$breakpoint-lg) {
				.aem-Grid {
					flex-wrap: wrap;
				}
			}
		}
	}

	&.navigation-style.no_gutter {
		.teaser:first-of-type {
			box-shadow: $navigation_shadow_first;

			.cmp-teaser {
				border-radius: 12px 0 0 12px;
			}
		}

		.teaser:not(:first-of-type):not(:last-of-type) {
			box-shadow: $navigation_shadow_middle;
		}

		.teaser:last-of-type {
			box-shadow: $navigation_shadow_last;

			.cmp-teaser {
				border-radius: 0 12px 12px 0;
			}
		}

		.teaser {
			cursor: pointer;

			.cmp-teaser a {
				cursor: pointer;
			}
		}
	}

	&.container-center-content {
		&:not(.flex-large):not(.flex) > .cmp-container > div > .aem-Grid {
			@media screen and (min-width: breakpoints.$breakpoint-sm) {
				display: flex;
				justify-content: center;
			}
		}

		&.flex {
			& > .cmp-container > div > .aem-Grid {
				@media screen and (min-width: breakpoints.$breakpoint-md) {
					justify-content: center;
				}
			}
		}

		&.flex-large {
			& > .cmp-container > div > .aem-Grid {
				@media screen and (min-width: breakpoints.$breakpoint-lg) {
					justify-content: center;
				}
			}
		}
	}

	.bg-color-gradient-violet > div.desktop,
	.bg-color-gradient-violet-mobile > div.mobile-only {
		@include gradientViolet;
	}

	.bg-color-gradient-red > div.desktop,
	.bg-color-gradient-red-mobile > div.mobile-only {
		@include gradientRed;
	}

	.bg-color-gradient-blue > div.desktop,
	.bg-color-gradient-blue-mobile > div.mobile-only {
		@include gradientBlue;
	}

	.bg-color-cloud > div.desktop,
	.bg-color-cloud-mobile > div.mobile-only {
		@include gradientCloud;
	}
	.animated-bg-gradient-red > div.desktop,
	.animated-bg-gradient-red-mobile > div.mobile-only {
		@extend %twinkleAnimation;
	}

	.video_overlay:before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}
	&.vertical-separator {
		--vertical-separator-opacity: 50%;
		.aem-Grid > div:not(:last-child, .title, .text),
		.component-card,
		.component-banner {
			border-bottom: 1px solid color-mix(in srgb, var(--sdx-color-gray-tint-8, #d6d6d6) var(--vertical-separator-opacity), transparent);
			@media (min-width: breakpoints.$breakpoint-md) {
				border: 0;
				border-right: 1px solid color-mix(in srgb, var(--sdx-color-gray-tint-8, #d6d6d6) var(--vertical-separator-opacity), transparent);
			}
		}
	}
}

.cluster-4-elements {
	.cmp-container {
		.aem-Grid {
			display: flex;
			flex-wrap: wrap;
			.component-card {
				margin-bottom: 16px;
				@media (min-width: breakpoints.$breakpoint-md) {
					animation: 2000ms cubic-bezier(0.4, 0, 0.2, 1) 0.5s 1 ts-cluster-slide-in-from-top-left;
				}
			}
			.component-card:nth-child(1) {
				@media (min-width: breakpoints.$breakpoint-md) {
					margin-left: 16.6666666667%;
					flex: 0 0 50%;
					max-width: 50%;
					align-self: flex-start;
				}
			}
			.component-card:nth-child(2) {
				@media (min-width: breakpoints.$breakpoint-md) {
					padding-top: 25%;
					flex: 0 0 33.3333333333%;
					max-width: 33.3333333333%;
					align-self: flex-end;
					animation-name: ts-cluster-slide-in-from-top-right;
				}
			}
			.component-card:nth-child(3) {
				@media (min-width: breakpoints.$breakpoint-md) {
					flex: 0 0 33.3333333333%;
					max-width: 33.3333333333%;
					align-self: flex-start;
					margin-top: -12%;
					animation-name: ts-cluster-slide-in-from-bottom-left;
				}

				@media (min-width: breakpoints.$breakpoint-lg) {
					margin-top: -10%;
				}
			}
			.component-card:nth-child(4) {
				@media (min-width: breakpoints.$breakpoint-md) {
					flex: 0 0 41.6666666667%;
					max-width: 41.6666666667%;
					align-self: flex-end;
					animation-name: ts-cluster-slide-in-from-bottom-right;
				}
			}
		}
	}
}

.cluster-3-elements {
	.cmp-container {
		.aem-Grid {
			display: flex;
			flex-wrap: wrap;
			.component-card {
				@media (min-width: breakpoints.$breakpoint-md) {
					animation: 2000ms cubic-bezier(0.4, 0, 0.2, 1) 0.5s 1 ts-cluster-slide-in-from-top-left;
				}
			}
			.component-card:nth-child(1) {
				@media (min-width: breakpoints.$breakpoint-md) {
					flex: 0 0 58.3333333333%;
					max-width: 58.3333333333%;
					margin-bottom: 16px;
					margin-top: 10%;
					min-height: 550px;
				}
			}
			.component-card:nth-child(2) {
				@media (min-width: breakpoints.$breakpoint-md) {
					max-width: calc(41.666% * 0.8333);
					align-self: flex-start;
					animation-name: ts-cluster-slide-in-from-top-right;
					margin-bottom: 16px;
				}
				@media (min-width: breakpoints.$breakpoint-lg) {
					margin-left: 8.3333333333%;
					margin-bottom: 24px;
					max-width: calc(33.3333% * 0.75);
				}
			}
			.component-card:nth-child(3) {
				@media (min-width: breakpoints.$breakpoint-md) {
					max-width: calc(41.666% * 0.8333);
					align-self: flex-end;
					animation-name: ts-cluster-slide-in-from-bottom-left;
					margin-left: calc(58.3333333333% + (41.6666% * 0.166));
					margin-top: -20%;
				}
				@media (min-width: breakpoints.$breakpoint-lg) {
					flex: 0 0 calc(33.3333% * 0.75);
					max-width: calc(33.3333% * 0.75);
					margin-left: calc(66.6666666667% + (41.6666% * 0.166));
				}
			}
		}
	}
}

.teaser-cluster {
	.cmp-container {
		.aem-Grid {
			display: grid;
			grid-template-columns: 1fr;
			grid-template-rows: none;
			gap: 20px 0;
			position: relative;
			width: 100%;

			.component-card {
				width: inherit;
				grid-row: auto;
				height: 520px;
			}
		}
	}

	@media (min-width: breakpoints.$breakpoint-lg) {
		.cmp-container {
			.aem-Grid {
				grid-template-columns: repeat(2, 1fr);
				grid-template-rows: auto auto;
				gap: 20px 10px;

				.component-card:nth-child(1),
				.component-card:nth-child(4) {
					height: 658px;
				}

				.component-card:nth-child(2),
				.component-card:nth-child(3) {
					height: 560px;
				}

				.component-card:nth-child(1),
				.component-card:nth-child(2) {
					grid-row: 1;
				}

				.component-card:nth-child(3) {
					grid-row: 2;
				}

				.component-card:nth-child(4) {
					grid-row: 2;
					margin-top: -90px;
				}
			}
		}
	}
}
