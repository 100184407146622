.teaser .animated-bg-gradient-red, .hero-banner.animated-bg-gradient-red, .component-container .animated-bg-gradient-red > div.desktop,
.component-container .animated-bg-gradient-red-mobile > div.mobile-only, .component-banner .animated-bg-gradient-red {
  background-image: radial-gradient(transparent 47%, #001155 48%), radial-gradient(ellipse 150% 110% at 20% 10%, #9b1339 4%, transparent 35%), radial-gradient(ellipse 130% 120% at 70% 80%, #0b82dc 4%, transparent 35%), radial-gradient(ellipse 120% 160% at 10% 50%, #9b1339 2%, transparent 30%), radial-gradient(ellipse 100% 110% at 90% 5%, #0b82dc 2%, transparent 30%), radial-gradient(ellipse 100% 70% at 50% 55%, #a63297 3%, transparent 40%), radial-gradient(circle at 50% 50%, #001155, #001155 100%);
  background-repeat: repeat, no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
  animation-name: twinkle;
  animation-duration: 60s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@keyframes twinkle {
  0% {
    background-position: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
    background-size: 5px 5px, 400% 400%, 400% 400%, 400% 400%, 400% 400%, 400% 400%;
  }
  10% {
    background-position: 0% 0%, 50% 20%, 50% 20%, 50% 20%, 50% 20%, 50% 20%;
    background-size: 5px 5px, 350% 350%, 350% 350%, 350% 350%, 350% 350%, 350% 350%;
  }
  20% {
    background-position: 0% 0%, 40% 70%, 40% 70%, 40% 70%, 40% 70%, 40% 70%;
    background-size: 5px 5px, 300% 300%, 300% 300%, 300% 300%, 300% 300%, 300% 300%;
  }
  30% {
    background-position: 0% 0%, 90% 90%, 90% 90%, 90% 90%, 90% 90%, 90% 90%;
    background-size: 5px 5px, 250% 250%, 250% 250%, 250% 250%, 250% 250%, 250% 250%;
  }
  40% {
    background-position: 0% 0%, 70% 20%, 70% 20%, 70% 20%, 70% 20%, 70% 20%;
    background-size: 5px 5px, 150% 150%, 150% 150%, 150% 150%, 150% 150%, 150% 150%;
  }
  50% {
    background-position: 0% 0%, 10% 40%, 10% 40%, 10% 40%, 10% 40%, 10% 40%;
    background-size: 5px 5px, 110% 110%, 110% 110%, 110% 110%, 110% 110%, 110% 110%;
  }
  60% {
    background-position: 0% 0%, 90% 60%, 90% 60%, 90% 60%, 90% 60%, 90% 60%;
    background-size: 5px 5px, 200% 200%, 200% 200%, 200% 200%, 200% 200%, 200% 200%;
  }
  70% {
    background-position: 0% 0%, 20% 80%, 20% 80%, 20% 80%, 20% 80%, 20% 80%;
    background-size: 5px 5px, 250% 250%, 250% 250%, 250% 250%, 250% 250%, 250% 250%;
  }
  80% {
    background-position: 0% 0%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 100% 100%;
    background-size: 5px 5px, 300% 300%, 300% 300%, 300% 300%, 300% 300%, 300% 300%;
  }
  90% {
    background-position: 0% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%;
    background-size: 5px 5px, 350% 350%, 350% 350%, 350% 350%, 350% 350%, 350% 350%;
  }
  100% {
    background-position: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
    background-size: 5px 5px, 400% 400%, 400% 400%, 400% 400%, 400% 400%, 400% 400%;
  }
}
@keyframes ts-cluster-slide-in-from-top-left {
  from {
    transform: translate(-5%, -5%);
  }
  to {
    transform: translate(0);
  }
}
@keyframes ts-cluster-slide-in-from-top-right {
  from {
    transform: translate(5%, -5%);
  }
  to {
    transform: translate(0);
  }
}
@keyframes ts-cluster-slide-in-from-bottom-left {
  from {
    transform: translate(-5%, 5%);
  }
  to {
    transform: translate(0);
  }
}
@keyframes ts-cluster-slide-in-from-bottom-right {
  from {
    transform: translate(5%, 5%);
  }
  to {
    transform: translate(0);
  }
}
@keyframes flipBubble {
  0% {
    transform: rotateY(0deg);
  }
  10% {
    transform: rotateY(180deg);
  }
  50% {
    transform: rotateY(180deg);
  }
  60% {
    transform: rotateY(360deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}
.article .author-template {
  margin-top: -16px;
}
.article .author-template .author-top {
  position: relative;
  height: 100%;
  margin-bottom: 74px;
}
.article .author-template .author-top .author-background {
  overflow: hidden;
  left: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  max-width: 100vw;
  position: absolute;
  right: 50%;
  width: 100vw;
  z-index: -1;
  height: inherit;
}
.article .author-template .author-top .author-head {
  padding: 50px 0;
}
@media only screen and (min-width: 480px) {
  .article .author-template .author-top .author-head {
    padding: 96px 0 102px;
  }
}
.article .author-template .author-top .author-head .author-image {
  width: 204px;
  height: 204px;
}
.article .news-template .fullbleed {
  position: relative;
  aspect-ratio: 16/9;
  left: 50%;
  width: 100vw;
  background-size: cover;
  background-position: center;
  margin-left: -50vw;
  max-width: 1920px;
}
.article .news-template .fullbleed.min-height {
  min-height: 432px;
}
.article .news-template .fullbleed.overlay {
  margin-top: -16px;
}
@media only screen and (min-width: 1920px) {
  .article .news-template .fullbleed {
    margin-left: -960px;
  }
}
.article .news-template .stage-overlay {
  position: absolute;
  bottom: 16px;
  color: white;
}
@media only screen and (min-width: 480px) {
  .article .news-template .stage-overlay {
    bottom: 24px;
  }
}
@media only screen and (min-width: 768px) {
  .article .news-template .stage-overlay {
    bottom: 48px;
  }
}
.article .event-template > div {
  position: relative;
  margin-bottom: 32px;
}
@media screen and (min-width: 768px) {
  .article .event-template > div {
    margin-bottom: 72px;
  }
}
.article .event-template .event-template-content {
  width: 100vw;
  margin: 0 -50vw;
  max-width: 1920px;
  min-height: 430px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media screen and (min-width: 768px) {
  .article .event-template .event-template-content {
    min-height: 580px;
  }
}
@media screen and (min-width: 1280px) {
  .article .event-template .event-template-content {
    min-height: 810px;
  }
}
.article .event-template .event-template-content.dark {
  border-image: linear-gradient(90deg, rgba(20, 20, 20, 0.7) 0%, rgba(29, 29, 29, 0) 80%) fill 1;
}
.article .event-template .event-template-content.sc-white {
  border-image: linear-gradient(90deg, rgba(255, 255, 255, 0.5) 0%, rgba(29, 29, 29, 0) 80%) fill 1;
}
.article .event-template .event-template-content.sc-navy {
  border-image: linear-gradient(90deg, rgba(0, 17, 85, 0.5) 0%, rgba(29, 29, 29, 0) 80%) fill 1;
}
.article .event-template .event-template-stage-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

body[data-wcm-mode=EDIT] .article .event-template .event-template-stage-content {
  position: relative;
  top: 0;
  left: 0;
  transform: none;
}

/*New*/
.component-banner div > a {
  position: relative;
  display: block;
}
.component-banner.rounded-corners > div,
.component-banner.rounded-corners .img-wrapper,
.component-banner.rounded-corners .centralized_over img {
  border-radius: 12px;
  padding-left: 0;
}
.component-banner .cmp-teaser_fullbleed {
  left: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  width: 100vw;
  right: 50%;
  position: relative;
  max-width: 1920px;
}
@media screen and (min-width: 1920px) {
  .component-banner .cmp-teaser_fullbleed {
    margin-left: -960px;
    margin-right: -960px;
  }
}
@media screen and (min-width: 1024px) {
  .component-banner .cmp-teaser_fullbleed .description_over {
    max-width: 678px;
  }
}
.component-banner .img-wrapper {
  background-position: center 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
.component-banner .centralized_over {
  position: absolute;
  display: flex;
  height: 100%;
  overflow: hidden;
  top: 0;
  padding-top: 16px;
  width: 100%;
  justify-content: center;
}
@media screen and (min-width: 1024px) {
  .component-banner .centralized_over {
    align-items: center;
    justify-content: flex-end;
    width: 50%;
    margin-left: 50%;
    max-width: 678px;
    padding-top: 0;
  }
}
.component-banner .centralized_over.img-left {
  justify-content: flex-start;
}
.component-banner .centralized_over.img-center {
  justify-content: center;
}
.component-banner .centralized_over.img-right {
  justify-content: flex-end;
}
.component-banner .centralized_over img {
  padding-left: 24px;
  height: 169px;
  width: auto;
  display: block;
  object-fit: contain;
}
@media screen and (min-width: 768px) {
  .component-banner .centralized_over img {
    height: 222px;
  }
}
@media screen and (min-width: 1024px) {
  .component-banner .centralized_over img {
    height: auto;
    max-height: 287px;
    width: 100%;
  }
}
@media screen and (min-width: 1280px) {
  .component-banner .centralized_over img {
    height: auto;
    max-height: 366px;
  }
}
@media screen and (min-width: 1440px) {
  .component-banner .centralized_over img {
    height: auto;
    max-height: 416px;
  }
}
.component-banner .centralized_over .sticker_holder {
  margin: 0 auto;
}
@media screen and (min-width: 1440px) {
  .component-banner .centralized_over .sticker_holder {
    margin-right: 0;
  }
}
.component-banner .description_over {
  position: absolute;
  display: flex;
  width: 100%;
  height: auto;
  bottom: 39px;
}
@media screen and (min-width: 768px) {
  .component-banner .description_over {
    height: auto;
    bottom: 42px;
  }
}
@media screen and (min-width: 1024px) {
  .component-banner .description_over {
    top: 0;
    width: 50%;
    right: 50%;
    height: 100%;
    max-width: 654px;
  }
  .component-banner .description_over.text-top {
    align-items: flex-start;
  }
  .component-banner .description_over.text-center {
    align-items: center;
  }
  .component-banner .description_over.text-bottom {
    align-items: flex-end;
  }
}
.component-banner .description_over .description_over_wrapper {
  margin-left: 16px;
  margin-right: 16px;
}
.component-banner .description_over .description_over_wrapper .underlined-link:hover, .component-banner .description_over .description_over_wrapper .underlined-link:focus {
  text-decoration: underline 2px;
}
@media screen and (min-width: 768px) {
  .component-banner .description_over .description_over_wrapper {
    margin-left: 20px;
    margin-right: 20px;
  }
}
@media screen and (min-width: 1024px) {
  .component-banner .description_over .description_over_wrapper {
    margin-left: 40px;
    margin-right: 0;
    margin-top: 68px;
    margin-bottom: 68px;
  }
}
@media screen and (min-width: 1280px) {
  .component-banner .description_over .description_over_wrapper {
    margin-left: 42px;
  }
}
@media screen and (min-width: 1440px) {
  .component-banner .description_over .description_over_wrapper {
    margin-left: 0;
  }
}
@media screen and (max-width: 1023px) {
  .component-banner .description_over .description_over_wrapper {
    display: flex;
    flex-direction: column;
  }
  .component-banner .description_over .description_over_wrapper .bottom-section {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
}
@media screen and (min-width: 1024px) {
  .component-banner .description_over .description_over_wrapper .bottom-section {
    margin-top: 40px;
  }
}
.component-banner .countdown {
  margin: 0 0 32px 0;
}
@media screen and (min-width: 480px) {
  .component-banner .countdown {
    margin: 0 0 16px 0;
  }
}
@media screen and (min-width: 768px) {
  .component-banner .countdown {
    margin: 0 0 16px 0;
  }
}
.component-banner .small-banner .centralized_over img {
  height: 169px;
}
@media screen and (min-width: 768px) {
  .component-banner .small-banner .centralized_over img {
    height: 138px;
  }
}
@media screen and (min-width: 1024px) {
  .component-banner .small-banner .centralized_over img {
    height: auto;
    max-height: 178px;
  }
}
@media screen and (min-width: 1280px) {
  .component-banner .small-banner .centralized_over img {
    max-height: 226px;
  }
}
@media screen and (min-width: 1440px) {
  .component-banner .small-banner .centralized_over img {
    max-height: 256px;
  }
}
.component-banner .small-banner .description_over {
  height: 250px;
}
@media screen and (min-width: 768px) {
  .component-banner .small-banner .description_over {
    height: 128px;
  }
}
@media screen and (min-width: 1024px) {
  .component-banner .small-banner .description_over {
    height: 100%;
  }
}
.component-banner .small-banner .description_over .description_over_wrapper {
  align-self: center;
}
.component-banner .small-banner .description_over .description_over_wrapper .bottom-section {
  margin-top: 32px;
}
@media screen and (min-width: 768px) {
  .component-banner .small-banner .description_over .description_over_wrapper .bottom-section {
    margin-top: 16px;
  }
}
@media screen and (min-width: 1024px) {
  .component-banner .small-banner .description_over .description_over_wrapper .bottom-section {
    margin-top: 40px;
  }
}
@media screen and (max-width: 767px) {
  .component-banner .small-banner .img-wrapper {
    min-height: 480px !important;
  }
}
.component-banner .sticker-mobile,
.component-banner .background-mobile,
.component-banner .img-wrapper-no-sticker-mobile {
  display: none;
}
@media screen and (max-width: 1023px) {
  .component-banner .sticker-mobile,
  .component-banner .background-mobile,
  .component-banner .img-wrapper-no-sticker-mobile {
    display: block;
  }
}
.component-banner .sticker-mobile.only-desktop,
.component-banner .background-mobile.only-desktop,
.component-banner .img-wrapper-no-sticker-mobile.only-desktop {
  display: none;
}
.component-banner .sticker-desktop,
.component-banner .background-desktop,
.component-banner .img-wrapper-no-sticker-desktop {
  display: none;
}
@media screen and (min-width: 1024px) {
  .component-banner .sticker-desktop,
  .component-banner .background-desktop,
  .component-banner .img-wrapper-no-sticker-desktop {
    display: block;
  }
}
.component-banner .sticker-desktop.only-desktop,
.component-banner .background-desktop.only-desktop,
.component-banner .img-wrapper-no-sticker-desktop.only-desktop {
  display: block;
}
.component-banner.text-white .description_over :not(button, .countdown:not(.theme-orchid-squares) *) {
  color: #fff;
}
.component-banner.text-navy .description_over :not(button) {
  color: #015;
}
.component-banner.text-blue .description_over :not(button) {
  color: #086adb;
}
.component-banner.pretitle-navy .description_over .subtitle {
  color: #015;
}
.component-banner .bg-color-gradient-red {
  background: radial-gradient(110% 220% at 0% 140%, #13a3f4 0%, rgba(22, 158, 255, 0.5) 40%, rgba(16, 136, 222, 0) 100%), radial-gradient(290% 800% at 220% -440%, #dd1122 30%, #001155 100%);
}
.component-banner .bg-color-gradient-blue {
  background: radial-gradient(110% 240% at 0% 130%, rgba(19, 163, 244, 0.9) 0%, rgba(22, 158, 255, 0.5) 55%, rgba(16, 136, 222, 0) 130%), #001155;
}
.component-banner .bg-color-gradient-violet {
  background: linear-gradient(to right bottom, #5d1082, #4d137b, #3d1473, #2d146a, #1d1361, #191a66, #14206b, #0d266f, #163683, #1f4797, #2758ab, #2e6ac0);
}
.component-banner .bg-color-cloud {
  background: #f9f9f9;
}
.buttongroup .button-group {
  display: block;
}
@media (min-width: 0) {
  .buttongroup .button-group {
    display: flex;
  }
}
@media (min-width: 480px) {
  .buttongroup .button-group {
    margin-top: 8px;
    margin-bottom: 8px;
  }
}
.buttongroup .button-group > a,
.buttongroup .button-group > div.align-right,
.buttongroup .button-group > div.align-center {
  padding-left: 32px;
  padding-right: 32px;
  margin: 16px;
}
@media screen and (max-width: 767px) {
  .buttongroup.early_full_width button,
  .buttongroup.early_full_width sdx-button {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  .buttongroup.early_full_width .button-group {
    flex-direction: column-reverse;
  }
}
.buttongroup.no_gutter button {
  margin-left: 0;
  margin-right: 0;
}
.buttongroup button.turquoise, .buttongroup button.white {
  margin-bottom: 16px;
  margin-top: 16px;
}

.chip .badge-align-left.badge.badge--message {
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.chip .badge-align-right.badge.badge--message {
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.chip .skinBadgeLink {
  z-index: 999;
}
.chip .skinBadgeLink .badge.badge--message {
  justify-content: flex-start;
}
.chip .skinBadgeLink .badge.badge--message a {
  max-width: none;
  border-bottom: none;
}
.chip .skinBadgeLink .badge.badge--message a.badge__content {
  padding-left: 5px;
  padding-right: 5px;
  cursor: pointer;
}
.chip .skinBadgeLink .badge.badge--message a.badge__content .badge__text {
  letter-spacing: normal;
}
.chip .skinBadgeLink .badge.badge--message a.badge__content:hover, .chip .skinBadgeLink .badge.badge--message a.badge__content:focus {
  border: none;
}

/* shadow box values for teasers in navigation style container */
.cmp-container {
  z-index: auto;
}
.z-index-0 .cmp-container {
  z-index: 0;
}

.cmp-container_fullbleed {
  left: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  max-width: 100vw;
  position: absolute;
  right: 50%;
  width: 100vw;
  z-index: -1;
  height: inherit;
}
.cmp-container_fullbleed.cmp-container_max_fullbleed {
  max-width: 1920px;
}
@media screen and (min-width: 1920px) {
  .cmp-container_fullbleed.cmp-container_max_fullbleed {
    margin-left: -960px;
    margin-right: -960px;
  }
}

.cmp-container_regular {
  position: absolute;
  width: 100%;
  z-index: -1;
  height: inherit;
}

.cmp-container_stage {
  margin-top: -8px;
}

.cmp-container_height {
  position: relative;
  height: 100%;
}

.cmp-container_fullbleed,
.cmp-container_regular {
  overflow: hidden;
}
.cmp-container_fullbleed video,
.cmp-container_regular video {
  object-fit: cover;
  height: 100%;
  width: 100%;
  min-width: 100%;
  min-height: 100%;
}
.cmp-container_fullbleed .background-video-img,
.cmp-container_regular .background-video-img {
  min-width: 100%;
  width: 100%;
  height: inherit;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.component-container.rounded-corners > div > .cmp-container_fullbleed,
.component-container.rounded-corners > div > .cmp-container_regular {
  border: 1px solid var(--sdx-color-gray-tint-7);
  border-radius: 12px;
}
.component-container.remove-border > div > .cmp-container_fullbleed,
.component-container.remove-border > div > .cmp-container_regular {
  border: none;
}
.component-container.add-hover .cmp-container:hover > .cmp-container_fullbleed,
.component-container.add-hover .cmp-container:hover > .cmp-container_regular {
  border: 1px solid var(--sdx-color-gray-tint-7);
  border-color: var(--sdx-color-gray-tint-4);
  box-shadow: color-mix(in srgb, var(--sdx-color-gray) 16%, transparent) 0px 4px 16px 0px;
  transition: all 200ms ease-in-out 0s;
  cursor: pointer;
}
.cmp-container_fullbleed.img-wrapper.has-image,
.cmp-container_regular.img-wrapper.has-image {
  background-size: cover;
  background-repeat: no-repeat;
}

.bg-x-left .cmp-container_fullbleed.has-image,
.bg-x-left .cmp-container_regular.has-image,
.bg-x-left .background-video-img {
  background-position-x: left;
}

.bg-x-center .cmp-container_fullbleed.has-image,
.bg-x-center .cmp-container_regular.has-image,
.bg-x-center .background-video-img {
  background-position-x: center;
}

.bg-x-right .cmp-container_fullbleed.has-image,
.bg-x-right .cmp-container_regular.has-image,
.bg-x-right .background-video-img {
  background-position-x: right;
}

.bg-y-top .cmp-container_fullbleed.has-image,
.bg-y-top .cmp-container_regular.has-image,
.bg-y-top .background-video-img {
  background-position-y: top;
}

.bg-y-center .cmp-container_fullbleed.has-image,
.bg-y-center .cmp-container_regular.has-image,
.bg-y-center .background-video-img {
  background-position-y: center;
}

.bg-y-bottom .cmp-container_fullbleed.has-image,
.bg-y-bottom .cmp-container_regular.has-image,
.bg-y-bottom .background-video-img {
  background-position-y: bottom;
}

.bg-size-contain .cmp-container_fullbleed.img-wrapper.has-image,
.bg-size-contain .cmp-container_regular.img-wrapper.has-image {
  background-size: contain;
}

.container-text-center .cmp-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-text-bottom .cmp-container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.component-container.aem-GridColumn {
  margin: 0;
}
.component-container.outer_gutter {
  margin-left: -4px;
  margin-right: -4px;
}
@media screen and (min-width: 768px) {
  .component-container.outer_gutter {
    margin-left: -8px;
    margin-right: -8px;
  }
}
@media screen and (min-width: 1024px) {
  .component-container.outer_gutter {
    margin-left: -12px;
    margin-right: -12px;
  }
}
.aem-Grid .component-container.outer_gutter.aem-GridColumn {
  width: calc(100% + 8px);
}
@media screen and (min-width: 768px) {
  .aem-Grid .component-container.outer_gutter.aem-GridColumn {
    width: calc(100% + 16px);
  }
}
@media screen and (min-width: 1024px) {
  .aem-Grid .component-container.outer_gutter.aem-GridColumn {
    width: calc(100% + 24px);
  }
}
.component-container.fixed-max-width .cmp-container {
  max-width: 1380px;
  margin: 0 auto;
}
.root > .aem-Grid > .responsivegrid .component-container.no_gutter.no_gutter [class*=aem-GridColumn], .xp-fragment-container > .root > .aem-Grid .component-container.no_gutter.no_gutter [class*=aem-GridColumn] {
  margin: 0 -1px -1px 0;
  padding: 0;
}
.root > .aem-Grid > .responsivegrid .component-container.no_gutter.no_gutter [class*=aem-GridColumn] [class*=sc-teaser], .xp-fragment-container > .root > .aem-Grid .component-container.no_gutter.no_gutter [class*=aem-GridColumn] [class*=sc-teaser] {
  margin-bottom: 0;
}
.component-container.flex .aem-Grid, .component-container.flex-large .aem-Grid, .component-container.flex-small .aem-Grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.component-container.flex .aem-Grid [class*=aem-GridColumn], .component-container.flex-large .aem-Grid [class*=aem-GridColumn], .component-container.flex-small .aem-Grid [class*=aem-GridColumn] {
  float: none !important;
}
.component-container.flex .aem-Grid [class*=aem-GridColumn] [class*=sc-teaser], .component-container.flex-large .aem-Grid [class*=aem-GridColumn] [class*=sc-teaser], .component-container.flex-small .aem-Grid [class*=aem-GridColumn] [class*=sc-teaser] {
  height: 100%;
}
.component-container.flex .aem-Grid [class*=aem-GridColumn] .modHoverContainer, .component-container.flex-large .aem-Grid [class*=aem-GridColumn] .modHoverContainer, .component-container.flex-small .aem-Grid [class*=aem-GridColumn] .modHoverContainer {
  height: 100%;
}
.component-container.flex .aem-Grid [class*=aem-GridColumn] .modUbcExchangeGenerator.modUrlPlaceholders, .component-container.flex-large .aem-Grid [class*=aem-GridColumn] .modUbcExchangeGenerator.modUrlPlaceholders, .component-container.flex-small .aem-Grid [class*=aem-GridColumn] .modUbcExchangeGenerator.modUrlPlaceholders {
  height: 100%;
}
.component-container.flex .aem-Grid [class*=aem-GridColumn] .modUbcExchangeGenerator.modUrlPlaceholders > div:not(.authorInfo), .component-container.flex-large .aem-Grid [class*=aem-GridColumn] .modUbcExchangeGenerator.modUrlPlaceholders > div:not(.authorInfo), .component-container.flex-small .aem-Grid [class*=aem-GridColumn] .modUbcExchangeGenerator.modUrlPlaceholders > div:not(.authorInfo) {
  height: inherit;
}
.component-container.flex .aem-Grid [class*=aem-GridColumn] .modUbcExchangeGenerator.modUrlPlaceholders > div:not(.authorInfo) > div.aem-Grid, .component-container.flex-large .aem-Grid [class*=aem-GridColumn] .modUbcExchangeGenerator.modUrlPlaceholders > div:not(.authorInfo) > div.aem-Grid, .component-container.flex-small .aem-Grid [class*=aem-GridColumn] .modUbcExchangeGenerator.modUrlPlaceholders > div:not(.authorInfo) > div.aem-Grid {
  height: inherit;
}
@media screen and (min-width: 768px) {
  .component-container.flex .aem-Grid {
    flex-wrap: nowrap;
  }
  .component-container.flex .aem-Grid .aem-Grid-newComponent {
    width: 100%;
    position: absolute;
    left: 0;
    top: initial;
    bottom: -40px;
    height: 20px;
  }
}
@media screen and (min-width: 1024px) {
  .component-container.flex-large .aem-Grid {
    flex-wrap: nowrap;
  }
  .component-container.flex-large .aem-Grid .aem-Grid-newComponent {
    width: 100%;
    position: absolute;
    left: 0;
    top: initial;
    bottom: -40px;
    height: 20px;
  }
}
@media screen and (min-width: 480px) {
  .component-container.flex-small .aem-Grid {
    flex-wrap: nowrap;
  }
  .component-container.flex-small .aem-Grid .aem-Grid-newComponent {
    width: 100%;
    position: absolute;
    left: 0;
    top: initial;
    bottom: -40px;
    height: 20px;
  }
}
@media screen and (min-width: 768px) {
  .component-container.flex-ribbon > div > div > .aem-Grid {
    display: flex;
    flex-direction: row;
  }
  .component-container.flex-ribbon > div > div > .aem-Grid sdx-ribbon {
    display: flex;
    height: 100%;
    flex-direction: column;
  }
  .component-container.flex-ribbon > div > div > .aem-Grid sdx-ribbon .ribbon-border {
    height: 100%;
  }
}
.component-container.flex-combo .aem-Grid [class*=aem-GridColumn] {
  float: none !important;
}
.component-container.flex-combo .aem-Grid [class*=aem-GridColumn] [class*=sc-teaser] {
  height: 100%;
}
.component-container.flex-combo .aem-Grid [class*=aem-GridColumn] .modHoverContainer {
  height: 100%;
}
@media screen and (min-width: 768px) {
  .component-container.flex-combo .aem-Grid {
    flex-wrap: nowrap;
  }
  .component-container.flex-combo .aem-Grid .aem-Grid-newComponent {
    width: 100%;
    position: absolute;
    left: 0;
    top: initial;
    bottom: -40px;
    height: 20px;
  }
}
@media screen and (min-width: 480px) {
  .component-container.wrap-text.flex-small .aem-Grid {
    flex-wrap: wrap;
  }
}
@media screen and (min-width: 768px) {
  .component-container.wrap-text.flex .aem-Grid {
    flex-wrap: wrap;
  }
}
@media screen and (min-width: 1024px) {
  .component-container.wrap-text.flex-large .aem-Grid {
    flex-wrap: wrap;
  }
}
.component-container.navigation-style.no_gutter .teaser:first-of-type {
  box-shadow: 0px 13px 16px -9px rgba(51, 51, 51, 0.08), -10px 0px 16px -10px rgba(51, 51, 51, 0.08), 0px -8px 16px -13px rgba(51, 51, 51, 0.08);
}
.component-container.navigation-style.no_gutter .teaser:first-of-type .cmp-teaser {
  border-radius: 12px 0 0 12px;
}
.component-container.navigation-style.no_gutter .teaser:not(:first-of-type):not(:last-of-type) {
  box-shadow: 0px 13px 16px -9px rgba(51, 51, 51, 0.08), 0px -8px 16px -9px rgba(51, 51, 51, 0.08);
}
.component-container.navigation-style.no_gutter .teaser:last-of-type {
  box-shadow: 0px 13px 16px -9px rgba(51, 51, 51, 0.08), 10px 0px 16px -13px rgba(51, 51, 51, 0.08), 0px -8px 16px -13px rgba(51, 51, 51, 0.08);
}
.component-container.navigation-style.no_gutter .teaser:last-of-type .cmp-teaser {
  border-radius: 0 12px 12px 0;
}
.component-container.navigation-style.no_gutter .teaser {
  cursor: pointer;
}
.component-container.navigation-style.no_gutter .teaser .cmp-teaser a {
  cursor: pointer;
}
@media screen and (min-width: 480px) {
  .component-container.container-center-content:not(.flex-large):not(.flex) > .cmp-container > div > .aem-Grid {
    display: flex;
    justify-content: center;
  }
}
@media screen and (min-width: 768px) {
  .component-container.container-center-content.flex > .cmp-container > div > .aem-Grid {
    justify-content: center;
  }
}
@media screen and (min-width: 1024px) {
  .component-container.container-center-content.flex-large > .cmp-container > div > .aem-Grid {
    justify-content: center;
  }
}
.component-container .bg-color-gradient-violet > div.desktop,
.component-container .bg-color-gradient-violet-mobile > div.mobile-only {
  background: linear-gradient(to right bottom, #5d1082, #4d137b, #3d1473, #2d146a, #1d1361, #191a66, #14206b, #0d266f, #163683, #1f4797, #2758ab, #2e6ac0);
}
.component-container .bg-color-gradient-red > div.desktop,
.component-container .bg-color-gradient-red-mobile > div.mobile-only {
  background: radial-gradient(110% 220% at 0% 140%, #13a3f4 0%, rgba(22, 158, 255, 0.5) 40%, rgba(16, 136, 222, 0) 100%), radial-gradient(290% 800% at 220% -440%, #dd1122 30%, #001155 100%);
}
.component-container .bg-color-gradient-blue > div.desktop,
.component-container .bg-color-gradient-blue-mobile > div.mobile-only {
  background: radial-gradient(110% 240% at 0% 130%, rgba(19, 163, 244, 0.9) 0%, rgba(22, 158, 255, 0.5) 55%, rgba(16, 136, 222, 0) 130%), #001155;
}
.component-container .bg-color-cloud > div.desktop,
.component-container .bg-color-cloud-mobile > div.mobile-only {
  background: #f9f9f9;
}
.component-container .video_overlay:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.component-container.vertical-separator {
  --vertical-separator-opacity: 50%;
}
.component-container.vertical-separator .aem-Grid > div:not(:last-child, .title, .text),
.component-container.vertical-separator .component-card,
.component-container.vertical-separator .component-banner {
  border-bottom: 1px solid color-mix(in srgb, var(--sdx-color-gray-tint-8, #d6d6d6) var(--vertical-separator-opacity), transparent);
}
@media (min-width: 768px) {
  .component-container.vertical-separator .aem-Grid > div:not(:last-child, .title, .text),
  .component-container.vertical-separator .component-card,
  .component-container.vertical-separator .component-banner {
    border: 0;
    border-right: 1px solid color-mix(in srgb, var(--sdx-color-gray-tint-8, #d6d6d6) var(--vertical-separator-opacity), transparent);
  }
}

.cluster-4-elements .cmp-container .aem-Grid {
  display: flex;
  flex-wrap: wrap;
}
.cluster-4-elements .cmp-container .aem-Grid .component-card {
  margin-bottom: 16px;
}
@media (min-width: 768px) {
  .cluster-4-elements .cmp-container .aem-Grid .component-card {
    animation: 2000ms cubic-bezier(0.4, 0, 0.2, 1) 0.5s 1 ts-cluster-slide-in-from-top-left;
  }
}
@media (min-width: 768px) {
  .cluster-4-elements .cmp-container .aem-Grid .component-card:nth-child(1) {
    margin-left: 16.6666666667%;
    flex: 0 0 50%;
    max-width: 50%;
    align-self: flex-start;
  }
}
@media (min-width: 768px) {
  .cluster-4-elements .cmp-container .aem-Grid .component-card:nth-child(2) {
    padding-top: 25%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    align-self: flex-end;
    animation-name: ts-cluster-slide-in-from-top-right;
  }
}
@media (min-width: 768px) {
  .cluster-4-elements .cmp-container .aem-Grid .component-card:nth-child(3) {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
    align-self: flex-start;
    margin-top: -12%;
    animation-name: ts-cluster-slide-in-from-bottom-left;
  }
}
@media (min-width: 1024px) {
  .cluster-4-elements .cmp-container .aem-Grid .component-card:nth-child(3) {
    margin-top: -10%;
  }
}
@media (min-width: 768px) {
  .cluster-4-elements .cmp-container .aem-Grid .component-card:nth-child(4) {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
    align-self: flex-end;
    animation-name: ts-cluster-slide-in-from-bottom-right;
  }
}

.cluster-3-elements .cmp-container .aem-Grid {
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 768px) {
  .cluster-3-elements .cmp-container .aem-Grid .component-card {
    animation: 2000ms cubic-bezier(0.4, 0, 0.2, 1) 0.5s 1 ts-cluster-slide-in-from-top-left;
  }
}
@media (min-width: 768px) {
  .cluster-3-elements .cmp-container .aem-Grid .component-card:nth-child(1) {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
    margin-bottom: 16px;
    margin-top: 10%;
    min-height: 550px;
  }
}
@media (min-width: 768px) {
  .cluster-3-elements .cmp-container .aem-Grid .component-card:nth-child(2) {
    max-width: 34.7202778%;
    align-self: flex-start;
    animation-name: ts-cluster-slide-in-from-top-right;
    margin-bottom: 16px;
  }
}
@media (min-width: 1024px) {
  .cluster-3-elements .cmp-container .aem-Grid .component-card:nth-child(2) {
    margin-left: 8.3333333333%;
    margin-bottom: 24px;
    max-width: 24.999975%;
  }
}
@media (min-width: 768px) {
  .cluster-3-elements .cmp-container .aem-Grid .component-card:nth-child(3) {
    max-width: 34.7202778%;
    align-self: flex-end;
    animation-name: ts-cluster-slide-in-from-bottom-left;
    margin-left: 65.2499889333%;
    margin-top: -20%;
  }
}
@media (min-width: 1024px) {
  .cluster-3-elements .cmp-container .aem-Grid .component-card:nth-child(3) {
    flex: 0 0 24.999975%;
    max-width: 24.999975%;
    margin-left: 73.5833222667%;
  }
}

.teaser-cluster .cmp-container .aem-Grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: none;
  gap: 20px 0;
  position: relative;
  width: 100%;
}
.teaser-cluster .cmp-container .aem-Grid .component-card {
  width: inherit;
  grid-row: auto;
  height: 520px;
}
@media (min-width: 1024px) {
  .teaser-cluster .cmp-container .aem-Grid {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto auto;
    gap: 20px 10px;
  }
  .teaser-cluster .cmp-container .aem-Grid .component-card:nth-child(1),
  .teaser-cluster .cmp-container .aem-Grid .component-card:nth-child(4) {
    height: 658px;
  }
  .teaser-cluster .cmp-container .aem-Grid .component-card:nth-child(2),
  .teaser-cluster .cmp-container .aem-Grid .component-card:nth-child(3) {
    height: 560px;
  }
  .teaser-cluster .cmp-container .aem-Grid .component-card:nth-child(1),
  .teaser-cluster .cmp-container .aem-Grid .component-card:nth-child(2) {
    grid-row: 1;
  }
  .teaser-cluster .cmp-container .aem-Grid .component-card:nth-child(3) {
    grid-row: 2;
  }
  .teaser-cluster .cmp-container .aem-Grid .component-card:nth-child(4) {
    grid-row: 2;
    margin-top: -90px;
  }
}

sdx-content-slider > div {
  display: grid;
}
sdx-content-slider > div .xf-content-height > .aem-Grid::after, sdx-content-slider > div .xf-content-height > .aem-Grid::before {
  content: none;
}

.modContentSlider .cmp-teaser {
  height: 100%;
}

.divider.divider--transparent {
  border-bottom-color: transparent;
}
.divider.full {
  margin: 0;
  border: 0;
  height: unset;
}

/*New*/
.download-list .downloadlist-item {
  border: 1px solid #d6d6d6;
}
.remove-side-borders .download-list .downloadlist-item {
  border-left: 0;
  border-right: 0;
}
.download-list .downloadlist-item:not(:last-child) {
  border-bottom: 0;
}
.download-list .downloadlist-item a {
  text-decoration: none;
  border-bottom: 0;
  display: flex;
  color: var(--int-color);
}
.download-list .downloadlist-item a div.icon-type-section,
.download-list .downloadlist-item a div.center-image {
  margin-right: 14px;
  align-self: center;
}
.download-list .downloadlist-item a .text {
  padding-right: 50px;
  word-wrap: break-word;
  align-self: center;
}
.download-list .downloadlist-item a .icon-indicator-section {
  position: absolute;
  right: 16px;
  align-self: center;
  color: var(--int-color);
}
.download-list .downloadlist-item a div.center-image {
  display: flex;
}
.download-list .downloadlist-item a div.center-image .cmp-image__image {
  width: 30px;
}
.normal-link-style .download-list .downloadlist-item a.link:hover {
  text-decoration: underline;
  text-decoration-thickness: 2px;
}
.remove-bottom-border .download-list .downloadlist-item:last-child {
  border-bottom: 0;
}
.separated-list-items .download-list .downloadlist-item {
  border-radius: 12px;
  box-shadow: 0px 4px 16px 0px rgba(51, 51, 51, 0.08);
}
.separated-list-items .download-list .downloadlist-item:not(:last-child) {
  margin-bottom: 8px;
  border-bottom: 1px solid #bbb;
}
.separated-list-items .download-list .downloadlist-item a {
  padding: 16px 24px 16px 24px !important;
}
.bg-download-list-white .download-list .downloadlist-item {
  background-color: #fff;
  border-color: #dde3e7;
}
.without-borders .download-list .downloadlist-item {
  border: none;
}
.without-borders .download-list .downloadlist-item a {
  padding: 8px 0 8px 0 !important;
}
.without-borders .download-list .downloadlist-item a:hover {
  text-decoration: underline;
  text-decoration-thickness: 2px;
}
.without-borders .download-list .downloadlist-item a .icon-indicator-section {
  display: none;
}
.without-borders .download-list .downloadlist-item a p {
  font-weight: 400;
}

.text-h2 .download-list .downloadlist-item a p.text,
.text-h3 .download-list .downloadlist-item a p.text,
.text-h4 .download-list .downloadlist-item a p.text,
.text-h5 .download-list .downloadlist-item a p.text,
.text-h6 .download-list .downloadlist-item a p.text {
  line-height: inherit;
  letter-spacing: inherit;
  font-size: inherit;
  font-weight: inherit;
}

.font--bold .download-list .downloadlist-item a p.text {
  font-weight: 700;
}

.font--semi-bold .download-list .downloadlist-item a p.text {
  font-weight: inherit;
}

.hero-banner {
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  height: auto;
}
.hero-banner .background-desktop,
.hero-banner .background-mobile {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  height: inherit;
}
.hero-banner h4,
.hero-banner h2,
.hero-banner .description {
  color: var(--headline-color);
}
.hero-banner .hero-banner-content {
  display: flex;
  position: relative;
  flex-direction: column;
  height: 560px;
  padding: 24px 16px;
  gap: 24px;
  flex: 1 1 0%;
  text-decoration: none;
  z-index: 1;
}
@media screen and (min-width: 480px) {
  .hero-banner .hero-banner-content {
    padding: 32px 32px;
  }
}
@media screen and (min-width: 768px) {
  .hero-banner .hero-banner-content {
    flex-direction: row;
    justify-content: space-between;
    height: 608px;
  }
}
.hero-banner .hero-banner-content > .banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-basis: 50%;
  align-items: normal;
}
@media screen and (min-width: 768px) {
  .hero-banner .hero-banner-content > .banner .title {
    font-size: 54px;
    line-height: 64px;
  }
}
.hero-banner .hero-banner-content > .banner .button-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
@media screen and (min-width: 480px) {
  .hero-banner .hero-banner-content > .banner .button-wrapper {
    flex-direction: row;
    column-gap: 16px;
  }
}
.hero-banner .hero-banner-content > .banner .hero-countdown-container .countdown {
  margin-bottom: 32px;
}
@media screen and (min-width: 768px) {
  .hero-banner .hero-banner-content > .banner .hero-countdown-container .countdown {
    margin-bottom: 50px;
  }
}
.hero-banner .hero-banner-content .image-wrapper {
  display: flex;
  align-items: center;
  flex-basis: 50%;
  position: relative;
}
.hero-banner .hero-banner-content .image-wrapper img {
  display: block;
  aspect-ratio: 4/3;
  height: 100%;
  object-fit: scale-down;
  transition: transform 600ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
}
.hero-banner .hero-banner-content .image-wrapper img:hover {
  transform: scale(1.05);
}
.hero-banner .hero-banner-content .sdx-stickers {
  animation: flipBubble 6s linear infinite forwards running;
  position: absolute;
  right: -10%;
  bottom: -15%;
  backface-visibility: hidden;
  scale: 0.6;
  display: grid;
}
@media (min-width: 768px) {
  .hero-banner .hero-banner-content .sdx-stickers {
    scale: 0.7;
    right: -10%;
    bottom: 10%;
  }
}
@media (min-width: 1024px) {
  .hero-banner .hero-banner-content .sdx-stickers {
    scale: 1;
    right: 9%;
    bottom: -2%;
  }
}
.hero-banner .hero-banner-content .sdx-stickers > sdx-sticker-circle {
  grid-column: 1;
  grid-row: 1;
}
.hero-banner .hero-banner-content .sdx-stickers .back-face {
  z-index: -1;
  transform: rotateY(180deg);
}
.hero-banner .hero-banner-content .sdx-stickers.no-flipping {
  animation: none;
}
.hero-banner video, .gradient-dark .hero-banner .gradient-dark-overlay {
  display: block;
  object-fit: cover;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
.hero-banner .gradient-dark-overlay {
  display: none;
  background-image: linear-gradient(90deg, #1d1d1d 0%, rgba(29, 29, 29, 0) 100%);
}
@media (min-width: 480px) {
  .hero-banner .gradient-dark-overlay {
    background-image: linear-gradient(90deg, #1d1d1d 0%, rgba(29, 29, 29, 0) 55%);
  }
}
.hero-banner.bg-gradient-pink {
  background: linear-gradient(214.35deg, #d8e1f9 -0.2%, #5990f9 55.31%, #942fbe 98.75%);
}

.hero-teaser {
  display: flex;
  flex-direction: column-reverse;
}
@media screen and (min-width: 768px) {
  .hero-teaser {
    flex-direction: row;
  }
}
.hero-teaser .teaser {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .hero-teaser .teaser {
    width: 45%;
  }
}
.hero-teaser .teaser .badge {
  border-radius: 12px;
}
.hero-teaser .image {
  width: 100%;
}
@media screen and (min-width: 480px) {
  .hero-teaser .image {
    width: 80%;
  }
}
.hero-teaser .image img {
  border-radius: 12px;
}
.hero-teaser .image-holder {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
}
@media screen and (min-width: 768px) {
  .hero-teaser .image-holder {
    width: 55%;
  }
}

.logo {
  display: flex;
  width: 100%;
}
.logo img {
  height: 32px !important;
  width: auto !important;
}
@media screen and (max-width: 768px) {
  .logo img {
    height: 24px !important;
  }
}
.logo.logo-align-left {
  justify-content: flex-start;
}
.logo.logo-align-right {
  justify-content: flex-end;
}
.logo.logo-align-center {
  justify-content: center;
}

/*New*/
sdx-ribbon .ribbon-border {
  min-height: 25px;
}
sdx-ribbon[theme=sales] .ribbon-border {
  border: 1px solid #a63297;
  border-radius: 2px;
}
sdx-ribbon[theme=hint] .ribbon-border {
  border: 1px solid #0eaba9;
  border-radius: 2px;
}
sdx-ribbon[theme=topic-dark] .ribbon-border, sdx-ribbon[theme=topic-light] .ribbon-border {
  border: 1px solid #015;
  border-radius: 2px;
}

.modSdxTag sdx-tag {
  justify-content: center;
  transform: translateY(50%);
}
.modSdxTag .ribbon-tag-container.ribbon-border[theme=standard] {
  border: 1px solid #015;
  border-radius: 2px;
}
.modSdxTag .ribbon-tag-container.ribbon-border[theme=sales] {
  border: 1px solid #a63297;
  border-radius: 2px;
}
.modSdxTag .ribbon-tag-container.ribbon-border[theme=new] {
  border: 1px solid #015;
  border-radius: 2px;
}

/*New*/
.table.modTable thead,
.table.modTable tbody {
  font-size: 16px;
}
@media screen and (min-width: 480px) {
  .table.modTable thead,
  .table.modTable tbody {
    font-size: 18px;
  }
}
.table.modTable .mobile-friendly th {
  width: 40%;
}
.table.modTable .mobile-friendly td {
  white-space: break-spaces;
}
.table.modTable .table--disabled-border table {
  border: none;
}
.table.modTable .table--disabled-th-border thead tr {
  border: none;
}
.table.modTable .table--disabled-tb-border tbody tr {
  border: none;
}
.table-column-highlight .table.modTable table tbody tr:hover {
  background-color: #eef3f6;
}

/*New*/
/* These styles are only temporary for demo */
/* In the future we should render sdx html */
@media only screen and (min-width: 1024px) {
  .teaser {
    margin: 12px 0;
  }
}
.teaser.rounded-corners .cmp-teaser,
.teaser.rounded-corners .img-wrapper,
.teaser.rounded-corners .testimonial-bg-img,
.teaser.rounded-corners .gradient {
  border-radius: 12px;
}
.teaser.rounded-corners .teaser-badge-left {
  border-radius: 12px 0 0 0;
}
.teaser.rounded-corners .teaser-badge-left.teaser-badge-ribbon {
  border-radius: 12px;
}
.teaser.rounded-corners .teaser-badge-right {
  border-radius: 0 12px 0 0;
}
.teaser.rounded-corners .teaser-badge-right.teaser-badge-ribbon {
  border-radius: 12px;
}
.teaser.rounded-corners:not(.sc-teaser-img-right):not(.sc-teaser-img-left):not(.sc-teaser-img-bottom) .sc-teaserStandard .cmp-image__image,
.teaser.rounded-corners:not(.sc-teaser-img-right):not(.sc-teaser-img-left):not(.sc-teaser-img-bottom) .sc-teaserResProduct .cmp-image__image,
.teaser.rounded-corners:not(.sc-teaser-img-right):not(.sc-teaser-img-left):not(.sc-teaser-img-bottom) .sc-teaserPromotional .cmp-image__image,
.teaser.rounded-corners:not(.sc-teaser-img-right):not(.sc-teaser-img-left):not(.sc-teaser-img-bottom) .sc-teaserAbout .cmp-image__image,
.teaser.rounded-corners:not(.sc-teaser-img-right):not(.sc-teaser-img-left):not(.sc-teaser-img-bottom) .sc-teaserDefault .cmp-image__image {
  border-radius: 12px 12px 0 0;
  overflow: hidden;
}
.teaser.rounded-corners.sc-teaser-img-right .sc-teaserStandard .cmp-image__image,
.teaser.rounded-corners.sc-teaser-img-right .sc-teaserDefault .cmp-image__image {
  border-radius: 0 12px 12px 0;
}
.teaser.rounded-corners.sc-teaser-img-left .sc-teaserStandard .cmp-image__image,
.teaser.rounded-corners.sc-teaser-img-left .sc-teaserDefault .cmp-image__image {
  border-radius: 12px 0 0 12px;
}
.teaser.rounded-corners .sc-teaserNavigation .cmp-image__image {
  border-radius: 12px 0 0 0;
}
.teaser.rounded-corners.sc-teaser-img-bottom .sc-teaserStandard .cmp-image__image,
.teaser.rounded-corners.sc-teaser-img-bottom .sc-teaserNews .cmp-image__image {
  border-radius: 0 0 12px 12px;
}
.teaser .bg-color-gradient-violet {
  background: linear-gradient(to right bottom, #5d1082, #4d137b, #3d1473, #2d146a, #1d1361, #191a66, #14206b, #0d266f, #163683, #1f4797, #2758ab, #2e6ac0);
}
.teaser .bg-color-gradient-red {
  background: radial-gradient(110% 220% at 0% 140%, #13a3f4 0%, rgba(22, 158, 255, 0.5) 40%, rgba(16, 136, 222, 0) 100%), radial-gradient(290% 800% at 220% -440%, #dd1122 30%, #001155 100%);
}
.teaser .bg-color-gradient-blue {
  background: radial-gradient(110% 240% at 0% 130%, rgba(19, 163, 244, 0.9) 0%, rgba(22, 158, 255, 0.5) 55%, rgba(16, 136, 222, 0) 130%), #001155;
}
.teaser .bg-color-cloud {
  background: #f9f9f9;
}
.sc-teaserAbout > a,
.sc-teaserNavigation > a,
.sc-teaserStandard > a,
.sc-teaserDevice > a,
.sc-teaserMultiElement > a,
.sc-teaserPicture > a {
  text-decoration: none;
}

@media only screen and (min-width: 1024px) {
  .sc-teaser-img-right .sc-teaserMultiElement > a, .sc-teaser-img-left .sc-teaserMultiElement > a,
  .sc-teaser-img-right .sc-teaserMultiElement > a.landscape-image,
  .sc-teaser-img-left .sc-teaserMultiElement > a.landscape-image {
    display: grid;
    height: 100%;
    min-height: 440px;
    align-items: center;
  }
  .sc-teaser-img-right .sc-teaserMultiElement > a .centralized_over, .sc-teaser-img-left .sc-teaserMultiElement > a .centralized_over,
  .sc-teaser-img-right .sc-teaserMultiElement > a.landscape-image .centralized_over,
  .sc-teaser-img-left .sc-teaserMultiElement > a.landscape-image .centralized_over {
    position: relative;
  }
  .sc-teaser-img-right .sc-teaserMultiElement > a .centralized_over .sticker_holder img, .sc-teaser-img-left .sc-teaserMultiElement > a .centralized_over .sticker_holder img,
  .sc-teaser-img-right .sc-teaserMultiElement > a.landscape-image .centralized_over .sticker_holder img,
  .sc-teaser-img-left .sc-teaserMultiElement > a.landscape-image .centralized_over .sticker_holder img {
    width: 100%;
    height: auto;
  }
  .sc-teaser-img-right .sc-teaserMultiElement > a .img-wrapper, .sc-teaser-img-left .sc-teaserMultiElement > a .img-wrapper,
  .sc-teaser-img-right .sc-teaserMultiElement > a.landscape-image .img-wrapper,
  .sc-teaser-img-left .sc-teaserMultiElement > a.landscape-image .img-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
  }
}
@media only screen and (min-width: 1024px) {
  .sc-teaser-img-right .sc-teaserMultiElement > a .description_over, .sc-teaser-img-left .sc-teaserMultiElement > a .description_over,
  .sc-teaser-img-right .sc-teaserMultiElement > a.landscape-image .description_over,
  .sc-teaser-img-left .sc-teaserMultiElement > a.landscape-image .description_over {
    position: relative;
    width: 100%;
    margin-top: 24px;
  }
}
@media only screen and (max-width: 1023px) {
  .sc-teaser-img-right .sc-teaserMultiElement > a .description_over .title, .sc-teaser-img-left .sc-teaserMultiElement > a .description_over .title,
  .sc-teaser-img-right .sc-teaserMultiElement > a.landscape-image .description_over .title,
  .sc-teaser-img-left .sc-teaserMultiElement > a.landscape-image .description_over .title {
    font-size: 28px;
    line-height: 32px;
    letter-spacing: -0.75px;
    margin-bottom: 22px;
  }
  .sc-teaser-img-right .sc-teaserMultiElement > a .description_over .subtitle, .sc-teaser-img-left .sc-teaserMultiElement > a .description_over .subtitle,
  .sc-teaser-img-right .sc-teaserMultiElement > a.landscape-image .description_over .subtitle,
  .sc-teaser-img-left .sc-teaserMultiElement > a.landscape-image .description_over .subtitle {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.1px;
    margin-bottom: 8px;
  }
}
@media only screen and (min-width: 1024px) {
  .sc-teaser-img-right .sc-teaserMultiElement > a,
  .sc-teaser-img-right .sc-teaserMultiElement > a.landscape-image {
    grid-template-areas: "b a";
    grid-template-columns: 60% 40%;
  }
  .sc-teaser-img-right .sc-teaserMultiElement > a .centralized_over,
  .sc-teaser-img-right .sc-teaserMultiElement > a.landscape-image .centralized_over {
    grid-area: a;
  }
  .sc-teaser-img-right .sc-teaserMultiElement > a .description_over,
  .sc-teaser-img-right .sc-teaserMultiElement > a.landscape-image .description_over {
    grid-area: b;
    padding-right: 8px;
  }
  .sc-teaser-img-left .sc-teaserMultiElement > a,
  .sc-teaser-img-left .sc-teaserMultiElement > a.landscape-image {
    grid-template-columns: 40% 60%;
  }
}
@media only screen and (min-width: 768px) {
  .sc-teaser-img-right .sc-teaserMultiElement > a.landscape-image .centralized_over .sticker_holder img, .sc-teaser-img-left .sc-teaserMultiElement > a.landscape-image .centralized_over .sticker_holder img {
    width: 100% !important;
    height: auto !important;
  }
}
.sc-teaserMultiElement .countdown-container {
  position: relative;
}
.sc-teaserMultiElement .countdown {
  position: absolute;
  z-index: 1;
}
.sc-teaserMultiElement .countdown[class*=top-] {
  top: 40px;
}
@media screen and (min-width: 480px) {
  .sc-teaserMultiElement .countdown[class*=top-] {
    top: 40px;
  }
}
@media screen and (min-width: 768px) {
  .sc-teaserMultiElement .countdown[class*=top-] {
    top: 16px;
  }
}
.sc-teaserMultiElement .countdown.top-left {
  left: 16px;
}
.sc-teaserMultiElement .countdown.top-center {
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  width: fit-content;
}
.sc-teaserMultiElement .countdown.top-right {
  right: 16px;
}
.sc-teaserMultiElement .countdown.bottom-right {
  bottom: 16px;
  right: 16px;
}
.sc-teaserMultiElement .countdown.bottom-left {
  bottom: 16px;
  left: 16px;
}
.sc-teaserMultiElement .countdown.bottom-center {
  bottom: 16px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  width: fit-content;
}
@media screen and (min-width: 480px) {
  .sc-teaserMultiElement .teaser-banner .countdown[class*=top-] {
    top: 32px;
  }
}
@media screen and (min-width: 768px) {
  .sc-teaserMultiElement .teaser-banner .countdown[class*=top-] {
    top: 60px;
  }
}
@media screen and (min-width: 1024px) {
  .sc-teaserMultiElement .teaser-banner .countdown[class*=top-] {
    top: 16px;
  }
}
@media screen and (min-width: 480px) {
  .sc-teaserMultiElement .landscape-image .countdown[class*=top-] {
    top: 42px;
  }
}
@media screen and (min-width: 768px) {
  .sc-teaserMultiElement .landscape-image .countdown[class*=top-] {
    top: 52px;
  }
}
@media screen and (min-width: 480px) {
  .sc-teaserMultiElement .landscape-image.teaser-banner .countdown[class*=top-] {
    top: 32px;
  }
}
@media screen and (min-width: 768px) {
  .sc-teaserMultiElement .landscape-image.teaser-banner .countdown[class*=top-] {
    top: 16px;
  }
}

.sc-teaser-stage {
  margin-top: -16px;
}

.sc-teaserDefault,
.sc-teaser-img-left,
.sc-teaser-img-right {
  margin-bottom: 24px;
}
.sc-teaserDefault .cmp-teaser__title,
.sc-teaser-img-left .cmp-teaser__title,
.sc-teaser-img-right .cmp-teaser__title {
  color: #015;
  font-size: 22px;
  line-height: 26px;
  margin-bottom: 16px;
}

/* change order, title, image, description */
.sc-teaserDefault .cmp-image__image,
.sc-teaserNavigation .cmp-image__image {
  display: block;
}
.sc-teaserDefault .cmp-teaser__title,
.sc-teaserNavigation .cmp-teaser__title {
  order: 1;
}
.sc-teaserDefault .cmp-teaser__image,
.sc-teaserNavigation .cmp-teaser__image {
  order: 2;
}

.sc-teaser-img-left .cmp-teaser,
.sc-teaser-img-right .cmp-teaser {
  /* clear-fix */
  overflow: hidden;
}
.sc-teaser-img-left .cmp-teaser__title,
.sc-teaser-img-right .cmp-teaser__title {
  color: #015;
  font-size: 28px;
  line-height: 36px;
  letter-spacing: -0.5px;
}
.sc-teaser-img-left .cmp-teaser__image,
.sc-teaser-img-right .cmp-teaser__image {
  width: 48%;
}

.sc-teaser-img-left .cmp-teaser__image {
  float: left;
}

.sc-teaser-img-right .cmp-teaser__image {
  float: right;
}

.cmp-teaser_border {
  border: 1px solid #bbb;
}
.cmp-teaser_border.no-top-border {
  border-top: 0;
}

.cmp-teaser_image {
  width: 100%;
}

.border-bottom {
  border-bottom: 1px solid #bbb;
}

.cmp-teaser {
  position: relative;
  /* multi element */
}
.cmp-teaser > a {
  display: block;
  border-bottom: none;
}
.cmp-teaser > a:not([href]) {
  cursor: default;
}
.cmp-teaser .button {
  text-shadow: none;
}
.cmp-teaser .table td,
.cmp-teaser .table th {
  white-space: normal;
}
.cmp-teaser .margin-top-40 {
  margin-top: 40px;
}
.cmp-teaser .img-wrapper > .gradient {
  display: none;
  width: 50.2%;
}
@media only screen and (max-width: 1920px) {
  .cmp-teaser .img-wrapper > .gradient {
    width: 56.2%;
  }
}
@media only screen and (max-width: 1024px) {
  .cmp-teaser .img-wrapper > .gradient {
    width: 69.2%;
  }
}
@media only screen and (max-width: 768px) {
  .cmp-teaser .img-wrapper > .gradient {
    width: 100%;
  }
}
.title-align-left .cmp-teaser h4,
.title-align-left .cmp-teaser .pretitle,
.title-align-left .cmp-teaser .title {
  text-align: left;
}
.title-align-center .cmp-teaser h4,
.title-align-center .cmp-teaser .pretitle,
.title-align-center .cmp-teaser .title {
  text-align: center;
}
.title-align-right .cmp-teaser h4,
.title-align-right .cmp-teaser .pretitle,
.title-align-right .cmp-teaser .title {
  text-align: right;
}
.cmp-teaser h4.sc-navy,
.cmp-teaser .pretitle.sc-navy,
.cmp-teaser .title.sc-navy {
  color: var(--headline-color);
}
.cmp-teaser b,
.cmp-teaser strong {
  font-weight: 700;
}
.actions-align-left .cmp-teaser.sc-teaserNavigation ul.list.actions, .actions-align-left .cmp-teaser.sc-teaserStandard div.list.actions, .actions-align-left .cmp-teaser.sc-teaserStandard div.teaserStandard-button, .actions-align-left .cmp-teaser.sc-teaserDevice div.list.actions, .actions-align-left .cmp-teaser.sc-teaserDevice div.teaserDevice-button, .actions-align-left .cmp-teaser.sc-teaserAbout div.actions {
  position: absolute;
}
.actions-align-center .cmp-teaser.sc-teaserNavigation ul.list.actions, .actions-align-center .cmp-teaser.sc-teaserStandard div.list.actions, .actions-align-center .cmp-teaser.sc-teaserStandard div.teaserStandard-button, .actions-align-center .cmp-teaser.sc-teaserDevice div.list.actions, .actions-align-center .cmp-teaser.sc-teaserDevice div.teaserDevice-button, .actions-align-center .cmp-teaser.sc-teaserAbout div.actions {
  display: flex;
  position: initial;
  justify-content: center;
  gap: 6px;
}
.actions-align-right .cmp-teaser.sc-teaserNavigation ul.list.actions, .actions-align-right .cmp-teaser.sc-teaserStandard div.list.actions, .actions-align-right .cmp-teaser.sc-teaserStandard div.teaserStandard-button, .actions-align-right .cmp-teaser.sc-teaserDevice div.list.actions, .actions-align-right .cmp-teaser.sc-teaserDevice div.teaserDevice-button, .actions-align-right .cmp-teaser.sc-teaserAbout div.actions {
  display: flex;
  position: initial;
  justify-content: flex-end;
  gap: 6px;
}
.actions-align-right .cmp-teaser.sc-teaserNavigation ul.list.actions .icon-arrow-right,
.actions-align-right .cmp-teaser.sc-teaserNavigation ul.list.actions sdx-icon, .actions-align-center .cmp-teaser.sc-teaserNavigation ul.list.actions .icon-arrow-right,
.actions-align-center .cmp-teaser.sc-teaserNavigation ul.list.actions sdx-icon, .actions-align-right .cmp-teaser.sc-teaserStandard div.list.actions .icon-arrow-right,
.actions-align-right .cmp-teaser.sc-teaserStandard div.list.actions sdx-icon, .actions-align-center .cmp-teaser.sc-teaserStandard div.list.actions .icon-arrow-right,
.actions-align-center .cmp-teaser.sc-teaserStandard div.list.actions sdx-icon, .actions-align-right .cmp-teaser.sc-teaserStandard div.teaserStandard-button .icon-arrow-right,
.actions-align-right .cmp-teaser.sc-teaserStandard div.teaserStandard-button sdx-icon, .actions-align-center .cmp-teaser.sc-teaserStandard div.teaserStandard-button .icon-arrow-right,
.actions-align-center .cmp-teaser.sc-teaserStandard div.teaserStandard-button sdx-icon, .actions-align-right .cmp-teaser.sc-teaserDevice div.list.actions .icon-arrow-right,
.actions-align-right .cmp-teaser.sc-teaserDevice div.list.actions sdx-icon, .actions-align-center .cmp-teaser.sc-teaserDevice div.list.actions .icon-arrow-right,
.actions-align-center .cmp-teaser.sc-teaserDevice div.list.actions sdx-icon, .actions-align-right .cmp-teaser.sc-teaserDevice div.teaserDevice-button .icon-arrow-right,
.actions-align-right .cmp-teaser.sc-teaserDevice div.teaserDevice-button sdx-icon, .actions-align-center .cmp-teaser.sc-teaserDevice div.teaserDevice-button .icon-arrow-right,
.actions-align-center .cmp-teaser.sc-teaserDevice div.teaserDevice-button sdx-icon, .actions-align-right .cmp-teaser.sc-teaserAbout div.actions .icon-arrow-right,
.actions-align-right .cmp-teaser.sc-teaserAbout div.actions sdx-icon, .actions-align-center .cmp-teaser.sc-teaserAbout div.actions .icon-arrow-right,
.actions-align-center .cmp-teaser.sc-teaserAbout div.actions sdx-icon {
  align-self: center;
}
.actions-align-left .cmp-teaser.sc-teaserResProduct .content-bottom-section {
  align-items: flex-start;
}
.actions-align-center .cmp-teaser.sc-teaserResProduct .content-bottom-section {
  align-items: center;
}
.actions-align-center .cmp-teaser.sc-teaserResProduct .content-bottom-section .font {
  align-self: flex-start;
}
.actions-align-right .cmp-teaser.sc-teaserResProduct .content-bottom-section {
  align-items: flex-end;
}
.actions-align-right .cmp-teaser.sc-teaserResProduct .content-bottom-section .font {
  align-self: flex-start;
}
.actions-align-left .cmp-teaser.sc-teaserDefault ul.list.actions li, .actions-align-left .cmp-teaser.sc-teaserTitleIcon ul.list.actions li, .actions-align-left .cmp-teaser.sc-teaserTestimonial ul.list.actions li, .actions-align-left .cmp-teaser.sc-teaserNews ul.list.actions li {
  display: list-item;
}
.actions-align-center .cmp-teaser.sc-teaserDefault ul.list.actions li, .actions-align-center .cmp-teaser.sc-teaserTitleIcon ul.list.actions li, .actions-align-center .cmp-teaser.sc-teaserTestimonial ul.list.actions li, .actions-align-center .cmp-teaser.sc-teaserNews ul.list.actions li {
  display: flex;
  justify-content: center;
  gap: 6px;
}
.actions-align-right .cmp-teaser.sc-teaserDefault ul.list.actions li, .actions-align-right .cmp-teaser.sc-teaserTitleIcon ul.list.actions li, .actions-align-right .cmp-teaser.sc-teaserTestimonial ul.list.actions li, .actions-align-right .cmp-teaser.sc-teaserNews ul.list.actions li {
  display: flex;
  justify-content: flex-end;
  gap: 6px;
}
.actions-align-left .cmp-teaser.sc-teaserMultiElement .description_over_wrapper, .actions-align-center .cmp-teaser.sc-teaserMultiElement .description_over_wrapper, .actions-align-right .cmp-teaser.sc-teaserMultiElement .description_over_wrapper, .actions-align-left .cmp-teaser.sc-teaserPicture .description_over_wrapper, .actions-align-center .cmp-teaser.sc-teaserPicture .description_over_wrapper, .actions-align-right .cmp-teaser.sc-teaserPicture .description_over_wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.actions-align-left .cmp-teaser.sc-teaserMultiElement .description_over_wrapper button, .actions-align-left .cmp-teaser.sc-teaserPicture .description_over_wrapper button {
  align-self: flex-start;
}
.actions-align-center .cmp-teaser.sc-teaserMultiElement .description_over_wrapper button, .actions-align-center .cmp-teaser.sc-teaserPicture .description_over_wrapper button {
  align-self: center;
}
.actions-align-right .cmp-teaser.sc-teaserMultiElement .description_over_wrapper button, .actions-align-right .cmp-teaser.sc-teaserPicture .description_over_wrapper button {
  align-self: flex-end;
}
.icon-align-left .cmp-teaser.sc-teaserStandard .image-icon-container {
  text-align: left;
}
.icon-align-center .cmp-teaser.sc-teaserStandard .image-icon-container {
  text-align: center;
}
.icon-align-right .cmp-teaser.sc-teaserStandard .image-icon-container {
  text-align: right;
}
.sc-teaser-img-left .cmp-teaser.sc-teaserStandard .image-icon-container {
  padding-right: 0 !important;
}
.sc-teaser-img-right .cmp-teaser.sc-teaserStandard .image-icon-container {
  padding-left: 0 !important;
}
.sc-teaser-img-bottom .cmp-teaser.sc-teaserStandard .image-icon-container {
  padding-top: 0 !important;
}
.cmp-teaser .teaser-badge {
  top: 0;
  font-size: 18px;
  letter-spacing: 0.06px;
}
.cmp-teaser .teaser-badge.teaser-badge-left {
  left: 0;
  transform: none;
}
.cmp-teaser .teaser-badge.teaser-badge-right {
  left: auto;
  right: 0;
  transform: none;
}
.cmp-teaser.sc-teaserPicture {
  height: 100%;
  width: 100%;
}
.cmp-teaser.sc-teaserPicture .img-wrapper {
  background-position: center 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
@media only screen and (max-width: 479px) {
  .cmp-teaser.sc-teaserPicture .img-wrapper {
    min-height: 344px !important;
  }
}
.cmp-teaser.sc-teaserPicture .img-wrapper.picture-align-center {
  background-position: center center;
}
.cmp-teaser.sc-teaserPicture .img-wrapper.picture-align-top {
  background-position: center top;
}
.cmp-teaser.sc-teaserPicture .img-wrapper.picture-align-bottom {
  background-position: center bottom;
}
.cmp-teaser.sc-teaserPicture .img-wrapper.picture-align-left {
  background-position: left center;
}
.cmp-teaser.sc-teaserPicture .img-wrapper.picture-align-right {
  background-position: right center;
}
.cmp-teaser.sc-teaserMultiElement {
  height: 100%;
  width: 100%;
}
.cmp-teaser.sc-teaserMultiElement .img-wrapper {
  background-position: center 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
@media only screen and (max-width: 479px) {
  .cmp-teaser.sc-teaserMultiElement .img-wrapper {
    min-height: 400px;
  }
}
@media only screen and (max-width: 767px) {
  .cmp-teaser.sc-teaserMultiElement .img-wrapper.min-height {
    min-height: 480px;
  }
}
@media only screen and (max-width: 479px) {
  .cmp-teaser.sc-teaserMultiElement .img-wrapper.min-height {
    min-height: 440px;
  }
}
@media only screen and (max-width: 767px) {
  .cmp-teaser.sc-teaserMultiElement .img-wrapper.min-height .gradient {
    min-height: 480px;
  }
}
@media only screen and (max-width: 479px) {
  .cmp-teaser.sc-teaserMultiElement .img-wrapper.min-height .gradient {
    min-height: 440px;
  }
}
.cmp-teaser.sc-teaserMultiElement .sticker-only__500 {
  width: 500px;
  height: 300px;
}
.cmp-teaser.sc-teaserMultiElement .sticker-only__512 {
  width: 512px;
  height: 300px;
}
.cmp-teaser.sc-teaserMultiElement .sticker-only__400 {
  width: 400px;
  height: 300px;
}
.cmp-teaser.sc-teaserMultiElement .sticker-only__256 {
  width: 256px;
  height: 300px;
}
.cmp-teaser.sc-teaserMultiElement img {
  height: 136px;
  width: auto;
}
@media only screen and (min-width: 480px) {
  .cmp-teaser.sc-teaserMultiElement img {
    height: 256px;
  }
}
@media only screen and (max-width: 1023px) {
  .cmp-teaser.sc-teaserMultiElement .sdx-sticker img {
    margin-top: 44px;
  }
}
@media only screen and (max-width: 1279px) {
  .aem-GridColumn--default--4 .cmp-teaser.sc-teaserMultiElement .sdx-sticker img {
    margin-top: 44px;
  }
}
.cmp-teaser.sc-teaserMultiElement .sticker_holder:not(.sdx-sticker) img {
  margin-top: 2px;
}
@media screen and (min-width: 480px) {
  .cmp-teaser.sc-teaserMultiElement .sticker_holder:not(.sdx-sticker) img {
    margin-top: 24px;
  }
}
@media screen and (min-width: 768px) {
  .cmp-teaser.sc-teaserMultiElement .sticker_holder:not(.sdx-sticker) img {
    margin-top: 0;
  }
}
@media only screen and (max-width: 1279px) {
  .cmp-teaser.sc-teaserMultiElement .sticker_holder:not(.sdx-sticker) .logo-image-margin img,
  .cmp-teaser.sc-teaserMultiElement .sticker_holder:not(.sdx-sticker) .logo img {
    margin-top: 0;
  }
}
@media only screen and (max-width: 1279px) {
  .cmp-teaser.sc-teaserMultiElement .logo-image-margin img,
  .cmp-teaser.sc-teaserMultiElement .logo img {
    margin-top: 0;
  }
}
.cmp-teaser.sc-teaserMultiElement .logo-above-title .logo-image-margin {
  margin-top: 16px;
}
.cmp-teaser.sc-teaserMultiElement .logo-above-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.cmp-teaser.sc-teaserMultiElement .logo-above-image .logo {
  margin-top: 32px;
}
@media only screen and (min-width: 480px) {
  .cmp-teaser.sc-teaserMultiElement .logo-above-image .logo {
    margin-top: 16px;
  }
}
.cmp-teaser.sc-teaserMultiElement .logo-above-image .logo-image-margin {
  margin-top: 4px;
  margin-bottom: 0px;
}
.cmp-teaser.sc-teaserMultiElement .sticker_holder:not(.sdx-sticker).logo-below-image,
.cmp-teaser.sc-teaserMultiElement .sdx-sticker.logo-below-image .sticker-logo {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
}
.cmp-teaser.sc-teaserMultiElement .sticker_holder:not(.sdx-sticker).logo-below-image .logo-image-margin,
.cmp-teaser.sc-teaserMultiElement .sdx-sticker.logo-below-image .sticker-logo .logo-image-margin {
  margin-top: 32px;
  margin-bottom: 4px;
}
@media only screen and (min-width: 480px) {
  .cmp-teaser.sc-teaserMultiElement .sticker_holder:not(.sdx-sticker).logo-below-image .logo-image-margin,
  .cmp-teaser.sc-teaserMultiElement .sdx-sticker.logo-below-image .sticker-logo .logo-image-margin {
    margin-top: 16px;
  }
}
.cmp-teaser.sc-teaserMultiElement .sdx-sticker.logo-below-image .sticker-mobile .sticker-logo .logo img {
  margin-top: 0;
}
@media only screen and (max-width: 767px) {
  .cmp-teaser.sc-teaserMultiElement .sdx-sticker.logo-below-image .sticker-mobile .sticker-logo .logo-image-margin {
    margin-top: 0;
  }
}
.cmp-teaser.sc-teaserMultiElement .sdx-sticker.logo-above-image .sticker-mobile .sticker-logo .logo-image-margin img {
  margin-top: 0;
}
.cmp-teaser.sc-teaserMultiElement .sdx-sticker.logo-above-image .sticker-mobile .sticker-logo .logo {
  margin-top: 0;
}
@media only screen and (max-width: 1023px) {
  .teaser.aem-GridColumn--default--6 .cmp-teaser.sc-teaserMultiElement .sdx-sticker.logo-below-image .sticker-mobile .sticker-logo,
  .teaser.aem-GridColumn--default--6 .cmp-teaser.sc-teaserMultiElement .sdx-sticker.logo-above-image .sticker-mobile .sticker-logo {
    margin-top: 44px;
  }
}
@media only screen and (max-width: 1023px) {
  .teaser.aem-GridColumn--default--6 .cmp-teaser.sc-teaserMultiElement .sdx-sticker.logo-below-image .sticker-mobile .sticker-logo .logo-image-margin {
    margin-top: 0;
  }
}
.teaser.aem-GridColumn--default--6 .cmp-teaser.sc-teaserMultiElement .landscape-image .centralized_over .sticker_holder img {
  width: auto;
  height: 172px;
  margin-top: 0;
}
@media only screen and (min-width: 480px) {
  .teaser.aem-GridColumn--default--6 .cmp-teaser.sc-teaserMultiElement .landscape-image .centralized_over .sticker_holder img {
    height: 178px;
    margin-top: 32px;
  }
}
@media only screen and (min-width: 768px) {
  .teaser.aem-GridColumn--default--6 .cmp-teaser.sc-teaserMultiElement .landscape-image .centralized_over .sticker_holder img {
    height: 219px;
    margin-top: 8px;
  }
}
@media only screen and (min-width: 1024px) {
  .teaser.aem-GridColumn--default--6 .cmp-teaser.sc-teaserMultiElement .landscape-image .centralized_over .sticker_holder img {
    height: 256px;
  }
}
.teaser.aem-GridColumn--default--6 .cmp-teaser.sc-teaserMultiElement .landscape-image .centralized_over .sticker_holder.sdx-sticker img {
  margin-top: 64px;
}
@media only screen and (min-width: 480px) {
  .teaser.aem-GridColumn--default--6 .cmp-teaser.sc-teaserMultiElement .landscape-image .centralized_over .sticker_holder.sdx-sticker img {
    margin-top: 56px;
  }
}
@media only screen and (min-width: 768px) {
  .teaser.aem-GridColumn--default--6 .cmp-teaser.sc-teaserMultiElement .landscape-image .centralized_over .sticker_holder.sdx-sticker img {
    margin-top: 44px;
  }
}
@media only screen and (min-width: 1024px) {
  .teaser.aem-GridColumn--default--6 .cmp-teaser.sc-teaserMultiElement .landscape-image .centralized_over .sticker_holder.sdx-sticker img {
    margin-top: 0;
  }
}
.teaser.aem-GridColumn--default--6 .cmp-teaser.sc-teaserMultiElement .landscape-image.landscape-logo .centralized_over .sticker_holder img {
  margin-top: 0;
}
.teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserMultiElement .landscape-image.landscape-logo .centralized_over .logo-above-image img,
.teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserMultiElement .landscape-image.landscape-logo .centralized_over .logo-below-image img,
.teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserMultiElement .landscape-image.landscape-logo .centralized_over .logo-above-title img {
  height: 172px;
  width: auto;
}
@media only screen and (min-width: 480px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserMultiElement .landscape-image.landscape-logo .centralized_over .logo-above-image img,
  .teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserMultiElement .landscape-image.landscape-logo .centralized_over .logo-below-image img,
  .teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserMultiElement .landscape-image.landscape-logo .centralized_over .logo-above-title img {
    height: 256px;
  }
}
@media only screen and (min-width: 768px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserMultiElement .landscape-image.landscape-logo .centralized_over .logo-above-image img,
  .teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserMultiElement .landscape-image.landscape-logo .centralized_over .logo-below-image img,
  .teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserMultiElement .landscape-image.landscape-logo .centralized_over .logo-above-title img {
    height: 214px;
  }
}
@media only screen and (min-width: 1024px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserMultiElement .landscape-image.landscape-logo .centralized_over .logo-above-image img,
  .teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserMultiElement .landscape-image.landscape-logo .centralized_over .logo-below-image img,
  .teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserMultiElement .landscape-image.landscape-logo .centralized_over .logo-above-title img {
    height: 256px;
  }
}
.teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserMultiElement .landscape-image.landscape-logo .centralized_over .logo img {
  margin-top: 0;
}
.teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserMultiElement .landscape-image.landscape-logo .sdx-sticker .centralized_over .logo-above-image img,
.teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserMultiElement .landscape-image.landscape-logo .sdx-sticker .centralized_over .logo-below-image img,
.teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserMultiElement .landscape-image.landscape-logo .sdx-sticker .centralized_over .logo-above-title img {
  margin-top: 44px;
}
@media only screen and (min-width: 1024px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserMultiElement .landscape-image.landscape-logo .sdx-sticker .centralized_over .logo-above-image img,
  .teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserMultiElement .landscape-image.landscape-logo .sdx-sticker .centralized_over .logo-below-image img,
  .teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserMultiElement .landscape-image.landscape-logo .sdx-sticker .centralized_over .logo-above-title img {
    margin-top: 0;
  }
}
.teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserMultiElement .landscape-image.landscape-logo .description_over .logo-above-title img {
  margin-top: 0px !important;
}
@media only screen and (min-width: 768px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserMultiElement .landscape-image.landscape-logo.teaser-banner .centralized_over {
    justify-content: flex-end;
    align-items: center;
  }
}
.teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserMultiElement .landscape-image.landscape-logo.teaser-banner .centralized_over .logo-above-image,
.teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserMultiElement .landscape-image.landscape-logo.teaser-banner .centralized_over .logo-below-image {
  width: auto;
}
@media only screen and (max-width: 1023px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserMultiElement .landscape-image .description_over {
    width: 100%;
    align-items: flex-start;
    padding-left: 5%;
    padding-right: 5%;
  }
  .teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserMultiElement .landscape-image .description_over .description_over_wrapper {
    text-align: left;
  }
  .teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserMultiElement .landscape-image .description_over .description_over_wrapper .title {
    width: 100%;
    text-align: left;
  }
  .teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserMultiElement .landscape-image .description_over .description_over_wrapper .subtitle {
    display: block;
    margin-bottom: 8px;
  }
}
@media only screen and (max-width: 479px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserMultiElement .landscape-image .description_over {
    width: 100%;
    padding-left: 10%;
    padding-right: 10%;
  }
}
.teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserMultiElement .landscape-image.teaser-banner .centralized_over {
  justify-content: flex-end;
  align-items: flex-start;
}
@media only screen and (min-width: 768px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserMultiElement .landscape-image.teaser-banner .centralized_over {
    align-items: center;
  }
}
.teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserMultiElement .landscape-image.teaser-banner .centralized_over .sticker_holder {
  margin-right: auto;
  width: auto;
}
@media only screen and (min-width: 768px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserMultiElement .landscape-image.teaser-banner .centralized_over .sticker_holder {
    margin-right: 3.8%;
  }
}
@media only screen and (min-width: 1280px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserMultiElement .landscape-image.teaser-banner .centralized_over .sticker_holder {
    margin-right: 14.82%;
  }
}
@media only screen and (min-width: 1024px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserMultiElement .landscape-image.teaser-banner .centralized_over .sticker_holder:not(.sdx-sticker) {
    margin-right: 3.8%;
  }
}
@media only screen and (min-width: 1280px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserMultiElement .landscape-image.teaser-banner .centralized_over .sticker_holder:not(.sdx-sticker) {
    margin-right: 14.82%;
  }
}
.teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserMultiElement .landscape-image:not(.teaser-banner) .centralized_over .sticker_holder {
  justify-content: center;
  margin-right: 0;
}
.teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserMultiElement .landscape-image .sticker_holder.sdx-sticker {
  margin-right: auto;
}
@media screen and (min-width: 768px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserMultiElement .landscape-image .sticker_holder.sdx-sticker {
    margin-right: 3.8%;
  }
}
@media screen and (min-width: 1280px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserMultiElement .landscape-image .sticker_holder.sdx-sticker {
    margin-right: 14.82%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserMultiElement .landscape-image .sticker_holder.sdx-sticker {
    align-self: flex-end;
  }
}
@media only screen and (max-width: 767px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserMultiElement .landscape-image .sticker_holder.sdx-sticker {
    height: 100%;
  }
}
@media only screen and (max-width: 1023px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserMultiElement .landscape-image .sticker_holder.sdx-sticker img {
    margin-top: 44px;
  }
}
@media only screen and (max-width: 767px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserMultiElement .landscape-image .sticker_holder.sdx-sticker img {
    margin-top: 56px;
  }
}
@media only screen and (max-width: 479px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserMultiElement .landscape-image .sticker_holder.sdx-sticker img {
    margin-top: 64px;
  }
}
.teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserMultiElement .landscape-image img {
  width: auto;
  height: 172px;
  margin-top: 22px;
}
@media only screen and (min-width: 480px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserMultiElement .landscape-image img {
    height: 256px;
    margin-top: 26px;
  }
}
@media only screen and (min-width: 768px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserMultiElement .landscape-image img {
    height: 214px;
    margin-top: 36px;
  }
}
@media only screen and (min-width: 1024px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserMultiElement .landscape-image img {
    height: 256px;
    margin-top: 36px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserMultiElement .landscape-image .description_over .title {
    font-size: 40px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserMultiElement .landscape-image.teaser-banner .description_over .title {
    max-height: 96px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserMultiElement .landscape-image.teaser-banner .description_over .title {
    font-size: 40px;
    line-height: 48px;
    max-height: 96px;
  }
}
@media only screen and (min-width: 768px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserMultiElement .landscape-image.teaser-banner img {
    margin-top: 0 !important;
  }
}
@media only screen and (max-width: 479px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserMultiElement .landscape-image:not(.teaser-banner) img {
    margin-top: 0;
  }
}
@media only screen and (min-width: 768px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserMultiElement .teaser-banner .centralized_over .sticker_holder {
    display: flex;
    align-items: center;
  }
}
@media only screen and (max-width: 1023px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserMultiElement .teaser-banner img {
    margin-top: 44px;
  }
}
.teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserMultiElement .teaser-banner .centralized_over .logo-above-image,
.teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserMultiElement .teaser-banner .centralized_over .logo-below-image {
  height: 100%;
  width: 170px;
}
@media only screen and (min-width: 480px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserMultiElement .teaser-banner .centralized_over .logo-above-image,
  .teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserMultiElement .teaser-banner .centralized_over .logo-below-image {
    width: 256px;
  }
}
@media only screen and (min-width: 1024px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserMultiElement .teaser-banner .centralized_over .logo-above-image,
  .teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserMultiElement .teaser-banner .centralized_over .logo-below-image {
    width: 350px;
  }
}
@media only screen and (min-width: 1280px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserMultiElement .teaser-banner .centralized_over .logo-above-image,
  .teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserMultiElement .teaser-banner .centralized_over .logo-below-image {
    width: 368px;
  }
}
.teaser.aem-GridColumn--default--6 .cmp-teaser.sc-teaserMultiElement .teaser-banner .centralized_over .logo-above-image .logo img,
.teaser.aem-GridColumn--default--6 .cmp-teaser.sc-teaserMultiElement .teaser-banner .centralized_over .logo-below-image .logo img {
  height: 24px !important;
}
@media only screen and (max-width: 767px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserMultiElement .teaser-banner .centralized_over .logo-above-image {
    justify-content: flex-start;
  }
  .teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserMultiElement .teaser-banner .centralized_over .logo-below-image {
    justify-content: flex-end;
  }
}
@media only screen and (max-width: 479px) {
  .cmp-teaser.sc-teaserMultiElement .teaser-banner .teaser.aem-GridColumn--default--12 .cmp-teaser .centralized_over .logo-above-image .logo-image-margin img {
    margin-top: 0;
  }
}
.teaser .cmp-teaser.sc-teaserMultiElement .teaser-banner .centralized_over .sticker_holder:not(.sdx-sticker).logo-above-title .logo-image-margin {
  margin-top: 32px;
}
@media only screen and (min-width: 480px) {
  .teaser .cmp-teaser.sc-teaserMultiElement .teaser-banner .centralized_over .sticker_holder:not(.sdx-sticker).logo-above-title .logo-image-margin {
    margin-top: 16px;
  }
}
@media only screen and (max-width: 767px) {
  .teaser .cmp-teaser.sc-teaserMultiElement .teaser-banner .centralized_over .sticker_holder.sdx-sticker.logo-above-title .logo-image-margin {
    margin-top: 0;
  }
}
@media only screen and (max-width: 1023px) {
  .teaser.aem-GridColumn--default--6 .cmp-teaser.sc-teaserMultiElement .teaser-banner .centralized_over .sticker_holder.sdx-sticker.logo-above-title .logo-image-margin {
    margin-top: 0;
  }
  .teaser.aem-GridColumn--default--6 .cmp-teaser.sc-teaserMultiElement .teaser-banner .centralized_over .sticker_holder.sdx-sticker.logo-above-title .logo-image-margin img {
    margin-top: 44px;
  }
}
.cmp-teaser.sc-teaserMultiElement .teaser-banner .centralized_over .logo-above-title {
  display: flex;
  align-items: center;
}
.teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserMultiElement .teaser-banner .centralized_over .logo-above-title img {
  width: 170px;
  height: 100%;
}
@media only screen and (min-width: 480px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserMultiElement .teaser-banner .centralized_over .logo-above-title img {
    width: 300px;
  }
}
@media only screen and (min-width: 1024px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserMultiElement .teaser-banner .centralized_over .logo-above-title img {
    width: 350px;
  }
}
@media only screen and (min-width: 1280px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserMultiElement .teaser-banner .centralized_over .logo-above-title img {
    width: 400px;
  }
}
@media only screen and (max-width: 767px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserMultiElement .teaser-banner .centralized_over .logo-above-title {
    align-items: flex-start;
  }
}
@media only screen and (max-width: 479px) {
  .teaser.aem-GridColumn--default--6 .cmp-teaser.sc-teaserMultiElement .teaser-banner .centralized_over .logo-above-title .logo-image-margin {
    margin-top: 32px;
  }
}
.cmp-teaser.sc-teaserMultiElement .teaser-banner .description_over .logo-above-title .logo {
  margin-bottom: 8px;
}
@media only screen and (min-width: 768px) {
  .cmp-teaser.sc-teaserMultiElement .teaser-banner .description_over .logo-above-title .logo {
    margin-bottom: 16px;
  }
}
@media only screen and (max-width: 1023px) {
  .teaser.aem-GridColumn--default--6 .cmp-teaser.sc-teaserMultiElement .teaser-banner .description_over .logo-above-title .logo {
    margin-bottom: 8px;
  }
}
@media only screen and (max-width: 1023px) {
  .cmp-teaser.sc-teaserMultiElement .teaser-banner .description_over .logo-above-title .logo img {
    margin-top: 0;
  }
}
.teaser.aem-GridColumn--default--6 .cmp-teaser.sc-teaserMultiElement .teaser-banner .description_over .logo-above-title .logo img {
  height: 24px !important;
}
.cmp-teaser.sc-teaserMultiElement.sc-teaser-content-banner .img-wrapper[style] {
  min-height: 452px !important;
}
@media screen and (min-width: 480px) {
  .cmp-teaser.sc-teaserMultiElement.sc-teaser-content-banner .img-wrapper[style] {
    min-height: 586px !important;
  }
}
@media screen and (min-width: 768px) {
  .cmp-teaser.sc-teaserMultiElement.sc-teaser-content-banner .img-wrapper[style].has-sticker {
    min-height: 280px !important;
  }
}
@media screen and (min-width: 1024px) {
  .cmp-teaser.sc-teaserMultiElement.sc-teaser-content-banner .img-wrapper[style].has-sticker {
    min-height: 240px !important;
  }
}
@media screen and (min-width: 768px) {
  .cmp-teaser.sc-teaserMultiElement.sc-teaser-content-banner .img-wrapper[style]:not(.has-sticker) {
    min-height: 240px !important;
  }
}
@media screen and (min-width: 1024px) {
  .cmp-teaser.sc-teaserMultiElement.sc-teaser-content-banner .img-wrapper[style]:not(.has-sticker) {
    min-height: 240px !important;
  }
}
.cmp-teaser.sc-teaserMultiElement.sc-teaser-content-banner .description_over {
  padding-bottom: 20px;
  padding-left: 24px;
}
.cmp-teaser.sc-teaserMultiElement.sc-teaser-content-banner .description_over .title {
  margin-bottom: 8px;
}
.cmp-teaser.sc-teaserMultiElement.sc-teaser-content-banner .description_over .title-description p {
  margin-bottom: 0;
}
@media screen and (min-width: 1024px) {
  .cmp-teaser.sc-teaserMultiElement.sc-teaser-content-banner .description_over.description-sdx-sticker {
    padding-bottom: 8px;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    height: fit-content;
  }
}
@media screen and (min-width: 768px) {
  .cmp-teaser.sc-teaserMultiElement.sc-teaser-content-banner .description_over:not(.description-sdx-sticker) {
    padding-bottom: 8px;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    height: fit-content;
  }
}
@media only screen and (min-width: 768px) {
  .cmp-teaser.sc-teaserMultiElement.sc-teaser-content-banner .description_over {
    width: 48%;
  }
}
@media only screen and (min-width: 1440px) {
  .cmp-teaser.sc-teaserMultiElement.sc-teaser-content-banner .description_over {
    width: 55%;
  }
}
.cmp-teaser.sc-teaserMultiElement.sc-teaser-content-banner .centralized_over,
.cmp-teaser.sc-teaserMultiElement.sc-teaser-content-banner .teaser-banner .centralized_over {
  justify-content: center;
}
@media only screen and (min-width: 768px) {
  .cmp-teaser.sc-teaserMultiElement.sc-teaser-content-banner .centralized_over,
  .cmp-teaser.sc-teaserMultiElement.sc-teaser-content-banner .teaser-banner .centralized_over {
    justify-content: flex-end;
  }
}
.cmp-teaser.sc-teaserMultiElement.sc-teaser-content-banner .centralized_over .sticker_holder,
.cmp-teaser.sc-teaserMultiElement.sc-teaser-content-banner .teaser-banner .centralized_over .sticker_holder {
  margin-right: 0;
}
@media only screen and (max-width: 767px) {
  .cmp-teaser.sc-teaserMultiElement.sc-teaser-content-banner .centralized_over .sticker_holder:not(.sdx-sticker),
  .cmp-teaser.sc-teaserMultiElement.sc-teaser-content-banner .teaser-banner .centralized_over .sticker_holder:not(.sdx-sticker) {
    height: 100%;
  }
}
.cmp-teaser.sc-teaserMultiElement.sc-teaser-content-banner .centralized_over .sticker_holder:not(.sdx-sticker) img,
.cmp-teaser.sc-teaserMultiElement.sc-teaser-content-banner .teaser-banner .centralized_over .sticker_holder:not(.sdx-sticker) img {
  margin-top: 0;
}
.cmp-teaser.sc-teaserMultiElement.sc-teaser-content-banner .centralized_over .sticker_holder.sdx-sticker,
.cmp-teaser.sc-teaserMultiElement.sc-teaser-content-banner .teaser-banner .centralized_over .sticker_holder.sdx-sticker {
  margin-right: 0 !important;
}
@media only screen and (max-width: 767px) {
  .cmp-teaser.sc-teaserMultiElement.sc-teaser-content-banner .centralized_over .sticker_holder.sdx-sticker,
  .cmp-teaser.sc-teaserMultiElement.sc-teaser-content-banner .teaser-banner .centralized_over .sticker_holder.sdx-sticker {
    height: 100%;
  }
}
@media only screen and (max-width: 1023px) {
  .cmp-teaser.sc-teaserMultiElement.sc-teaser-content-banner .centralized_over .sticker_holder.sdx-sticker .sticker-ribbon-mobile,
  .cmp-teaser.sc-teaserMultiElement.sc-teaser-content-banner .centralized_over .sticker_holder.sdx-sticker .sticker-mobile,
  .cmp-teaser.sc-teaserMultiElement.sc-teaser-content-banner .teaser-banner .centralized_over .sticker_holder.sdx-sticker .sticker-ribbon-mobile,
  .cmp-teaser.sc-teaserMultiElement.sc-teaser-content-banner .teaser-banner .centralized_over .sticker_holder.sdx-sticker .sticker-mobile {
    display: block;
  }
  .cmp-teaser.sc-teaserMultiElement.sc-teaser-content-banner .centralized_over .sticker_holder.sdx-sticker .sticker-desktop,
  .cmp-teaser.sc-teaserMultiElement.sc-teaser-content-banner .teaser-banner .centralized_over .sticker_holder.sdx-sticker .sticker-desktop {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .cmp-teaser.sc-teaserMultiElement.sc-teaser-content-banner .centralized_over .sticker_holder,
  .cmp-teaser.sc-teaserMultiElement.sc-teaser-content-banner .teaser-banner .centralized_over .sticker_holder {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
@media screen and (min-width: 768px) {
  .cmp-teaser.sc-teaserMultiElement.sc-teaser-content-banner .centralized_over .sticker_holder,
  .cmp-teaser.sc-teaserMultiElement.sc-teaser-content-banner .teaser-banner .centralized_over .sticker_holder {
    max-width: 50%;
  }
}
.cmp-teaser.sc-teaserMultiElement.sc-teaser-content-banner .centralized_over .sticker_holder img,
.cmp-teaser.sc-teaserMultiElement.sc-teaser-content-banner .teaser-banner .centralized_over .sticker_holder img {
  width: auto;
  height: 186px;
}
@media only screen and (min-width: 480px) {
  .cmp-teaser.sc-teaserMultiElement.sc-teaser-content-banner .centralized_over .sticker_holder img,
  .cmp-teaser.sc-teaserMultiElement.sc-teaser-content-banner .teaser-banner .centralized_over .sticker_holder img {
    height: 317px;
  }
}
@media only screen and (min-width: 768px) {
  .cmp-teaser.sc-teaserMultiElement.sc-teaser-content-banner .centralized_over .sticker_holder img,
  .cmp-teaser.sc-teaserMultiElement.sc-teaser-content-banner .teaser-banner .centralized_over .sticker_holder img {
    height: 240px;
    max-width: 460px;
    margin-top: 40px;
  }
}
@media only screen and (min-width: 1024px) {
  .cmp-teaser.sc-teaserMultiElement.sc-teaser-content-banner .centralized_over .sticker_holder img,
  .cmp-teaser.sc-teaserMultiElement.sc-teaser-content-banner .teaser-banner .centralized_over .sticker_holder img {
    margin-top: 0;
    width: auto;
    max-width: 551px;
  }
}
@media only screen and (max-width: 1023px) {
  .cmp-teaser.sc-teaserMultiElement.sc-teaser-content-banner .centralized_over .sticker_holder img,
  .cmp-teaser.sc-teaserMultiElement.sc-teaser-content-banner .teaser-banner .centralized_over .sticker_holder img {
    margin-top: 40px;
  }
}
.cmp-teaser.sc-teaserMultiElement.sc-teaser-content-banner .margin-top-3 {
  margin-top: 0 !important;
}
.cmp-teaser.cmp-teaser_fullbleed {
  left: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  width: 100vw;
  right: 50%;
}
@media screen and (min-width: 768px) and (max-width: 1279px), screen and (min-width: 1440px) {
  .page:not(.home-page) .teaser.aem-GridColumn--default--12:not(.sc-teaser-text-central-alignment):not(.sc-teaser-text-central-alignment-bottom):not(.sc-teaser-text-central-alignment-top) .cmp-teaser.cmp-teaser_fullbleed .teaser-banner .description_over {
    margin-left: auto;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .page:not(.home-page) .teaser.aem-GridColumn--default--12:not(.sc-teaser-text-central-alignment):not(.sc-teaser-text-central-alignment-bottom):not(.sc-teaser-text-central-alignment-top) .cmp-teaser.cmp-teaser_fullbleed .teaser-banner.picture .description_over {
    width: auto;
    margin-left: 32px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .page:not(.home-page) .teaser.aem-GridColumn--default--12:not(.sc-teaser-text-central-alignment):not(.sc-teaser-text-central-alignment-bottom):not(.sc-teaser-text-central-alignment-top) .cmp-teaser.cmp-teaser_fullbleed .teaser-banner.picture .description_over {
    margin-left: 36px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1279px), screen and (min-width: 1440px) {
  .page:not(.home-page) .teaser.aem-GridColumn--default--12:not(.sc-teaser-text-central-alignment):not(.sc-teaser-text-central-alignment-bottom):not(.sc-teaser-text-central-alignment-top) .cmp-teaser.cmp-teaser_fullbleed .description_over {
    left: 0;
    right: 0;
    margin: 0 auto;
    width: auto;
    max-width: 1380px;
  }
  .page:not(.home-page) .teaser.aem-GridColumn--default--12:not(.sc-teaser-text-central-alignment):not(.sc-teaser-text-central-alignment-bottom):not(.sc-teaser-text-central-alignment-top) .cmp-teaser.cmp-teaser_fullbleed .description_over .description_over_wrapper {
    width: 54%;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .page:not(.home-page) .teaser.aem-GridColumn--default--12:not(.sc-teaser-text-central-alignment):not(.sc-teaser-text-central-alignment-bottom):not(.sc-teaser-text-central-alignment-top) .cmp-teaser.cmp-teaser_fullbleed .teaser-banner .description_over {
    margin-left: 32px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .page:not(.home-page) .teaser.aem-GridColumn--default--12:not(.sc-teaser-text-central-alignment):not(.sc-teaser-text-central-alignment-bottom):not(.sc-teaser-text-central-alignment-top) .cmp-teaser.cmp-teaser_fullbleed .teaser-banner .description_over {
    margin-left: 36px;
  }
}
.sc-teaser-text-central-alignment .cmp-teaser .testimonial-desc h4,
.sc-teaser-text-central-alignment .cmp-teaser .testimonial-desc div.subtitle,
.sc-teaser-text-central-alignment .cmp-teaser .testimonial-desc p {
  text-align: center;
}
.cmp-teaser .description_over {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0 24px 24px 24px;
}
@media only screen and (min-width: 768px) {
  .cmp-teaser .description_over {
    width: 90%;
    padding: 0 32px 40px 32px;
  }
}
@media only screen and (min-width: 1024px) {
  .cmp-teaser .description_over {
    width: 85%;
    padding: 0 32px 32px 32px;
  }
}
@media only screen and (min-width: 1280px) {
  .cmp-teaser .description_over {
    width: 66%;
  }
}
@media only screen and (max-width: 767px) {
  .cmp-teaser .description_over button {
    width: 100%;
  }
}
.aem-GridColumn--default--4 .cmp-teaser .description_over {
  width: 100%;
}
@media screen and (min-width: 768px) and (max-width: 1439px) {
  .aem-GridColumn--default--4 .cmp-teaser .description_over .title {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -1px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1279px) {
  .aem-GridColumn--default--6 .cmp-teaser .description_over {
    width: 100%;
  }
}
@media screen and (min-width: 1280px) {
  .aem-GridColumn--default--6 .cmp-teaser .description_over {
    width: 85%;
  }
}
.sc-teaser-text-central-alignment .cmp-teaser .description_over, .sc-teaser-text-central-alignment-bottom .cmp-teaser .description_over, .sc-teaser-text-central-alignment-top .cmp-teaser .description_over {
  width: 100%;
}
.sc-teaser-text-central-alignment .cmp-teaser .description_over .description_over_wrapper, .sc-teaser-text-central-alignment-bottom .cmp-teaser .description_over .description_over_wrapper, .sc-teaser-text-central-alignment-top .cmp-teaser .description_over .description_over_wrapper {
  text-align: center;
  margin: 0 auto;
  width: 90%;
}
.sc-teaser-text-central-alignment .cmp-teaser .description_over .description_over_wrapper > *, .sc-teaser-text-central-alignment-bottom .cmp-teaser .description_over .description_over_wrapper > *, .sc-teaser-text-central-alignment-top .cmp-teaser .description_over .description_over_wrapper > * {
  text-align: center;
}
.sc-teaser-text-central-alignment .cmp-teaser .description_over {
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  height: fit-content;
  padding: 32px;
}
.sc-teaser-text-central-alignment-bottom .cmp-teaser .description_over {
  bottom: 0;
}
.sc-teaser-text-central-alignment-top .cmp-teaser .description_over {
  top: 32px;
}
.cmp-teaser .description_over .subtitle {
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.1px;
  margin-bottom: 8px;
}
@media only screen and (min-width: 480px) {
  .cmp-teaser .description_over .subtitle {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 4px;
  }
}
@media only screen and (min-width: 768px) {
  .cmp-teaser .description_over .subtitle {
    letter-spacing: -0.35px;
  }
}
@media only screen and (min-width: 1024px) {
  .cmp-teaser .description_over .subtitle {
    line-height: 28px;
    letter-spacing: -0.6px;
  }
}
.cmp-teaser .description_over .title {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.35px;
  width: 100%;
  margin-bottom: 16px;
}
@media only screen and (min-width: 480px) {
  .cmp-teaser .description_over .title {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -1px;
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 768px) {
  .cmp-teaser .description_over .title {
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -1.2px;
    margin-bottom: 22px;
  }
}
.cmp-teaser .description_over .title-description {
  font-size: 24px;
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: -0.96px;
  width: 100%;
  margin-bottom: 24px;
}
.cmp-teaser .description_over .title-description.no-action {
  margin-bottom: 0;
}
@media only screen and (min-width: 480px) {
  .cmp-teaser .description_over .title-description {
    font-size: 32px;
  }
}
.cmp-teaser .description_over .subtitle:empty,
.cmp-teaser .description_over .title:empty,
.cmp-teaser .description_over .title-description:empty {
  display: none;
}
.cmp-teaser .description_over .description_over_wrapper .title.word-wrap {
  hyphens: auto;
}
@media only screen and (min-width: 480px) {
  .cmp-teaser .landscape-image .description_over .subtitle {
    line-height: 28px;
    letter-spacing: -0.6px;
  }
}
@media only screen and (min-width: 768px) {
  .cmp-teaser .landscape-image .description_over .title {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -1px;
  }
}
@media only screen and (min-width: 1024px) {
  .cmp-teaser .landscape-image .description_over .title {
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -1.2px;
  }
}
@media only screen and (max-width: 479px) {
  .cmp-teaser .landscape-image.teaser-banner .centralized_over .sticker_holder img, .teaser.aem-GridColumn--default--6 .cmp-teaser .landscape-image.teaser-banner .centralized_over .sticker_holder img {
    margin-top: 24px;
  }
}
.teaser.sc-teaser-text-central-alignment .cmp-teaser.sc-teaserPicture .picture .description_over, .teaser.sc-teaser-text-central-alignment-bottom .cmp-teaser.sc-teaserPicture .picture .description_over, .sc-teaser-text-central-alignment-top .cmp-teaser.sc-teaserPicture .picture .description_over,
.teaser.sc-teaser-text-central-alignment .cmp-teaser.sc-teaserPicture .teaser-banner.picture .description_over,
.teaser.sc-teaser-text-central-alignment-bottom .cmp-teaser.sc-teaserPicture .teaser-banner.picture .description_over,
.sc-teaser-text-central-alignment-top .cmp-teaser.sc-teaserPicture .teaser-banner.picture .description_over {
  width: 100%;
  margin-left: 0;
}
@media only screen and (max-width: 479px) {
  .cmp-teaser:not(.sc-teaserStandard) .centralized_over .sticker_holder img {
    height: 170px;
  }
  .cmp-teaser:not(.sc-teaserStandard) .landscape-image .centralized_over .sticker_holder img {
    height: 172px;
  }
}
.cmp-teaser .centralized_over {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  top: 0;
  overflow: hidden;
}
@media only screen and (min-width: 1024px) {
  .cmp-teaser .centralized_over {
    align-items: center;
  }
}
@media only screen and (max-width: 479px) {
  .cmp-teaser .centralized_over .sticker_holder {
    display: flex;
    justify-content: center;
  }
}
.cmp-teaser .centralized_over .sticker_holder .sticker-ribbon-mobile {
  display: none;
}
.cmp-teaser .centralized_over .sticker_holder.sdx-sticker .sticker-ribbon-mobile {
  display: none;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: -0.23px;
  font-weight: 400;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  max-height: 40px;
  padding: 8px;
  overflow: hidden;
}
.cmp-teaser .centralized_over .sticker_holder.sdx-sticker .sticker-ribbon-mobile sdx-icon {
  margin-right: 4px;
}
.cmp-teaser .centralized_over .sticker_holder.sdx-sticker .sticker-ribbon-mobile.bg-orchid {
  background-color: #a63297;
}
.cmp-teaser .centralized_over .sticker_holder.sdx-sticker .sticker-ribbon-mobile.bg-sc-white {
  border: 1.5px solid #015;
  background-color: #f8fafb;
  font-weight: 600;
}
.cmp-teaser .centralized_over .sticker_holder.sdx-sticker .sticker-ribbon-mobile.txt-orchid {
  color: #a63297;
}
.cmp-teaser .centralized_over .sticker_holder.sdx-sticker .sticker-ribbon-mobile.txt-sc-white {
  color: #fff;
}
.cmp-teaser .centralized_over .sticker_holder.sdx-sticker .sticker-ribbon-mobile.txt-sc-navy {
  color: #015;
}
.cmp-teaser .centralized_over .sticker_holder.sdx-sticker .sticker-mobile {
  display: none;
}
.cmp-teaser .centralized_over .sticker_holder.sdx-sticker .sticker-desktop {
  display: block;
}
@media only screen and (max-width: 1023px) {
  .cmp-teaser .centralized_over .sticker_holder.sdx-sticker .sticker-ribbon-mobile,
  .cmp-teaser .centralized_over .sticker_holder.sdx-sticker .sticker-mobile {
    display: block;
  }
  .cmp-teaser .centralized_over .sticker_holder.sdx-sticker .sticker-desktop {
    display: none;
  }
}
@media only screen and (max-width: 1279px) {
  .aem-GridColumn--default--4 .cmp-teaser .centralized_over .sticker_holder.sdx-sticker .sticker-ribbon-mobile,
  .aem-GridColumn--default--4 .cmp-teaser .centralized_over .sticker_holder.sdx-sticker .sticker-mobile {
    display: block;
  }
  .aem-GridColumn--default--4 .cmp-teaser .centralized_over .sticker_holder.sdx-sticker .sticker-desktop {
    display: none;
  }
}
.cmp-teaser .centralized_over .sticker_holder .sticker-mobile {
  display: none;
}
.cmp-teaser .centralized_over .sticker_holder .sticker-desktop {
  display: block;
}
.teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserPromotional .teaser-banner .centralized_over {
  height: auto;
}
.teaser.aem-GridColumn--default--12 .cmp-teaser .teaser-banner .centralized_over {
  height: 100%;
}
@media only screen and (min-width: 1024px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser .teaser-banner .centralized_over {
    justify-content: flex-end;
  }
}
.teaser.aem-GridColumn--default--12 .cmp-teaser .teaser-banner .centralized_over .sticker_holder {
  width: 172px;
  margin: 0 auto 14px auto;
}
@media only screen and (min-width: 480px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser .teaser-banner .centralized_over .sticker_holder {
    width: 256px;
    margin: 0 auto 18px auto;
    display: flex;
    justify-content: center;
  }
}
@media only screen and (min-width: 768px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser .teaser-banner .centralized_over .sticker_holder {
    width: 400px;
    height: 400px;
    margin-right: 3.8%;
    display: block;
  }
}
@media only screen and (min-width: 1024px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser .teaser-banner .centralized_over .sticker_holder {
    margin-left: 0;
  }
}
@media only screen and (min-width: 1280px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser .teaser-banner .centralized_over .sticker_holder {
    margin: 0 14.82% 0 0;
  }
}
@media only screen and (min-width: 1024px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser .teaser-banner .centralized_over .sticker_holder:not(.sdx-sticker) {
    margin-right: 3.8%;
  }
}
@media only screen and (min-width: 1280px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser .teaser-banner .centralized_over .sticker_holder:not(.sdx-sticker) {
    margin-right: 14.82%;
  }
}
@media only screen and (max-width: 767px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser .teaser-banner .centralized_over .sticker_holder:not(.sdx-sticker) img {
    margin-top: 16px;
  }
}
@media only screen and (max-width: 479px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser .teaser-banner .centralized_over .sticker_holder:not(.sdx-sticker) img {
    margin-top: 24px;
  }
}
.teaser.aem-GridColumn--default--12 .cmp-teaser .teaser-banner .centralized_over .sticker_holder:not(.sdx-sticker) .logo-image-margin img {
  margin-top: 0;
}
.teaser.aem-GridColumn--default--12 .cmp-teaser .teaser-banner .centralized_over .sticker_holder:not(.sdx-sticker) .logo img {
  margin-top: 0;
}
.teaser.aem-GridColumn--default--12 .cmp-teaser .teaser-banner .description_over {
  width: 100%;
  display: block;
  padding-left: 4.94%;
  padding-right: 4.94%;
}
@media only screen and (min-width: 480px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser .teaser-banner .description_over {
    justify-content: center;
    padding-bottom: 0;
    margin: auto 0;
    padding-bottom: 24px;
  }
}
@media only screen and (min-width: 768px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser .teaser-banner .description_over {
    height: 100%;
    width: 53.6%;
    margin-left: 3.2%;
    display: flex;
    flex-direction: column;
    padding-left: 0;
    padding-right: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
}
@media only screen and (min-width: 1024px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser .teaser-banner .description_over {
    width: 50.6%;
    margin-left: 3.8%;
  }
}
@media only screen and (min-width: 1280px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser .teaser-banner .description_over {
    width: 36%;
    margin-left: 14.82%;
  }
}
@media only screen and (max-width: 767px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser .teaser-banner .description_over .title-description:not(.show-description-mobile) {
    display: none;
  }
  .teaser.aem-GridColumn--default--12 .cmp-teaser .teaser-banner .description_over .subtitle.hide-pretitle-mobile {
    display: none;
  }
}
@media only screen and (max-width: 479px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser .teaser-banner .description_over .title {
    text-align: left;
  }
  .teaser.aem-GridColumn--default--12 .cmp-teaser .teaser-banner .description_over .title,
  .teaser.aem-GridColumn--default--12 .cmp-teaser .teaser-banner .description_over button {
    width: 100%;
  }
}
@media only screen and (min-width: 480px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser .teaser-banner .description_over .subtitle {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.1px;
  }
}
@media only screen and (min-width: 768px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser .teaser-banner .description_over .subtitle {
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.6px;
  }
}
.teaser.aem-GridColumn--default--12 .cmp-teaser .teaser-banner .description_over .title {
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.35px;
  max-height: 64px;
  overflow: hidden;
  margin-bottom: 16px;
}
@media (min-width: 768px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser .teaser-banner .description_over .title {
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -1.2px;
    max-height: 144px;
    overflow: hidden;
    width: auto;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 1024px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser .teaser-banner .description_over .title {
    font-size: 54px;
    line-height: 64px;
    letter-spacing: -1.35px;
    max-height: 192px;
    margin-bottom: 19px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1279px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser .teaser-banner.landscape-image .description_over {
    width: 50%;
  }
}
@media only screen and (min-width: 480px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser .teaser-banner.landscape-image .description_over .subtitle {
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.6px;
  }
}
@media (min-width: 480px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser .teaser-banner.landscape-image .description_over .title {
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -1.2px;
  }
}
@media (min-width: 1024px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser .teaser-banner.landscape-image .description_over .title {
    font-size: 54px;
    line-height: 64px;
    letter-spacing: -1.35px;
  }
}
@media only screen and (min-width: 768px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser .teaser-banner img {
    height: auto;
    width: 87.6%;
  }
}
@media only screen and (min-width: 1280px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser .teaser-banner img {
    height: auto;
    width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser .teaser-banner img {
    margin-top: 10px;
  }
}
@media only screen and (max-width: 479px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser .teaser-banner img {
    width: auto;
    margin-top: 44px;
  }
}
@media only screen and (min-width: 768px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser .teaser-banner:not(.landscape-image) .countdown-container {
    height: auto;
    width: 87.6%;
  }
  .teaser.aem-GridColumn--default--12 .cmp-teaser .teaser-banner:not(.landscape-image) .countdown-container img {
    height: auto;
    width: 100%;
  }
}
.teaser.aem-GridColumn--default--12 .cmp-teaser .teaser-banner .logo-above-image .logo-image-margin img,
.teaser.aem-GridColumn--default--12 .cmp-teaser .teaser-banner .logo-below-image .logo-image-margin img {
  height: auto;
  width: 100%;
}
.teaser.aem-GridColumn--default--12:not(.sc-teaser-text-central-alignment):not(.sc-teaser-text-central-alignment-bottom):not(.sc-teaser-text-central-alignment-top) .cmp-teaser .teaser-banner.picture .description_over {
  padding-left: 0;
  padding-right: 0;
  width: 64.2%;
  margin-left: 3.2%;
}
@media only screen and (min-width: 1024px) {
  .teaser.aem-GridColumn--default--12:not(.sc-teaser-text-central-alignment):not(.sc-teaser-text-central-alignment-bottom):not(.sc-teaser-text-central-alignment-top) .cmp-teaser .teaser-banner.picture .description_over {
    width: 50.6%;
    margin-left: 3.8%;
  }
}
@media only screen and (min-width: 1280px) {
  .teaser.aem-GridColumn--default--12:not(.sc-teaser-text-central-alignment):not(.sc-teaser-text-central-alignment-bottom):not(.sc-teaser-text-central-alignment-top) .cmp-teaser .teaser-banner.picture .description_over {
    width: 34%;
    margin-left: 14.82%;
  }
}
@media only screen and (min-width: 768px) {
  .teaser.aem-GridColumn--default--12:not(.sc-teaser-text-central-alignment):not(.sc-teaser-text-central-alignment-bottom):not(.sc-teaser-text-central-alignment-top) .cmp-teaser .teaser-banner.picture .description_over {
    top: 49.7%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}
.teaser.aem-GridColumn--default--12:not(.sc-teaser-text-central-alignment):not(.sc-teaser-text-central-alignment-bottom):not(.sc-teaser-text-central-alignment-top) .cmp-teaser .teaser-banner.picture .description_over .subtitle {
  font-size: 24px;
  margin-bottom: 14px;
}
.teaser.aem-GridColumn--default--12:not(.sc-teaser-text-central-alignment):not(.sc-teaser-text-central-alignment-bottom):not(.sc-teaser-text-central-alignment-top) .cmp-teaser .teaser-banner.picture .description_over .title {
  margin-bottom: 16px;
}
@media only screen and (max-width: 479px) {
  .teaser.aem-GridColumn--default--12:not(.sc-teaser-text-central-alignment):not(.sc-teaser-text-central-alignment-bottom):not(.sc-teaser-text-central-alignment-top) .cmp-teaser .teaser-banner.picture .description_over .title {
    hyphens: manual;
  }
}
@media only screen and (min-width: 768px) {
  .teaser.aem-GridColumn--default--12:not(.sc-teaser-text-central-alignment):not(.sc-teaser-text-central-alignment-bottom):not(.sc-teaser-text-central-alignment-top) .cmp-teaser .teaser-banner .description_over {
    align-items: flex-start;
  }
}
@media only screen and (min-width: 1440px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser.cmp-teaser_fullbleed .teaser-banner .description_over,
  .teaser.aem-GridColumn--default--12 .cmp-teaser.cmp-teaser_fullbleed .teaser-banner .centralized_over {
    left: 0;
    right: 0;
    margin: 0 auto;
    width: auto;
    max-width: 1380px;
  }
}
@media only screen and (min-width: 1440px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser.cmp-teaser_fullbleed .teaser-banner .description_over .description_over_wrapper {
    width: 40%;
    margin-left: 12px;
  }
}
@media only screen and (min-width: 1440px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser.cmp-teaser_fullbleed .teaser-banner .centralized_over {
    justify-content: flex-end;
    align-items: center;
  }
}
@media only screen and (min-width: 1440px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser.cmp-teaser_fullbleed .teaser-banner .centralized_over .sticker_holder.sdx-sticker {
    margin-right: 90px;
  }
}
@media only screen and (min-width: 1440px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser.cmp-teaser_fullbleed .teaser-banner .centralized_over .sticker_holder:not(.sdx-sticker) {
    margin-right: 8px;
  }
}
@media only screen and (min-width: 768px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserPromotional .teaser-banner img {
    width: 100%;
  }
}
@media only screen and (max-width: 479px) {
  .teaser.aem-GridColumn--default--12 .cmp-teaser.sc-teaserPromotional .teaser-banner img {
    width: 100%;
  }
}

.text-white .cmp-teaser :not(button, .countdown *),
.text-white .cmp-teaser li.list--link::before,
.text-white .cmp-teaser .actions > sdx-icon,
.text-white .cmp-teaser ul.list li::before,
.text-white .cmp-teaser ol.list li::before {
  color: #fff;
}
.text-white .cmp-teaser li.list--link a {
  color: #fff;
}
.text-white .cmp-teaser .sc-teaserTestimonial-container .testimonial-desc .icon {
  -webkit-text-fill-color: white;
}

.text-navy .cmp-teaser .description_over :not(button),
.text-navy .cmp-teaser .description_over li.list--link::before,
.text-navy .cmp-teaser .description_over .actions > sdx-icon,
.text-navy .cmp-teaser .description_over ul.list li::before,
.text-navy .cmp-teaser .description_over ol.list li::before,
.text-navy .cmp-teaser .sc-teaserTestimonial-container :not(button),
.text-navy .cmp-teaser .sc-teaserTestimonial-container li.list--link::before,
.text-navy .cmp-teaser .sc-teaserTestimonial-container .actions > sdx-icon,
.text-navy .cmp-teaser .sc-teaserTestimonial-container ul.list li::before,
.text-navy .cmp-teaser .sc-teaserTestimonial-container ol.list li::before,
.text-navy .cmp-teaser .teaser-standard-container :not(button),
.text-navy .cmp-teaser .teaser-standard-container li.list--link::before,
.text-navy .cmp-teaser .teaser-standard-container .actions > sdx-icon,
.text-navy .cmp-teaser .teaser-standard-container ul.list li::before,
.text-navy .cmp-teaser .teaser-standard-container ol.list li::before {
  color: #015;
}

.pretitle-navy .cmp-teaser .description_over .subtitle,
.pretitle-navy .cmp-teaser .sc-teaserTestimonial-container .subtitle,
.pretitle-navy .cmp-teaser .teaser-standard-container .pretitle {
  color: #015;
}

.icon-white .cmp-teaser.sc-teaserStandard .image-icon-container span,
.icon-white .cmp-teaser.sc-teaserWide .image-icon-container span {
  color: #fff;
}

.gradient-light .cmp-teaser .img-wrapper > .gradient {
  display: block;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0.2) 49%, rgba(255, 255, 255, 0) 100%);
}
@media only screen and (min-width: 769px) {
  .gradient-light .cmp-teaser .img-wrapper > .gradient {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0.55) 50%, rgba(255, 255, 255, 0.3) 84%, rgba(255, 255, 255, 0) 100%);
  }
}

.gradient-dark .cmp-teaser .img-wrapper > .gradient {
  display: block;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.2) 49%, rgba(0, 0, 0, 0) 100%);
}
@media only screen and (min-width: 769px) {
  .gradient-dark .cmp-teaser .img-wrapper > .gradient {
    background: linear-gradient(90deg, rgba(32, 30, 55, 0.4) 0%, rgba(32, 30, 55, 0.25) 50%, rgba(32, 30, 55, 0.1) 84%, rgba(32, 30, 55, 0) 100%);
  }
}

.teaser-rounded-corners {
  border-radius: 160px;
}

.teaser-badge {
  position: absolute;
  padding: 2px 25px;
  display: inline-block;
  font-size: 16px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  top: 0;
  letter-spacing: 0.06px;
}
.teaser-badge.teaser-badge-left {
  left: 0;
  transform: none;
}
.teaser-badge.teaser-badge-right {
  left: auto;
  right: 0;
  transform: none;
}
.teaser-badge.teaser-badge-turquoise {
  color: #fff;
  background-color: #0eaba9;
}
.teaser-badge.teaser-badge-navy {
  color: #fff;
  background-color: #015;
}
.teaser-badge.teaser-badge-ribbon {
  font-weight: 500;
  letter-spacing: 0;
  font-size: 16px;
  line-height: 24px;
  top: -13.2px;
  z-index: 1;
  padding: 0 24px;
  border-radius: 12px;
  color: #fff;
}

.sc-teaserTitleIcon .teaserIcon-container {
  display: flex;
  flex-direction: row;
  padding: 47px 28px 32px 28px;
  height: 100%;
}
.aem-GridColumn--default--4 .sc-teaserTitleIcon .teaserIcon-container {
  padding-right: 28px;
}
@media only screen and (min-width: 480px) {
  .sc-teaserTitleIcon .teaserIcon-container {
    padding-right: 60px;
  }
}
@media only screen and (min-width: 768px) {
  .sc-teaserTitleIcon .teaserIcon-container {
    padding-right: 90px;
  }
}
@media screen and (max-width: 767px) {
  .sc-teaserTitleIcon .teaserIcon-container {
    flex-direction: column;
    align-items: center;
  }
  .sc-teaserTitleIcon .teaserIcon-container sdx-icon.teaser-sdx-icon {
    margin: 0 0 24px 0;
  }
}
.sc-teaserTitleIcon .teaserIcon-container sdx-icon {
  margin-right: 28px;
}
.sc-teaserTitleIcon .teaserIcon-container sdx-icon.padding-top-34 {
  padding-top: 34px !important;
}
.sc-teaserTitleIcon .teaserIcon-container.teaser-icon-pretitle {
  padding-top: 22px;
}
.sc-teaserTitleIcon .teaserIcon-container .teaser-icon-content {
  display: flex;
  flex-direction: column;
}
.sc-teaserTitleIcon .teaserIcon-container .teaser-icon-content h4 {
  font-size: 32px;
  line-height: 38px;
  letter-spacing: -0.8px;
  margin-bottom: 12px;
}
.sc-teaserTitleIcon .teaserIcon-container .teaser-icon-content .subtitle {
  font-size: 18px;
  line-height: 32px;
  letter-spacing: -0.26px;
  margin-bottom: 8px !important;
}
.sc-teaserTitleIcon .teaserIcon-container .teaser-icon-content div.font {
  margin-bottom: 32px;
}
.sc-teaserTitleIcon .teaserIcon-container .content-bottom-section {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.sc-teaserWide .sc-teaser-wide-container {
  display: flex;
  padding: 32px 28px 40px 28px;
}
@media screen and (max-width: 479px) {
  .sc-teaserWide .sc-teaser-wide-container {
    flex-direction: column;
  }
}
.sc-teaserWide .sc-teaser-wide-container .cmp-teaser__image {
  margin-right: 32px;
}
@media screen and (max-width: 479px) {
  .sc-teaserWide .sc-teaser-wide-container .cmp-teaser__image {
    text-align: center;
    margin-right: 0;
  }
}
.sc-teaserWide .sc-teaser-wide-container img {
  margin-top: auto;
  margin-bottom: auto;
}
.sc-teaserWide .sc-teaser-wide-container img.cmp-image__image {
  width: 48px;
}
.sc-teaserWide .sc-teaser-wide-container .image-icon-container {
  text-align: center;
  font-size: 48px;
  margin-right: 28px;
}
@media screen and (max-width: 479px) {
  .sc-teaserWide .sc-teaser-wide-container .image-icon-container {
    margin-bottom: 8px;
    margin-right: 0;
  }
}
.sc-teaserWide .sc-teaser-wide-container .teaser-wide-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}
@media only screen and (min-width: 1024px) {
  .sc-teaserWide .sc-teaser-wide-container .teaser-wide-content {
    flex-direction: row;
    justify-content: space-between;
  }
}
.sc-teaserWide .sc-teaser-wide-container .teaser-wide-content h4 {
  letter-spacing: -0.13px;
}
.sc-teaserWide .sc-teaser-wide-container .teaser-wide-content .subtitle {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.09px;
}
.sc-teaserWide .sc-teaser-wide-container .teaser-wide-content .sc-teaser-wide-button {
  margin-top: 32px;
}
@media screen and (min-width: 1024px) {
  .sc-teaserWide .sc-teaser-wide-container .teaser-wide-content .sc-teaser-wide-button {
    margin-top: 0;
  }
}
.sc-teaserWide .sc-teaser-wide-container .teaser-wide-content .sc-teaser-wide-button a {
  border-bottom: none;
}
.sc-teaserWide .sc-teaser-wide-container .teaser-wide-content .teaser-wide-text {
  padding-top: 8px;
}
@media only screen and (min-width: 1024px) {
  .sc-teaserWide .sc-teaser-wide-container .teaser-wide-content .teaser-wide-text {
    padding-top: 0;
  }
}

.sc-teaserTestimonial .img-wrapper {
  background-position: center 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
@media only screen and (max-width: 479px) {
  .sc-teaserTestimonial .img-wrapper {
    min-height: 344px !important;
  }
}
.sc-teaserTestimonial .testimonial-bg-img {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.sc-teaserTestimonial .sc-teaserTestimonial-container {
  display: flex;
  justify-content: flex-end;
  width: 80%;
  margin: 0 auto;
}
@media screen and (min-width: 480px) {
  .sc-teaserTestimonial .sc-teaserTestimonial-container div.subtitle {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
  .sc-teaserTestimonial .sc-teaserTestimonial-container p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
  .sc-teaserTestimonial .sc-teaserTestimonial-container p.sc-teaserTestimonial-quote {
    -webkit-line-clamp: 4;
    margin-bottom: 16px;
  }
}
@media screen and (max-width: 767px) {
  .sc-teaserTestimonial .sc-teaserTestimonial-container .testimonial-desc.testimonial-desc {
    margin: 24px 0 0 0;
  }
}
@media screen and (max-width: 1023px) {
  .sc-teaserTestimonial .sc-teaserTestimonial-container {
    flex-direction: column;
    width: 100%;
  }
  .sc-teaserTestimonial .sc-teaserTestimonial-container .testimonial-desc {
    margin: 16px 48px 0 48px;
  }
  .sc-teaserTestimonial .sc-teaserTestimonial-container .testimonial-desc .sc-teaser-newline {
    font-size: 28px;
    line-height: 32px;
    letter-spacing: -0.75px;
    font-weight: 600;
  }
  .sc-teaserTestimonial .sc-teaserTestimonial-container .testimonial-desc .icon {
    float: left;
    font-size: 28px;
    margin: 4px 10px 0 0;
  }
  .sc-teaserTestimonial .sc-teaserTestimonial-container .testimonial-desc > span > p {
    margin-bottom: 32px;
  }
  .sc-teaserTestimonial .sc-teaserTestimonial-container .cmp-teaser_image-container.cmp-teaser_image-container {
    margin-top: 32px;
  }
}
@media screen and (min-width: 1024px) {
  .sc-teaserTestimonial .sc-teaserTestimonial-container {
    height: 409px;
  }
  .sc-teaserTestimonial .sc-teaserTestimonial-container .testimonial-desc {
    margin: 10px 0 0 32px;
  }
  .sc-teaserTestimonial .sc-teaserTestimonial-container .testimonial-desc .icon {
    float: left;
    font-size: 40px;
    margin: 6px 10px 0 0;
  }
}
.sc-teaserTestimonial .sc-teaserTestimonial-container .cmp-teaser_image-container {
  align-self: center;
  flex: 0 0 auto;
  width: 206px;
  height: 206px;
  overflow: hidden;
  display: inline-block;
  position: relative;
  margin-top: -16px;
}
@media screen and (max-width: 1023px) {
  .sc-teaserTestimonial .sc-teaserTestimonial-container .cmp-teaser_image-container {
    width: 206px;
    height: 206px;
  }
}
.sc-teaserTestimonial .sc-teaserTestimonial-container .cmp-teaser_image-container .cmp-teaser_image {
  height: 100%;
}
.sc-teaserTestimonial .sc-teaserTestimonial-container .cmp-teaser_image-container .cmp-teaser_image img {
  margin: 0 auto;
  display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.sc-teaserNavigation a {
  overflow: hidden;
  transition: background-color 0.5s ease;
  display: flex;
  height: 100%;
}
.sc-teaserNavigation a:hover {
  background-color: #eef3f6;
}
.sc-teaserNavigation a ul.list.actions {
  margin-bottom: 0;
  position: absolute;
  bottom: 24px;
  font-weight: 400;
  margin-left: 16px;
}
.sc-teaserNavigation a ul.list.actions:not(.navigation-no-img) li {
  margin-left: 0;
}
.sc-teaserNavigation a ul.list.actions.navigation-no-img {
  left: 0;
  margin-left: 22px;
}
.sc-teaserNavigation a ul.list.actions.navigation-no-img li[class*=icon-]::before {
  margin-left: -15px;
}
@media screen and (min-width: 1024px) {
  .sc-teaserNavigation a ul.list.actions {
    margin-left: 0;
  }
}
.sc-teaserNavigation a ul.list.actions li {
  font-weight: 400;
  margin-bottom: 0;
}
.sc-teaserNavigation a ul.list.actions li[class*=icon-]::before {
  margin-left: -15px;
}
@media screen and (min-width: 1024px) {
  .sc-teaserNavigation a ul.list.actions li[class*=icon-]::before {
    margin-left: 0;
  }
}
.sc-teaserNavigation a .no-description {
  display: flex;
}
.sc-teaserNavigation a .no-description .cmp-teaser__image {
  margin: auto;
}
.sc-teaserNavigation a .margin-bottom-dynamic {
  margin-bottom: 24px;
}
.sc-teaser-space-between-desc-link .sc-teaserNavigation a .margin-bottom-dynamic {
  margin-bottom: 42px;
}

.sc-teaser-img-left .sc-teaserDefault .cmp-teaser__image {
  margin: 0 32px 0 0;
}
@media screen and (max-width: 1023px) {
  .sc-teaser-img-left .sc-teaserDefault .cmp-teaser__image {
    float: none;
    margin: 0;
    width: 100%;
  }
}
.sc-teaser-img-left .sc-teaserDefault ul.list {
  margin-left: 0;
}
.sc-teaser-img-left .sc-teaserDefault ul.list li[class*=icon-] {
  margin-left: 0;
}
.sc-teaser-img-left .sc-teaserDefault ul.list li[class*=icon-]::before {
  margin-left: 0;
}

.sc-teaserPromotional {
  padding: 27px 16px 32px 16px;
}
.teaser.aem-GridColumn--default--12 .sc-teaserPromotional .teaser-banner .centralized_over .sticker_holder {
  margin-right: -16%;
}
.sc-teaserPromotional h4 {
  letter-spacing: -0.75px;
}
.sc-teaserPromotional .button-full-width {
  width: 100%;
}
.sc-teaserPromotional .sdx-sticker-circle-container {
  position: relative;
}
.sc-teaserPromotional .sdx-sticker-circle-container .sticker_holder.sdx-sticker {
  width: 469px;
  height: 200px;
}
@media screen and (min-width: 1024px) {
  .sc-teaserPromotional .sc-teaserPromotional-content.sc-teaserPromotional-with-sticker {
    padding-top: 150px;
  }
}
.sc-teaserPromotional .sc-teaserPromotional-content .sc-teaserPromotional-footer {
  display: flex;
  justify-content: space-between;
}
.sc-teaserPromotional .sc-teaserPromotional-content .sc-teaserPromotional-footer .sc-teaserPromotional-price {
  display: flex;
  flex-direction: column;
}
.sc-teaserPromotional .sc-teaserPromotional-content .sc-teaserPromotional-footer .button {
  max-height: 48px;
  align-self: flex-end;
}
.sc-teaserPromotional .sc-teaserPromotional-content .sc-teaserPromotional-footer a {
  border-bottom: none;
}

.sc-teaserNews {
  padding: 36px 16px 32px 16px;
}
.sc-teaserNews h4 {
  letter-spacing: -0.75px;
  color: #015;
}
.sc-teaserNews p:empty {
  display: none;
}
.sc-teaserNews ul.list li a {
  letter-spacing: -0.1px;
  color: #1781e3;
}
.sc-teaserNews .cmp-teaser__image {
  margin-bottom: 0;
}

.cmp-card-border {
  border-top: 4px solid #015;
}

.sc-teaserStandard .link-align-bottom,
.sc-teaserDefault .link-align-bottom,
.sc-teaserTitleIcon .link-align-bottom {
  position: absolute;
  bottom: 24px;
}
.sc-teaserStandard ul.list,
.sc-teaserDefault ul.list,
.sc-teaserTitleIcon ul.list {
  margin-bottom: 0;
}
.sc-teaserStandard ul.list.actions li,
.sc-teaserDefault ul.list.actions li,
.sc-teaserTitleIcon ul.list.actions li {
  margin-bottom: 0;
}
.sc-teaser-img-left .sc-teaserStandard ul.list,
.sc-teaser-img-left .sc-teaserDefault ul.list,
.sc-teaser-img-left .sc-teaserTitleIcon ul.list {
  left: 48%;
}
.sc-teaserStandard .margin-bottom-64,
.sc-teaserDefault .margin-bottom-64,
.sc-teaserTitleIcon .margin-bottom-64 {
  margin-bottom: 64px;
}
.sc-teaserStandard .margin-bottom-46,
.sc-teaserDefault .margin-bottom-46,
.sc-teaserTitleIcon .margin-bottom-46 {
  margin-bottom: 46px;
}
.sc-teaserStandard .cmp-image__image,
.sc-teaserDefault .cmp-image__image,
.sc-teaserTitleIcon .cmp-image__image {
  display: flex;
}

.sc-teaserStandard {
  display: flex;
  flex-direction: column;
}
.sc-teaserStandard.teaser-clickable {
  display: block;
}
.sc-teaserStandard.teaser-hover {
  transition: border-color 200ms ease-in-out 0s, box-shadow 200ms ease-in-out 0s;
}
.sc-teaserStandard.teaser-hover:hover {
  border-color: var(--sdx-color-gray-tint-4);
  box-shadow: color-mix(in srgb, var(--sdx-color-gray) 16%, transparent) 0px 4px 16px 0px;
}
.sc-teaser-img-right .sc-teaserStandard:not(.teaser-clickable), .sc-teaser-img-left .sc-teaserStandard:not(.teaser-clickable),
.sc-teaser-img-right .sc-teaserStandard .standard-combined-clickable,
.sc-teaser-img-left .sc-teaserStandard .standard-combined-clickable {
  display: flex;
  justify-content: space-between;
}
.sc-teaser-img-right .sc-teaserStandard:not(.teaser-clickable) .centralized_over, .sc-teaser-img-left .sc-teaserStandard:not(.teaser-clickable) .centralized_over,
.sc-teaser-img-right .sc-teaserStandard .standard-combined-clickable .centralized_over,
.sc-teaser-img-left .sc-teaserStandard .standard-combined-clickable .centralized_over {
  width: auto;
  overflow: visible;
}
.sc-teaser-img-right .sc-teaserStandard:not(.teaser-clickable) .centralized_over .sticker_holder, .sc-teaser-img-left .sc-teaserStandard:not(.teaser-clickable) .centralized_over .sticker_holder,
.sc-teaser-img-right .sc-teaserStandard .standard-combined-clickable .centralized_over .sticker_holder,
.sc-teaser-img-left .sc-teaserStandard .standard-combined-clickable .centralized_over .sticker_holder {
  align-self: center;
  width: 100%;
}
.sc-teaser-img-right .sc-teaserStandard:not(.teaser-clickable) .teaser-standard-container, .sc-teaser-img-left .sc-teaserStandard:not(.teaser-clickable) .teaser-standard-container,
.sc-teaser-img-right .sc-teaserStandard .standard-combined-clickable .teaser-standard-container,
.sc-teaser-img-left .sc-teaserStandard .standard-combined-clickable .teaser-standard-container {
  width: 100%;
}
.sc-teaser-img-right .sc-teaserStandard:not(.teaser-clickable),
.sc-teaser-img-right .sc-teaserStandard .standard-combined-clickable {
  flex-direction: row-reverse;
}
.sc-teaser-img-right .sc-teaserStandard:not(.teaser-clickable) .centralized_over[class*=padding-],
.sc-teaser-img-right .sc-teaserStandard .standard-combined-clickable .centralized_over[class*=padding-] {
  padding-left: 0 !important;
}
.sc-teaser-img-left .sc-teaserStandard:not(.teaser-clickable),
.sc-teaser-img-left .sc-teaserStandard .standard-combined-clickable {
  flex-direction: row;
}
.sc-teaser-img-left .sc-teaserStandard:not(.teaser-clickable) .centralized_over[class*=padding-],
.sc-teaser-img-left .sc-teaserStandard .standard-combined-clickable .centralized_over[class*=padding-] {
  padding-right: 0 !important;
}
.sc-teaser-img-bottom .sc-teaserStandard:not(.teaser-clickable),
.sc-teaser-img-bottom .sc-teaserStandard .standard-combined-clickable {
  display: flex;
  flex-direction: column-reverse;
}
.sc-teaser-img-bottom .sc-teaserStandard:not(.teaser-clickable) .centralized_over,
.sc-teaser-img-bottom .sc-teaserStandard .standard-combined-clickable .centralized_over {
  width: auto;
  overflow: visible;
}
.sc-teaser-img-bottom .sc-teaserStandard:not(.teaser-clickable) .centralized_over[class*=padding-],
.sc-teaser-img-bottom .sc-teaserStandard .standard-combined-clickable .centralized_over[class*=padding-] {
  padding-top: 0 !important;
}
.sc-teaser-img-bottom .sc-teaserStandard:not(.teaser-clickable) .centralized_over .sticker_holder,
.sc-teaser-img-bottom .sc-teaserStandard .standard-combined-clickable .centralized_over .sticker_holder {
  align-self: center;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .sc-teaser-img-right .sc-teaserStandard:not(.teaser-clickable).teaser-style-img-adjust-position, .sc-teaser-img-left .sc-teaserStandard:not(.teaser-clickable).teaser-style-img-adjust-position, .sc-teaser-img-left .sc-teaserStandard.teaser-style-img-adjust-position .standard-combined-clickable, .sc-teaser-img-right .sc-teaserStandard.teaser-style-img-adjust-position .standard-combined-clickable {
    flex-direction: column;
  }
  .sc-teaser-img-right .sc-teaserStandard:not(.teaser-clickable).teaser-style-img-adjust-position .centralized_over, .sc-teaser-img-left .sc-teaserStandard:not(.teaser-clickable).teaser-style-img-adjust-position .centralized_over, .sc-teaser-img-left .sc-teaserStandard.teaser-style-img-adjust-position .standard-combined-clickable .centralized_over, .sc-teaser-img-right .sc-teaserStandard.teaser-style-img-adjust-position .standard-combined-clickable .centralized_over {
    width: 100% !important;
    height: auto !important;
  }
  .sc-teaser-img-right .sc-teaserStandard:not(.teaser-clickable).teaser-style-img-adjust-position .zero-padding.teaser-standard-container[class*=padding-h-], .sc-teaser-img-left .sc-teaserStandard:not(.teaser-clickable).teaser-style-img-adjust-position .zero-padding.teaser-standard-container[class*=padding-h-], .sc-teaser-img-left .sc-teaserStandard.teaser-style-img-adjust-position .standard-combined-clickable .zero-padding.teaser-standard-container[class*=padding-h-], .sc-teaser-img-right .sc-teaserStandard.teaser-style-img-adjust-position .standard-combined-clickable .zero-padding.teaser-standard-container[class*=padding-h-] {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
@media screen and (min-width: 768px) {
  .sc-teaser-img-right .sc-teaserStandard:not(.teaser-clickable).teaser-style-img-adjust-position .zero-padding.teaser-standard-container[class*=padding-v-], .sc-teaser-img-left .sc-teaserStandard:not(.teaser-clickable).teaser-style-img-adjust-position .zero-padding.teaser-standard-container[class*=padding-v-], .sc-teaser-img-left .sc-teaserStandard.teaser-style-img-adjust-position .standard-combined-clickable .zero-padding.teaser-standard-container[class*=padding-v-], .sc-teaser-img-right .sc-teaserStandard.teaser-style-img-adjust-position .standard-combined-clickable .zero-padding.teaser-standard-container[class*=padding-v-] {
    padding-top: 0 !important;
  }
  .sc-teaser-img-right .sc-teaserStandard:not(.teaser-clickable).teaser-style-img-adjust-position .sticker_holder, .sc-teaser-img-left .sc-teaserStandard:not(.teaser-clickable).teaser-style-img-adjust-position .sticker_holder, .sc-teaser-img-left .sc-teaserStandard.teaser-style-img-adjust-position .standard-combined-clickable .sticker_holder, .sc-teaser-img-right .sc-teaserStandard.teaser-style-img-adjust-position .standard-combined-clickable .sticker_holder {
    align-self: flex-start;
  }
}
.sc-teaser-img-left .sc-teaserStandard:not(.teaser-clickable).teaser-style-img-adjust-position .zero-padding.teaser-standard-container[class*=padding-h-], .sc-teaser-img-left .sc-teaserStandard.teaser-style-img-adjust-position .standard-combined-clickable .zero-padding.teaser-standard-container[class*=padding-h-] {
  padding-right: 0 !important;
}
.sc-teaser-img-right .sc-teaserStandard:not(.teaser-clickable).teaser-style-img-adjust-position .zero-padding.teaser-standard-container[class*=padding-h-], .sc-teaser-img-right .sc-teaserStandard.teaser-style-img-adjust-position .standard-combined-clickable .zero-padding.teaser-standard-container[class*=padding-h-] {
  padding-left: 0 !important;
}
.sc-teaserStandard .standard-combined-clickable {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.sc-teaserStandard .teaser-standard-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.sc-teaserStandard .teaser-standard-container .content-bottom-section,
.sc-teaserStandard .teaser-standard-container .call-me-back-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: 24px;
}
.sc-teaserStandard .teaser-standard-container .content-bottom-section.add-text-left,
.sc-teaserStandard .teaser-standard-container .call-me-back-container.add-text-left {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.sc-teaserStandard .teaser-standard-container .content-bottom-section .add-text-right,
.sc-teaserStandard .teaser-standard-container .call-me-back-container .add-text-right {
  display: flex;
}
.sc-teaserStandard .teaser-standard-container .content-bottom-section div.list.actions > a,
.sc-teaserStandard .teaser-standard-container .content-bottom-section .teaserStandard-button > a,
.sc-teaserStandard .teaser-standard-container .call-me-back-container div.list.actions > a,
.sc-teaserStandard .teaser-standard-container .call-me-back-container .teaserStandard-button > a {
  text-decoration: none;
}
.sc-teaserStandard .teaser-standard-container .content-bottom-section a .action-text:focus,
.sc-teaserStandard .teaser-standard-container .content-bottom-section a .action-text:hover,
.sc-teaserStandard .teaser-standard-container .call-me-back-container a .action-text:focus,
.sc-teaserStandard .teaser-standard-container .call-me-back-container a .action-text:hover {
  text-decoration: underline;
  text-decoration-thickness: 2px;
}
.aem-GridColumn--default--3 .sc-teaserStandard sdx-sticker-circle .text-h1.text-compact {
  line-height: 42px;
}
.aem-GridColumn--default--3 .sc-teaserStandard sdx-sticker-circle .text-h2.text-compact {
  line-height: 34px;
}
.sc-teaserStandard > a .content-bottom-section .action-text:focus,
.sc-teaserStandard > a .content-bottom-section .action-text:hover {
  text-decoration: underline;
  text-decoration-thickness: 2px;
}
.sc-teaserStandard .additional-desc-right {
  display: inline-block;
}
.sc-teaserStandard .teaserStandard-button:not(:last-of-type) {
  margin-bottom: 16px;
}
.sc-teaserStandard .image-icon-container {
  text-align: center;
  font-size: 120px;
}
@media screen and (min-width: 480px) {
  .sc-teaserStandard .image-icon-container {
    font-size: 140px;
  }
}
.sc-teaserStandard .button-full-width {
  width: 100%;
}
.sc-teaserStandard .centralized_over {
  position: relative;
  overflow: initial;
}
.sc-teaserStandard .centralized_over .sticker_holder:not(.sticker-only) {
  display: flex;
  justify-content: center;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
}
.sc-teaserStandard .centralized_over .sticker-only {
  width: 469px;
  height: 40px;
}
@media screen and (min-width: 1024px) {
  .sc-teaserStandard .centralized_over .sticker-only {
    height: 200px;
  }
}
.sc-teaserStandard .centralized_over img {
  width: 100%;
  object-fit: cover;
}
.sc-teaserStandard .centralized_over .sdx-sticker img {
  height: 469px;
  width: auto;
}
.sc-teaserStandard .subtitle {
  font-size: 18px;
}
.sc-teaserStandard .pretitle {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: -0.6px;
  width: 100%;
}
.sc-teaserStandard .teaser-badge {
  z-index: 1;
}
.sc-teaserStandard .modCallFromNet {
  color: #333;
}
.sc-teaserStandard .gradient-title {
  background: radial-gradient(110% 220% at 0% 140%, #13a3f4 0%, rgba(22, 158, 255, 0.5) 40%, rgba(16, 136, 222, 0) 100%), radial-gradient(290% 800% at 220% -440%, #dd1122 30%, #001155 100%);
  border-radius: 12px 12px 0 0;
  box-shadow: 0px -1px 8px rgba(0, 0, 0, 0.16);
}

@media screen and (min-width: 1024px) {
  .sc-teaserDevice {
    flex-direction: column;
  }
  .sc-teaserDevice .teaser-device-container {
    padding-top: 0 !important;
  }
}
.sc-teaserDevice small {
  display: block;
  line-height: 1.3;
}
.sc-teaserDevice .teaser-badge {
  z-index: 1;
}
.sc-teaserDevice.teaser-hover {
  transition: background-color 0.5s ease;
}
.sc-teaserDevice.teaser-hover:hover {
  background-color: #eef3f6 !important;
  box-shadow: 0px 4px 16px rgba(8, 106, 219, 0.5) !important;
  transition: 0.5s ease;
}
.sc-teaserDevice .device-clickable {
  display: flex;
  flex-direction: row;
}
@media screen and (min-width: 1024px) {
  .sc-teaserDevice .device-clickable {
    flex-direction: column;
    height: 100%;
  }
}
.sc-teaserDevice .teaser-device-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.sc-teaserDevice .teaser-device-container h4 {
  margin-bottom: 16px;
}
.sc-teaserDevice .teaser-device-container .content-bottom-section {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.sc-teaserDevice .teaser-device-container .content-bottom-section.add-text-left {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.sc-teaserDevice .teaser-device-container .content-bottom-section .add-text-right {
  display: flex;
}
.sc-teaserDevice .teaser-device-container .content-bottom-section div.list.actions > a,
.sc-teaserDevice .teaser-device-container .content-bottom-section .teaserStandard-button > a {
  text-decoration: none;
}
.sc-teaserDevice .teaser-device-container .content-bottom-section a .action-text:focus,
.sc-teaserDevice .teaser-device-container .content-bottom-section a .action-text:hover {
  text-decoration: underline;
  text-decoration-thickness: 2px;
}
.sc-teaserDevice > a .content-bottom-section .action-text:focus,
.sc-teaserDevice > a .content-bottom-section .action-text:hover {
  text-decoration: underline;
  text-decoration-thickness: 2px;
}
.sc-teaserDevice .teaserDevice-button:not(:last-of-type) {
  margin-bottom: 16px;
}
.sc-teaserDevice .button-full-width {
  width: 100%;
}
.sc-teaserDevice .centralized_over {
  position: relative;
  overflow: initial;
  width: auto;
}
@media screen and (min-width: 1024px) {
  .sc-teaserDevice .centralized_over {
    padding-bottom: 0px !important;
  }
}
.sc-teaserDevice .centralized_over img {
  width: auto;
  height: 104px;
}
@media screen and (min-width: 768px) {
  .sc-teaserDevice .centralized_over img {
    height: 160px;
  }
}
.sc-teaserDevice .pretitle {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: -0.6px;
  width: 100%;
}

.teaser-standard-container :not(.teaserStandard-button:not(button)) {
  transition: color 200ms ease-in-out 0s;
}
.hover-navy-text:hover .teaser-standard-container :not(.teaserStandard-button:not(button)) {
  color: #015;
}
.hover-white-text:hover .teaser-standard-container :not(.teaserStandard-button:not(button)) {
  color: #fff;
}
.hover-grey-text:hover .teaser-standard-container :not(.teaserStandard-button:not(button)) {
  color: #333;
}
.hover-blue-text:hover .teaser-standard-container :not(.teaserStandard-button:not(button)) {
  color: #086adb;
}

.sc-teaserAbout .underlined-link,
.sc-teaserPicture .underlined-link,
.sc-teaserMultiElement .underlined-link,
.sc-teaserStandard .underlined-link {
  text-decoration: underline;
}
.sc-teaserAbout .underlined-link:hover, .sc-teaserAbout .underlined-link:focus,
.sc-teaserPicture .underlined-link:hover,
.sc-teaserPicture .underlined-link:focus,
.sc-teaserMultiElement .underlined-link:hover,
.sc-teaserMultiElement .underlined-link:focus,
.sc-teaserStandard .underlined-link:hover,
.sc-teaserStandard .underlined-link:focus {
  text-decoration-thickness: 2px;
}

.sc-teaser-newline {
  display: block;
}

.sc-teaserResProduct {
  display: flex;
  flex-direction: column;
}
.sc-teaserResProduct .button-full-width {
  width: 100%;
}
.sc-teaserResProduct .content-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.sc-teaserResProduct .content-container.padding-top-40 {
  padding-top: 40px !important;
}
.sc-teaserResProduct .content-container .content-bottom-section {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: 24px;
}
.sc-teaserResProduct .content-container .content-bottom-section ul.list,
.sc-teaserResProduct .content-container .content-bottom-section ul.list li {
  margin-bottom: 0;
}
.sc-teaserResProduct .content-container .table {
  width: calc(100% + 16px);
  margin-left: -8px;
}
.sc-teaserResProduct .content-container .table tbody {
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.1px;
  font-weight: 400;
}
.sc-teaserResProduct .content-container .table td:first-child {
  padding-left: 8px;
}
.sc-teaserResProduct .content-container .table td {
  padding-top: 16px;
  padding-bottom: 16px;
}
.sc-teaserResProduct ul.list {
  font-weight: 400;
}
.sc-teaserResProduct .teaserStandard-button:not(:last-of-type) {
  margin-bottom: 16px;
}

.sc-teaserResProduct li.link-icon {
  margin-left: 0px;
}
.sc-teaserResProduct li.link-icon a {
  vertical-align: top;
}
.sc-teaserResProduct li.link-icon::before {
  display: none !important;
}

.sc-teaserStandard .content-bottom-section .link-icon .action-text,
.sc-teaserDevice .content-bottom-section .link-icon .action-text {
  vertical-align: top;
}
.sc-teaserStandard .content-bottom-section .list.actions:not(:last-of-type),
.sc-teaserDevice .content-bottom-section .list.actions:not(:last-of-type) {
  margin-bottom: 8px;
}

.aem-GridColumn--default--4 .teaser-fixed-height-image .cmp-teaser__image,
.aem-GridColumn--default--4 .teaser-fixed-height-image .teaser-standard-fixed-image {
  overflow: hidden;
  display: flex;
  justify-content: center;
}
.aem-GridColumn--default--4 .teaser-fixed-height-image .cmp-teaser__image img,
.aem-GridColumn--default--4 .teaser-fixed-height-image .teaser-standard-fixed-image img {
  width: auto;
  height: 152px;
}
@media only screen and (min-width: 480px) {
  .aem-GridColumn--default--4 .teaser-fixed-height-image .cmp-teaser__image img,
  .aem-GridColumn--default--4 .teaser-fixed-height-image .teaser-standard-fixed-image img {
    height: 312px;
  }
}
@media only screen and (min-width: 768px) {
  .aem-GridColumn--default--4 .teaser-fixed-height-image .cmp-teaser__image img,
  .aem-GridColumn--default--4 .teaser-fixed-height-image .teaser-standard-fixed-image img {
    height: 224px;
  }
}
.aem-GridColumn--default--3 .teaser-fixed-height-image .cmp-teaser__image,
.aem-GridColumn--default--3 .teaser-fixed-height-image .teaser-standard-fixed-image {
  overflow: hidden;
  display: flex;
  justify-content: center;
}
.aem-GridColumn--default--3 .teaser-fixed-height-image .cmp-teaser__image img,
.aem-GridColumn--default--3 .teaser-fixed-height-image .teaser-standard-fixed-image img {
  width: auto;
  height: 152px;
}
@media only screen and (min-width: 480px) {
  .aem-GridColumn--default--3 .teaser-fixed-height-image .cmp-teaser__image img,
  .aem-GridColumn--default--3 .teaser-fixed-height-image .teaser-standard-fixed-image img {
    height: 312px;
  }
}
@media only screen and (min-width: 768px) {
  .aem-GridColumn--default--3 .teaser-fixed-height-image .cmp-teaser__image img,
  .aem-GridColumn--default--3 .teaser-fixed-height-image .teaser-standard-fixed-image img {
    height: 112px;
  }
}
@media only screen and (min-width: 1024px) {
  .aem-GridColumn--default--3 .teaser-fixed-height-image .cmp-teaser__image img,
  .aem-GridColumn--default--3 .teaser-fixed-height-image .teaser-standard-fixed-image img {
    height: 144px;
  }
}
@media only screen and (min-width: 1280px) {
  .aem-GridColumn--default--3 .teaser-fixed-height-image .cmp-teaser__image img,
  .aem-GridColumn--default--3 .teaser-fixed-height-image .teaser-standard-fixed-image img {
    height: 168px;
  }
}

/*New*/
ol.list.circled-number-list > li {
  position: relative;
  display: list-item;
  padding-bottom: 8px;
  padding-left: 32px;
}
ol.list.circled-number-list > li::before {
  background-color: #086adb;
  position: absolute;
  top: calc(50% - 8px);
  transform: translateY(-50%);
  left: 0;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  content: counter(mycounter);
  color: #fff;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 0;
}
ol.list.circled-number-list.circled-number-list-top > li::before {
  position: absolute;
  top: 0px;
  transform: none;
}

span.circled--icon .icon:before {
  background-color: #a63297;
  color: #fff;
  border-radius: 50%;
  padding: 5px;
}

.cmp-text ol.list:not(.circled-number-list),
.cmp-text ul.list {
  margin-top: 0;
  margin-bottom: 0;
}
.grey-check-list .cmp-text ol.list:not(.circled-number-list) > li::before,
.grey-check-list .cmp-text ul.list > li::before {
  color: #333;
}

.font--bold {
  font-weight: 700;
}

.turquoise-check-list ul.list.single-line .icon-check-mark-2-circle {
  display: block;
  align-items: flex-start;
  margin-left: 32px;
}
.turquoise-check-list ul.list.single-line .icon-check-mark-2-circle::before {
  color: #0eaba9;
  margin-left: -32px;
  font-size: 24px;
  padding-right: 6px;
  top: 0;
}

.line-height-28 {
  line-height: 28px;
}

.table-text-wrap .table td,
.table-text-wrap .table th {
  white-space: normal;
}

.text sdx-tag {
  display: inline-block;
}

.text-hero .cmp-title__text,
.text-d1 .cmp-title__text,
.text-d2 .cmp-title__text,
.text-d3 .cmp-title__text,
.text-h1 .cmp-title__text,
.text-h2 .cmp-title__text,
.text-h3 .cmp-title__text,
.text-h4 .cmp-title__text,
.text-h5 .cmp-title__text,
.text-h6 .cmp-title__text {
  line-height: inherit;
  letter-spacing: inherit;
  font-size: inherit;
  font-weight: inherit;
}

.title-align-left .cmp-title__text {
  text-align: left;
}
.title-align-center .cmp-title__text {
  text-align: center;
}
.title-align-right .cmp-title__text {
  text-align: right;
}

.experiencefragment > .cmp-experiencefragment,
.experiencefragment > .cmp-experiencefragment > .xf-content-height,
.experiencefragment > .cmp-experiencefragment > .xf-content-height > .aem-Grid {
  display: contents;
}

/*New*/
/* These styles are only temporary for demo */
/* In the future we should render sdx html */
.yourselection-wrapper {
  width: 100%;
  border: 1px solid #d6d6d6;
}

.cmp-yourselection-title {
  border-bottom: solid 1px #d6d6d6;
  padding: 16px;
}
.cmp-yourselection-title p {
  font-size: 24px;
  line-height: 40px;
  letter-spacing: -0.75px;
}

.cmp-yourselection-body {
  position: relative;
  padding: 16px;
}

.cmp-yourselection-price {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 40px;
}
@media screen and (min-width: 769px) {
  .cmp-yourselection-price {
    flex-direction: row;
  }
}

.cmp-yourselection-price-text,
.cmp-yourselection-price-discount {
  flex: 1;
}

@media screen and (min-width: 769px) {
  .cmp-yourselection-price-discount {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}

.cmp-yourselection-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: column-reverse;
}
@media screen and (min-width: 769px) {
  .cmp-yourselection-buttons {
    flex-direction: row;
  }
}
.cmp-yourselection-buttons .button-container.button-container-align-right {
  width: 100%;
}
@media screen and (max-width: 768px) {
  .cmp-yourselection-buttons .button-container {
    margin-bottom: 18px;
  }
}
.cmp-yourselection-buttons .button-container .component-container.aem-GridColumn {
  padding: 0 !important;
}
.cmp-yourselection-buttons .button-container .component-button.aem-GridColumn {
  padding: 0 !important;
}
.cmp-yourselection-buttons .link-container {
  display: flex;
  align-items: center;
}

/*New*/
.modCascadingTabs.mod .inner-filter {
  display: none;
}
.modCascadingTabs.mod .inner-filter.active {
  display: block;
}
.modCascadingTabs.mod .cmp-tabs__tabpanel {
  display: none;
}
.modCascadingTabs.mod .cmp-tabs__tabpanel.active {
  display: flex;
}
.modCascadingTabs.mod .cmp-tabs__tabpanel > .component-container {
  width: calc(100% + 24px);
  margin: 0 -4px;
}
@media only screen and (min-width: 1024px) {
  .modCascadingTabs.mod .cmp-tabs__tabpanel > .component-container {
    margin: 0 -12px;
  }
}
.modCascadingTabs.mod .middle-title-text,
.modCascadingTabs.mod .bottom-title-text {
  color: #666;
  margin-top: 70px;
}
.modCascadingTabs.mod .middle-title-text.selected,
.modCascadingTabs.mod .bottom-title-text.selected {
  color: #015;
}
.modCascadingTabs.mod .step-back {
  width: 100%;
}
.modCascadingTabs .cmp-tabs__tab {
  border: none;
  padding-bottom: 12px;
  padding-top: 12px;
}
@media only screen and (min-width: 480px) {
  .modCascadingTabs .cmp-tabs__tab {
    padding: 4px;
  }
}
@media only screen and (min-width: 768px) {
  .modCascadingTabs .cmp-tabs__tab {
    padding: 12px;
  }
}
@media only screen and (min-width: 1024px) {
  .modCascadingTabs .cmp-tabs__tab {
    padding-bottom: 12px;
    padding-top: 12px;
  }
}
.modCascadingTabs .cmp-tabs__tab.active .tab-link, .modCascadingTabs .cmp-tabs__tab.cmp-tabs__tab--active .tab-link {
  border: solid 1px #0048cf;
  color: #0048cf;
}
.modCascadingTabs .cmp-tabs__tab .tab-link {
  display: flex;
  align-items: center;
  height: 72px;
  border-radius: 5px;
  border: 1px solid #d6d6d6;
  color: #015;
  width: 100%;
  hyphens: auto;
}
.modCascadingTabs .cmp-tabs__tab .tab-link.active {
  border: solid 1px #0048cf;
  color: #0048cf;
  margin-top: 0;
  margin-bottom: 0;
}
.modCascadingTabs .cmp-tabs__tab .tab-link:hover, .modCascadingTabs .cmp-tabs__tab .tab-link:focus, .modCascadingTabs .cmp-tabs__tab .tab-link.active:hover {
  border: solid 1px #086adb;
  color: #0048cf;
  margin-top: 0;
  margin-bottom: 0;
}
.modCascadingTabs .deselect-button {
  cursor: pointer;
  text-decoration: none;
}
@media (max-width: 479px) {
  .modCascadingTabs ol.hasSelection li:not(.active),
  .modCascadingTabs ol.hasSelection li button:not(.active) {
    display: none;
  }
  .modCascadingTabs .step-back {
    display: none;
  }
  .modCascadingTabs ol.hasSelection + .step-back {
    display: block;
  }
}
.hide-non-selected-content .modCascadingTabs.mod .middle-title-text,
.hide-non-selected-content .modCascadingTabs.mod .bottom-title-text,
.hide-non-selected-content .modCascadingTabs.mod .description-middle,
.hide-non-selected-content .modCascadingTabs.mod .description-bottom {
  display: none;
}
.hide-non-selected-content .modCascadingTabs.mod .middle-title-text.selected,
.hide-non-selected-content .modCascadingTabs.mod .bottom-title-text.selected,
.hide-non-selected-content .modCascadingTabs.mod .description-middle.selected,
.hide-non-selected-content .modCascadingTabs.mod .description-bottom.selected {
  display: block;
}

/*New*/
@media screen and (min-width: 768px) {
  .modCountdown.default-view {
    display: inline-flex;
  }
}
.modCountdown.default-view .banner {
  z-index: 0;
  display: flex;
}
.modCountdown.default-view .banner figure {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 6px;
  flex-grow: 1;
  padding: 4px 8px;
  background-color: #fff;
  color: #a63297;
  border-radius: 6px;
  font-size: 20px;
  line-height: 20px;
  font-weight: 520;
  min-width: 0;
  flex-flow: wrap;
}
.modCountdown.default-view .banner figure:not(:last-child) {
  margin-right: 8px;
}
@media screen and (min-width: 768px) {
  .modCountdown.default-view .banner figure {
    flex-direction: column;
    height: auto;
    gap: 0;
    line-height: 32px;
    font-size: 24px;
  }
}
.modCountdown.default-view .banner figure figcaption {
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
}
.theme-orchid-squares .modCountdown.default-view .banner figure {
  background-color: rgba(166, 50, 151, 0.2);
  color: #a63297;
}
.theme-hero .modCountdown.default-view {
  display: flex;
  gap: 12px;
  background: white;
  border-radius: 50px;
  border: 1px solid var(--sdx-color-pink);
  padding: 4px 8px;
}
@media screen and (min-width: 480px) {
  .theme-hero .modCountdown.default-view {
    display: inline-flex;
  }
}
@media screen and (min-width: 768px) {
  .theme-hero .modCountdown.default-view {
    flex-flow: inherit;
    padding: 0 20px;
  }
}
.theme-hero .modCountdown.default-view .banner {
  display: contents;
}
.theme-hero .modCountdown.default-view .banner > figure {
  background: transparent;
  color: var(--sdx-color-pink);
  flex-flow: inherit;
  align-items: baseline;
  gap: 8px;
  margin: 0;
}
.theme-hero .modCountdown.default-view .banner > figure > div {
  font-size: 24px;
  font-weight: 600;
}
@media screen and (min-width: 768px) {
  .theme-hero .modCountdown.default-view .banner > figure > div {
    font-size: 28px;
  }
}

/*New*/
.modHeroCarousel {
  color: #086adb;
}
.modHeroCarousel .carousel__slider {
  display: flex;
}
.modHeroCarousel .carousel__pagination {
  width: 100%;
  flex-direction: row;
  justify-content: space-evenly;
  align-content: flex-start;
  align-items: stretch;
  height: 6px;
  pointer-events: none;
}
@media screen and (min-width: 768px) {
  .modHeroCarousel .carousel__pagination {
    width: 30%;
  }
}
.modHeroCarousel .carousel__pagination li:first-child {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.modHeroCarousel .carousel__pagination li:last-child {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.modHeroCarousel .cmp-carousel__indicator {
  opacity: 1;
  height: 4px;
  border-radius: 0;
  margin: 0;
  flex-grow: 1;
  width: unset;
  background-color: #d6d6d6;
}
.modHeroCarousel .cmp-carousel__indicator.cmp-carousel__indicator--active {
  background-color: #086adb;
  opacity: 0.6;
  animation-name: hero-carousel-fade-in-bar;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}
.modHeroCarousel .cmp-carousel__actions {
  display: flex;
  position: relative;
  left: 0;
  align-items: center;
}
@media screen and (min-width: 768px) {
  .modHeroCarousel .cmp-carousel__actions {
    bottom: 50px;
    z-index: 1;
  }
}
.modHeroCarousel.mod .cmp-carousel__item {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  overflow: hidden;
}
.modHeroCarousel.mod .cmp-carousel__item .hero-teaser .teaser,
.modHeroCarousel.mod .cmp-carousel__item .hero-teaser .image-holder {
  visibility: hidden;
}
.modHeroCarousel.mod .cmp-carousel__item .hero-teaser .image-holder {
  overflow: hidden;
}
.modHeroCarousel.mod .cmp-carousel__item .hero-teaser .teaser {
  min-height: 350px;
}
@media screen and (min-width: 768px) {
  .modHeroCarousel.mod .cmp-carousel__item .hero-teaser .teaser {
    min-height: 320px;
  }
}
.modHeroCarousel.mod .cmp-carousel__item.next .teaser {
  height: 100%;
}
.modHeroCarousel.mod .cmp-carousel__item.next .hero-teaser {
  height: 100%;
}
.modHeroCarousel.mod .cmp-carousel__item.next .hero-teaser .image-holder {
  visibility: visible;
}
.modHeroCarousel.mod .cmp-carousel__item.next .hero-teaser .image-holder img {
  position: relative;
  right: calc(-100% - 16px);
  transform: scaleY(0.96);
}
.modHeroCarousel.mod .cmp-carousel__item.animate-in .hero-teaser .teaser {
  animation-name: hero-carousel-fade-in;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  opacity: 0;
}
.modHeroCarousel.mod .cmp-carousel__item.animate-out .hero-teaser .teaser {
  animation-name: hero-carousel-fade-out;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  opacity: 1;
  visibility: visible;
}
.modHeroCarousel.mod .cmp-carousel__item.animate-in-left .hero-teaser .image-holder img {
  position: relative;
  animation-name: hero-carousel-slide-in-left;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  visibility: visible;
  right: -100%;
  margin-left: 16px;
}
.modHeroCarousel.mod .cmp-carousel__item.animate-out-left .hero-teaser .image-holder img {
  position: relative;
  visibility: visible;
  left: 0;
  height: 100%;
  animation-name: hero-carousel-slide-out-left;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}
.modHeroCarousel.mod .cmp-carousel__item.next.animate-preview-in .hero-teaser .image-holder img {
  position: relative;
  right: -200%;
  animation-name: hero-carousel-preview-in;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}
.modHeroCarousel.mod .cmp-carousel__item.animate-in-right .hero-teaser .image-holder img {
  position: relative;
  animation-name: hero-carousel-slide-in-right;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  visibility: visible;
  left: -100%;
}
.modHeroCarousel.mod .cmp-carousel__item.next.animate-out-right .hero-teaser .image-holder img {
  position: relative;
  visibility: visible;
  right: 0;
  height: 100%;
  animation-name: hero-carousel-slide-out-right;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  margin-left: 16px;
}
.modHeroCarousel.mod .cmp-carousel__item.animate-preview-out .hero-teaser .image-holder img {
  position: relative;
  right: calc(-100% - 16px);
  animation-name: hero-carousel-preview-out;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  visibility: visible;
  margin-left: 16px;
  transform: scaleY(0.96);
}
.modHeroCarousel.mod .cmp-carousel__item.cmp-carousel__item--active {
  position: relative;
  z-index: 1;
}
.modHeroCarousel.mod .cmp-carousel__item.cmp-carousel__item--active .hero-teaser .teaser,
.modHeroCarousel.mod .cmp-carousel__item.cmp-carousel__item--active .hero-teaser .image-holder {
  visibility: visible;
}

@keyframes hero-carousel-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes hero-carousel-fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
@keyframes hero-carousel-fade-in-bar {
  100% {
    opacity: 1;
  }
}
@keyframes hero-carousel-slide-out-left {
  0% {
    transform: scaleY(1);
  }
  100% {
    left: calc(-100% - 16px);
    transform: scaleY(0.96);
    visibility: hidden;
  }
}
@keyframes hero-carousel-slide-in-left {
  0% {
    transform: scaleY(0.96);
  }
  100% {
    right: 0;
    height: 100%;
    margin-left: 0;
    transform: scaleY(1);
  }
}
@keyframes hero-carousel-preview-in {
  100% {
    right: calc(-100% - 16px);
  }
}
@keyframes hero-carousel-preview-out {
  100% {
    right: -200%;
    visibility: hidden;
  }
}
@keyframes hero-carousel-slide-out-right {
  0% {
    transform: scaleY(1);
  }
  100% {
    right: -100%;
    transform: scaleY(0.96);
  }
}
@keyframes hero-carousel-slide-in-right {
  0% {
    transform: scaleY(0.96);
  }
  100% {
    left: 0;
    height: 100%;
    transform: scaleY(1);
  }
}
.scs-portal-range-slider {
  -webkit-appearance: none;
  border-radius: 5px;
  overflow: hidden;
  width: 40%;
  height: 4px;
  margin: 0 30%;
  padding: var(--sdx-baseline-3) 0 var(--sdx-baseline) 0;
  cursor: pointer;
  display: block;
}
.scs-portal-range-slider:focus {
  outline: 0;
}
.scs-portal-range-slider::-moz-focus-outer {
  border: 0;
}
.scs-portal-range-slider[max="0"], .scs-portal-range-slider[max^="-"] {
  visibility: hidden;
}
.scs-portal-range-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  background: var(--sdx-color-int-blue);
  border: none;
  border-radius: 5px;
  width: var(--scs-portal-range-slider-thumb-width, 48px);
  height: 4px;
  cursor: pointer;
}
.scs-portal-range-slider::-webkit-slider-thumb:active {
  outline: 0;
}
.scs-portal-range-slider::-webkit-slider-thumb:hover {
  background: var(--sdx-color-int-blue--active);
}
.scs-portal-range-slider.first-position::-webkit-slider-thumb {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.scs-portal-range-slider.last-position::-webkit-slider-thumb {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.scs-portal-range-slider::-moz-range-thumb {
  background: var(--sdx-color-int-blue);
  border: none;
  border-radius: 5px;
  width: var(--scs-portal-range-slider-thumb-width, 48px);
  height: 4px;
  cursor: pointer;
}
.scs-portal-range-slider::-moz-range-thumb:active {
  outline: 0;
}
.scs-portal-range-slider::-moz-range-thumb:hover {
  background: var(--sdx-color-int-blue--active);
}
.scs-portal-range-slider.first-position::-moz-range-thumb {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.scs-portal-range-slider.last-position::-moz-range-thumb {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.scs-portal-range-slider::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  background: var(--sdx-color-gray-tint-8);
  border: none;
  border-radius: 5px;
  width: 100%;
  height: 4px;
}
.scs-portal-range-slider::-moz-range-track {
  background: var(--sdx-color-gray-tint-8);
  border: none;
  border-radius: 5px;
  width: 100%;
  height: 4px;
}

.arrow {
  position: absolute;
  top: calc(0.5 * (100% - 40px - var(--sdx-baseline-3)));
  z-index: 5;
  padding: 10px;
}
.arrow.next {
  right: -20px;
  padding-right: 0;
}
.arrow.prev {
  left: -20px;
  padding-left: 0;
}
.arrow .arrow-icon {
  cursor: pointer;
  border: 1px solid var(--sdx-color-gray-tint-7);
  border-radius: 50%;
  background-color: var(--sdx-color-sc-white);
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  opacity: 1;
  transform: scale(1);
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
  outline: none;
}
.arrow .arrow-icon sdx-icon {
  position: absolute;
  top: 7px;
  align-items: center;
  transition: color 200ms cubic-bezier(0.4, 0, 0.2, 1);
  color: var(--sdx-color-int-blue);
}
.arrow .arrow-icon:hover {
  border-color: var(--sdx-color-gray-tint-4);
}
.arrow .arrow-icon:hover sdx-icon {
  color: var(--sdx-color-int-blue--active);
}

@keyframes xs-first-to-previous {
  from {
    left: 0;
  }
  to {
    left: calc(-82% - 8px);
  }
}
@keyframes xs-next-to-current {
  from {
    left: calc(94% - 8px);
  }
  to {
    left: 6%;
  }
}
@keyframes xs-to-small-left-preview {
  from {
    left: calc(182% + 8px);
  }
  to {
    left: calc(94% + 4px);
  }
}
@keyframes xs-last-to-current {
  from {
    left: calc(94% + 4px);
  }
  to {
    left: calc(12% - 10px);
  }
}
@keyframes xs-current-large-previous {
  from {
    left: 6%;
  }
  to {
    left: calc(-82% - 8px);
  }
}
@keyframes xs-previous-out {
  from {
    display: inline-block;
    left: calc(-82% - 8px);
  }
  to {
    display: none;
    left: calc(-170% - 8px);
  }
}
@keyframes xs-next-in {
  from {
    left: calc(188% + 4px);
  }
  to {
    left: calc(94% + 4px);
  }
}
@keyframes xs-current-to-previous {
  from {
    left: 6%;
  }
  to {
    left: calc(-76% - 12px);
  }
}
@keyframes xs-previous-to-first {
  from {
    left: calc(-82% - 8px);
  }
  to {
    left: 0;
  }
}
@keyframes xs-current-to-next-large {
  from {
    left: calc(6% - 4px);
  }
  to {
    left: calc(88% + 4px);
  }
}
@keyframes xs-next-out {
  from {
    display: inline-block;
    top: 0;
    left: calc(94% + 8px);
  }
  to {
    left: calc(152% + 8px);
    display: none;
  }
}
@keyframes xs-previous-large-to-current {
  from {
    left: calc(-76% - 12px);
  }
  to {
    left: 6%;
  }
}
@keyframes xs-last-to-next {
  from {
    left: calc(12% - 4px);
  }
  to {
    left: calc(94% + 4px);
  }
}
@keyframes xs-previous-in {
  from {
    display: inline-block;
    left: calc(-170% - 8px);
  }
  to {
    display: inline-block;
    left: calc(-82% - 8px);
  }
}
@keyframes xs-previous-to-current {
  from {
    left: calc(-82% - 8px);
  }
  to {
    left: 6%;
  }
}
@keyframes xs-current-to-next {
  from {
    left: 6%;
  }
  to {
    left: calc(94% + 4px);
  }
}
@keyframes md-first-to-previous {
  from {
    left: 0;
    width: 88%;
  }
  to {
    width: 30%;
    left: calc(-24% - 20px);
  }
}
@keyframes md-next-large-to-current {
  from {
    left: calc(88% + 20px);
    width: 30%;
  }
  to {
    left: 6%;
    width: 88%;
  }
}
@keyframes md-to-small-left-preview {
  from {
    left: calc(112% + 20px);
  }
  to {
    left: calc(94% + 10px);
  }
}
@keyframes md-last-to-current {
  from {
    left: calc(94% + 10px);
    width: 30%;
  }
  to {
    width: 88%;
    left: calc(12% - 10px);
  }
}
@keyframes md-current-large-previous {
  from {
    left: 6%;
    width: 88%;
  }
  to {
    width: 30%;
    left: calc(-18% - 30px);
  }
}
@keyframes md-previous-out {
  from {
    display: inline-block;
    left: calc(-24% - 20px);
  }
  to {
    left: calc(-54% - 30px);
    display: inline-block;
  }
}
@keyframes md-next-to-current {
  from {
    left: calc(94% + 10px);
    width: 30%;
  }
  to {
    left: 6%;
    width: 88%;
  }
}
@keyframes md-next-in {
  from {
    left: calc(126% + 10px);
  }
  to {
    left: calc(94% + 10px);
  }
}
@keyframes md-current-to-previous {
  from {
    left: 6%;
    width: 88%;
  }
  to {
    left: calc(-24% - 20px);
    width: 30%;
  }
}
@keyframes md-previous-to-first {
  from {
    left: calc(-24% - 20px);
    width: 30%;
  }
  to {
    left: 0;
    width: 88%;
  }
}
@keyframes md-current-to-next-large {
  from {
    left: 6%;
    width: 88%;
  }
  to {
    left: calc(88% + 20px);
    width: 30%;
  }
}
@keyframes md-next-out {
  from {
    left: calc(94% + 10px);
    display: inline-block;
  }
  to {
    left: calc(124% + 10px);
    display: inline-block;
  }
}
@keyframes md-previous-large-to-current {
  from {
    left: calc(-18% - 30px);
    width: 30%;
  }
  to {
    left: 6%;
    width: 88%;
  }
}
@keyframes md-last-to-next {
  from {
    left: calc(12% - 10px);
    width: 88%;
  }
  to {
    left: calc(94% + 10px);
    width: 30%;
  }
}
@keyframes md-previous-in {
  from {
    left: calc(-54% - 10px);
  }
  to {
    left: calc(-24% - 20px);
  }
}
@keyframes md-previous-to-current {
  from {
    left: calc(-24% - 20px);
    width: 30%;
  }
  to {
    left: 6%;
    width: 88%;
  }
}
@keyframes md-current-to-next {
  from {
    left: 6%;
    width: 88%;
  }
  to {
    left: calc(94% + 10px);
    width: 30%;
  }
}
@keyframes lg-first-to-previous {
  from {
    left: 0;
    width: 60%;
  }
  to {
    left: calc(-20% - 40px);
    width: 30%;
  }
}
@keyframes lg-first-right-to-full {
  from {
    left: calc(60% + 20px);
    width: 30%;
  }
  to {
    left: calc(10% - 20px);
    width: 60%;
  }
}
@keyframes lg-first-right-preview-to-full-preview {
  from {
    left: calc(90% + 40px);
  }
  to {
    left: 70%;
  }
}
@keyframes lg-last-to-full {
  from {
    width: 30%;
    left: 70%;
  }
  to {
    left: 40%;
    width: 60%;
  }
}
@keyframes lg-full-to-large-previous {
  from {
    width: 60%;
  }
  to {
    width: 30%;
  }
}
@keyframes lg-preview-right-to-full {
  from {
    width: 30%;
    left: 70%;
  }
  to {
    left: calc(10% - 20px);
    width: 60%;
  }
}
@keyframes lg-next-in {
  from {
    left: calc(100% + 20px);
  }
  to {
    left: 70%;
  }
}
@keyframes lg-full-to-previous {
  from {
    left: calc(10% - 20px);
    width: 60%;
  }
  to {
    left: calc(-20% - 40px);
    width: 30%;
  }
}
@keyframes lg-previous-out {
  from {
    left: calc(-20% - 40px);
  }
  to {
    left: calc(-50% - 40px);
  }
}
@keyframes lg-previous-to-first {
  from {
    left: calc(-20% - 40px);
    width: 30%;
  }
  to {
    left: 0;
    width: 60%;
  }
}
@keyframes lg-full-to-first-next {
  from {
    left: calc(10% - 20px);
    width: 60%;
  }
  to {
    left: calc(60% + 20px);
    width: 30%;
  }
}
@keyframes lg-full-preview-to-preview-next {
  from {
    left: 70%;
  }
  to {
    left: calc(90% + 40px);
  }
}
@keyframes lg-left-full-to-full {
  from {
    width: 30%;
  }
  to {
    width: 60%;
  }
}
@keyframes lg-last-to-preview-right {
  from {
    width: 60%;
    left: 40%;
  }
  to {
    left: 70%;
    width: 30%;
  }
}
@keyframes lg-previous-to-full {
  from {
    width: 30%;
    left: calc(-20% - 40px);
  }
  to {
    left: calc(10% - 20px);
    width: 60%;
  }
}
@keyframes lg-previous-in {
  from {
    left: calc(-50% - 40px);
  }
  to {
    left: calc(-20% - 40px);
  }
}
@keyframes lg-full-to-right-preview {
  from {
    left: calc(10% - 20px);
    width: 60%;
  }
  to {
    left: 70%;
    width: 30%;
  }
}
@keyframes lg-right-preview-out {
  from {
    left: 70%;
  }
  to {
    left: calc(100% + 20px);
  }
}
.scs-portal-slider-item .hero-banner .hero-banner-content .banner .banner-content {
  width: 100%;
}
.scs-portal-slider-item .hero-banner .hero-banner-content .banner .banner-content sdx-button,
.scs-portal-slider-item .hero-banner .hero-banner-content .banner .banner-content .modDmcButton,
.scs-portal-slider-item .hero-banner .hero-banner-content .banner .banner-content .hero-countdown-container {
  opacity: 1;
}
.scs-portal-slider-item.preview .hero-banner .hero-banner-content .banner .banner-content {
  width: 100%;
}
.scs-portal-slider-item.preview .hero-banner .hero-banner-content .banner .banner-content sdx-button,
.scs-portal-slider-item.preview .hero-banner .hero-banner-content .banner .banner-content .modDmcButton,
.scs-portal-slider-item.preview .hero-banner .hero-banner-content .banner .banner-content .hero-countdown-container {
  opacity: 0;
}
@media (min-width: 0) and (max-width: 479px) {
  .scs-portal-slider-item .hero-banner .hero-banner-content > .banner {
    justify-content: flex-start;
  }
  .scs-portal-slider-item .hero-banner .hero-banner-content > .banner .banner-content .text-d3 {
    font-size: 32px;
    line-height: 40px;
  }
}
@media (min-width: 768px) {
  .scs-portal-slider-item .hero-banner .hero-banner-content {
    display: block;
  }
  .scs-portal-slider-item .hero-banner .hero-banner-content > .banner {
    position: relative;
    width: 50%;
    height: 100%;
  }
  .scs-portal-slider-item .hero-banner .hero-banner-content > .banner .banner-content {
    z-index: 1;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
  }
  .scs-portal-slider-item .hero-banner .hero-banner-content > .banner .banner-content .title {
    font-size: 54px;
    line-height: 64px;
  }
  .scs-portal-slider-item .hero-banner .image-wrapper {
    position: absolute;
    width: 50%;
    left: 50%;
    height: 100%;
    bottom: 0;
  }
  .scs-portal-slider-item .hero-banner .image-wrapper .sdx-stickers {
    opacity: 1;
    right: 5%;
    bottom: 10%;
  }
  .scs-portal-slider-item.preview .hero-banner .hero-banner-content .banner {
    width: 100%;
  }
  .scs-portal-slider-item.preview .hero-banner .hero-banner-content .banner .banner-content {
    top: 0;
    transform: translate(0, 0);
  }
  .scs-portal-slider-item.preview .hero-banner .hero-banner-content .banner .banner-content .title,
  .scs-portal-slider-item.preview .hero-banner .hero-banner-content .banner .banner-content .text-d3 {
    font-size: 40px;
    line-height: 48px;
  }
  .scs-portal-slider-item.preview .hero-banner .hero-banner-content .image-wrapper {
    width: 100%;
    left: 0;
    bottom: 0;
    height: calc(100% - 240px);
  }
  .scs-portal-slider-item.preview .hero-banner .hero-banner-content .image-wrapper img:hover {
    transform: none;
  }
  .scs-portal-slider-item.preview .hero-banner .hero-banner-content .image-wrapper .sdx-stickers {
    opacity: 0;
  }
}
.scs-portal-slider-item.to-full .hero-banner .hero-banner-content .banner, .scs-portal-slider-item.to-preview .hero-banner .hero-banner-content .banner {
  transition: all 720ms;
}
.scs-portal-slider-item.to-full .hero-banner .hero-banner-content .banner .banner-content, .scs-portal-slider-item.to-preview .hero-banner .hero-banner-content .banner .banner-content {
  transition: all 720ms;
}
.scs-portal-slider-item.to-full .hero-banner .hero-banner-content .banner .title, .scs-portal-slider-item.to-preview .hero-banner .hero-banner-content .banner .title {
  transition: all 720ms;
}
.scs-portal-slider-item.to-full .hero-banner .hero-banner-content .banner sdx-button,
.scs-portal-slider-item.to-full .hero-banner .hero-banner-content .banner .modDmcButton,
.scs-portal-slider-item.to-full .hero-banner .hero-banner-content .banner .hero-countdown-container, .scs-portal-slider-item.to-preview .hero-banner .hero-banner-content .banner sdx-button,
.scs-portal-slider-item.to-preview .hero-banner .hero-banner-content .banner .modDmcButton,
.scs-portal-slider-item.to-preview .hero-banner .hero-banner-content .banner .hero-countdown-container {
  transition: all 720ms;
}
.scs-portal-slider-item.to-full .hero-banner .hero-banner-content .image-wrapper,
.scs-portal-slider-item.to-full .hero-banner .hero-banner-content .sdx-stickers, .scs-portal-slider-item.to-preview .hero-banner .hero-banner-content .image-wrapper,
.scs-portal-slider-item.to-preview .hero-banner .hero-banner-content .sdx-stickers {
  transition: all 720ms;
}

scs-portal-slider .content-wrapper {
  position: relative;
}
scs-portal-slider .content-wrapper .slides-wrapper {
  position: relative;
  overflow-x: auto;
  scrollbar-width: none;
  width: 100%;
}
scs-portal-slider .content-wrapper .slides-wrapper .slides {
  position: relative;
  width: 100%;
  display: block;
  gap: 20px;
}
@media (min-width: 0) and (max-width: 479px) {
  scs-portal-slider .content-wrapper {
    padding-left: 18px;
    padding-right: 18px;
  }
  scs-portal-slider .content-wrapper .slides-wrapper {
    overflow-x: visible;
  }
}
scs-portal-slider.animation-speedup {
  --scs-portal-slider-animation-time: 450ms;
}

.scs-portal-slider-item {
  flex-shrink: 0;
  position: relative;
  display: none;
}
.scs-portal-slider-item[current] {
  display: inline-block;
}
.scs-portal-slider-item.full, .scs-portal-slider-item.preview {
  width: 100%;
}
.scs-portal-slider-item.preview::before {
  content: " ";
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
}
@media (max-width: 479px) {
  .scs-portal-slider-item {
    display: inline-block;
    margin-left: 4px;
    margin-right: 4px;
  }
  .scs-portal-slider-item.preview:not([next]):not([previous]) {
    display: none;
  }
  .scs-portal-slider-item[first][current] {
    left: 0;
  }
  .scs-portal-slider-item[last][current] {
    left: calc(12% - 4px);
  }
  .scs-portal-slider-item[previous].preview-middle {
    left: calc(-82% - 8px);
  }
  .scs-portal-slider-item[previous].preview-left {
    left: calc(-76% - 12px);
  }
  .scs-portal-slider-item[next].preview-middle {
    left: calc(94% + 4px);
  }
  .scs-portal-slider-item.full {
    width: 88%;
  }
  .scs-portal-slider-item.full:not([first]):not([last]) {
    margin-left: 0;
    left: 6%;
  }
  .scs-portal-slider-item[first] {
    margin-left: 0;
  }
  .scs-portal-slider-item[last] {
    margin-right: 0;
  }
  .scs-portal-slider-item.preview {
    width: 88%;
    position: absolute;
  }
}
@media (min-width: 480px) and (max-width: 1023px) {
  .scs-portal-slider-item {
    display: inline-block;
    margin-left: 10px;
    margin-right: 10px;
  }
  .scs-portal-slider-item.preview:not([next]):not([previous]) {
    display: none;
  }
  .scs-portal-slider-item[first][current] {
    left: 0;
  }
  .scs-portal-slider-item[last][current] {
    left: calc(12% - 10px);
  }
  .scs-portal-slider-item[previous].preview-middle {
    left: calc(-24% - 20px);
  }
  .scs-portal-slider-item[previous].preview-left {
    left: calc(-18% - 30px);
  }
  .scs-portal-slider-item[next].preview-middle {
    left: calc(94% + 10px);
  }
  .scs-portal-slider-item.full {
    width: 88%;
  }
  .scs-portal-slider-item.full:not([first]):not([last]) {
    margin-left: 0;
    left: 6%;
  }
  .scs-portal-slider-item[first] {
    margin-left: 0;
  }
  .scs-portal-slider-item[last] {
    margin-right: 0;
  }
  .scs-portal-slider-item.preview {
    width: 30%;
    position: absolute;
  }
}
@media (min-width: 1024px) {
  .scs-portal-slider-item {
    display: inline-block;
  }
  .scs-portal-slider-item[first][current] {
    left: 0;
  }
  .scs-portal-slider-item.lg-first-preview-right-full {
    left: calc(60% + 20px);
  }
  .scs-portal-slider-item.lg-first-preview-right {
    left: calc(90% + 40px);
  }
  .scs-portal-slider-item.lg-full {
    left: calc(10% - 20px);
  }
  .scs-portal-slider-item.lg-left-preview {
    left: calc(-20% - 40px);
  }
  .scs-portal-slider-item.lg-right-preview {
    left: 70%;
  }
  .scs-portal-slider-item.lg-last-full {
    left: 40%;
  }
  .scs-portal-slider-item.lg-last-left-preview {
    left: calc(10% - 20px);
  }
  .scs-portal-slider-item.full {
    width: 60%;
  }
  .scs-portal-slider-item.preview {
    width: 30%;
    position: absolute;
  }
}
.scs-portal-slider-item.xs-first-to-previous {
  animation-duration: var(--scs-portal-slider-animation-time, 720ms);
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-name: xs-first-to-previous;
}
.scs-portal-slider-item.xs-next-to-current {
  animation-duration: var(--scs-portal-slider-animation-time, 720ms);
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-name: xs-next-to-current;
}
.scs-portal-slider-item.xs-to-small-left-preview {
  animation-duration: var(--scs-portal-slider-animation-time, 720ms);
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-name: xs-to-small-left-preview;
}
.scs-portal-slider-item.xs-last-to-current {
  animation-duration: var(--scs-portal-slider-animation-time, 720ms);
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-name: xs-last-to-current;
}
.scs-portal-slider-item.xs-current-large-previous {
  animation-duration: var(--scs-portal-slider-animation-time, 720ms);
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-name: xs-current-large-previous;
}
.scs-portal-slider-item.xs-previous-out {
  animation-duration: var(--scs-portal-slider-animation-time, 720ms);
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-name: xs-previous-out;
}
.scs-portal-slider-item.xs-next-in {
  animation-duration: var(--scs-portal-slider-animation-time, 720ms);
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-name: xs-next-in;
}
.scs-portal-slider-item.xs-current-to-previous {
  animation-duration: var(--scs-portal-slider-animation-time, 720ms);
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-name: xs-current-to-previous;
}
.scs-portal-slider-item.xs-previous-to-first {
  animation-duration: var(--scs-portal-slider-animation-time, 720ms);
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-name: xs-previous-to-first;
}
.scs-portal-slider-item.xs-current-to-next-large {
  animation-duration: var(--scs-portal-slider-animation-time, 720ms);
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-name: xs-current-to-next-large;
}
.scs-portal-slider-item.xs-next-out {
  animation-duration: var(--scs-portal-slider-animation-time, 720ms);
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-name: xs-next-out;
}
.scs-portal-slider-item.xs-previous-large-to-current {
  animation-duration: var(--scs-portal-slider-animation-time, 720ms);
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-name: xs-previous-large-to-current;
}
.scs-portal-slider-item.xs-last-to-next {
  animation-duration: var(--scs-portal-slider-animation-time, 720ms);
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-name: xs-last-to-next;
}
.scs-portal-slider-item.xs-previous-in {
  animation-duration: var(--scs-portal-slider-animation-time, 720ms);
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-name: xs-previous-in;
}
.scs-portal-slider-item.xs-previous-to-current {
  animation-duration: var(--scs-portal-slider-animation-time, 720ms);
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-name: xs-previous-to-current;
}
.scs-portal-slider-item.xs-current-to-next {
  animation-duration: var(--scs-portal-slider-animation-time, 720ms);
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-name: xs-current-to-next;
}
.scs-portal-slider-item.md-first-to-previous {
  animation-duration: var(--scs-portal-slider-animation-time, 720ms);
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-name: md-first-to-previous;
}
.scs-portal-slider-item.md-next-large-to-current {
  animation-duration: var(--scs-portal-slider-animation-time, 720ms);
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-name: md-next-large-to-current;
}
.scs-portal-slider-item.md-to-small-left-preview {
  animation-duration: var(--scs-portal-slider-animation-time, 720ms);
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-name: md-to-small-left-preview;
}
.scs-portal-slider-item.md-last-to-current {
  animation-duration: var(--scs-portal-slider-animation-time, 720ms);
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-name: md-last-to-current;
}
.scs-portal-slider-item.md-current-large-previous {
  animation-duration: var(--scs-portal-slider-animation-time, 720ms);
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-name: md-current-large-previous;
}
.scs-portal-slider-item.md-previous-out {
  animation-duration: var(--scs-portal-slider-animation-time, 720ms);
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-name: md-previous-out;
}
.scs-portal-slider-item.md-next-to-current {
  animation-duration: var(--scs-portal-slider-animation-time, 720ms);
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-name: md-next-to-current;
}
.scs-portal-slider-item.md-next-in {
  animation-duration: var(--scs-portal-slider-animation-time, 720ms);
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-name: md-next-in;
}
.scs-portal-slider-item.md-current-to-previous {
  animation-duration: var(--scs-portal-slider-animation-time, 720ms);
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-name: md-current-to-previous;
}
.scs-portal-slider-item.md-previous-to-first {
  animation-duration: var(--scs-portal-slider-animation-time, 720ms);
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-name: md-previous-to-first;
}
.scs-portal-slider-item.md-current-to-next-large {
  animation-duration: var(--scs-portal-slider-animation-time, 720ms);
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-name: md-current-to-next-large;
}
.scs-portal-slider-item.md-previous-large-to-current {
  animation-duration: var(--scs-portal-slider-animation-time, 720ms);
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-name: md-previous-large-to-current;
}
.scs-portal-slider-item.md-last-to-next {
  animation-duration: var(--scs-portal-slider-animation-time, 720ms);
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-name: md-last-to-next;
}
.scs-portal-slider-item.md-previous-in {
  animation-duration: var(--scs-portal-slider-animation-time, 720ms);
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-name: md-previous-in;
}
.scs-portal-slider-item.md-previous-to-current {
  animation-duration: var(--scs-portal-slider-animation-time, 720ms);
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-name: md-previous-to-current;
}
.scs-portal-slider-item.md-current-to-next {
  animation-duration: var(--scs-portal-slider-animation-time, 720ms);
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-name: md-current-to-next;
}
.scs-portal-slider-item.lg-first-to-previous {
  animation-duration: var(--scs-portal-slider-animation-time, 720ms);
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-name: lg-first-to-previous;
}
.scs-portal-slider-item.lg-first-right-to-full {
  animation-duration: var(--scs-portal-slider-animation-time, 720ms);
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-name: lg-first-right-to-full;
}
.scs-portal-slider-item.lg-first-right-preview-to-full-preview {
  animation-duration: var(--scs-portal-slider-animation-time, 720ms);
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-name: lg-first-right-preview-to-full-preview;
}
.scs-portal-slider-item.lg-last-to-full {
  animation-duration: var(--scs-portal-slider-animation-time, 720ms);
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-name: lg-last-to-full;
}
.scs-portal-slider-item.lg-full-to-large-previous {
  animation-duration: var(--scs-portal-slider-animation-time, 720ms);
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-name: lg-full-to-large-previous;
}
.scs-portal-slider-item.lg-preview-right-to-full {
  animation-duration: var(--scs-portal-slider-animation-time, 720ms);
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-name: lg-preview-right-to-full;
}
.scs-portal-slider-item.lg-next-in {
  animation-duration: var(--scs-portal-slider-animation-time, 720ms);
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-name: lg-next-in;
}
.scs-portal-slider-item.lg-full-to-previous {
  animation-duration: var(--scs-portal-slider-animation-time, 720ms);
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-name: lg-full-to-previous;
}
.scs-portal-slider-item.lg-previous-to-first {
  animation-duration: var(--scs-portal-slider-animation-time, 720ms);
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-name: lg-previous-to-first;
}
.scs-portal-slider-item.lg-full-to-first-next {
  animation-duration: var(--scs-portal-slider-animation-time, 720ms);
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-name: lg-full-to-first-next;
}
.scs-portal-slider-item.lg-full-preview-to-preview-next {
  animation-duration: var(--scs-portal-slider-animation-time, 720ms);
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-name: lg-full-preview-to-preview-next;
}
.scs-portal-slider-item.lg-left-full-to-full {
  animation-duration: var(--scs-portal-slider-animation-time, 720ms);
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-name: lg-left-full-to-full;
}
.scs-portal-slider-item.lg-last-to-preview-right {
  animation-duration: var(--scs-portal-slider-animation-time, 720ms);
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-name: lg-last-to-preview-right;
}
.scs-portal-slider-item.lg-previous-to-full {
  animation-duration: var(--scs-portal-slider-animation-time, 720ms);
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-name: lg-previous-to-full;
}
.scs-portal-slider-item.lg-full-to-right-preview {
  animation-duration: var(--scs-portal-slider-animation-time, 720ms);
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-name: lg-full-to-right-preview;
}
.scs-portal-slider-item.lg-right-preview-out {
  animation-duration: var(--scs-portal-slider-animation-time, 720ms);
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-name: lg-right-preview-out;
}