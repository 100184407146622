@use '~@swisscom/sdx/stylesheets/sdx/variables/colors';
@use 'lib/styles/defs/breakpoints';
@import 'lib/styles/defs/mixins';

.component-banner {
	div > a {
		position: relative;
		display: block;
	}
	&.rounded-corners {
		> div,
		.img-wrapper,
		.centralized_over img {
			border-radius: 12px;
			padding-left: 0;
		}
	}

	.cmp-teaser_fullbleed {
		left: 50%;
		margin-left: -50vw;
		margin-right: -50vw;
		width: 100vw;
		right: 50%;
		position: relative;
		max-width: 1920px;
		@media screen and (min-width: 1920px) {
			margin-left: -960px;
			margin-right: -960px;
		}

		.description_over {
			@media screen and (min-width: breakpoints.$breakpoint-lg) {
				max-width: 678px;
			}
		}
	}

	.img-wrapper {
		background-position: center 50%;
		background-repeat: no-repeat;
		background-size: cover;
	}

	.centralized_over {
		position: absolute;
		display: flex;
		height: 100%;
		overflow: hidden;
		top: 0;
		padding-top: 16px;
		width: 100%;
		justify-content: center;
		@media screen and (min-width: breakpoints.$breakpoint-lg) {
			align-items: center;
			justify-content: flex-end;
			width: 50%;
			margin-left: 50%;
			max-width: 678px;
			padding-top: 0;
		}

		&.img-left {
			justify-content: flex-start;
		}

		&.img-center {
			justify-content: center;
		}

		&.img-right {
			justify-content: flex-end;
		}

		img {
			padding-left: 24px;
			height: 169px;
			width: auto;
			display: block;
			object-fit: contain;
			@media screen and (min-width: breakpoints.$breakpoint-md) {
				height: 222px;
			}
			@media screen and (min-width: breakpoints.$breakpoint-lg) {
				height: auto;
				max-height: 287px;
				width: 100%;
			}
			@media screen and (min-width: breakpoints.$breakpoint-xl) {
				height: auto;
				max-height: 366px;
			}
			@media screen and (min-width: breakpoints.$breakpoint-ul) {
				height: auto;
				max-height: 416px;
			}
		}

		.sticker_holder {
			margin: 0 auto;
			@media screen and (min-width: breakpoints.$breakpoint-ul) {
				margin-right: 0;
			}
		}
	}

	.description_over {
		position: absolute;
		display: flex;
		width: 100%;
		height: auto;
		bottom: 39px;
		@media screen and (min-width: breakpoints.$breakpoint-md) {
			height: auto;
			bottom: 42px;
		}
		@media screen and (min-width: breakpoints.$breakpoint-lg) {
			top: 0;
			width: 50%;
			right: 50%;
			height: 100%;
			max-width: 654px;
			&.text-top {
				align-items: flex-start;
			}
			&.text-center {
				align-items: center;
			}
			&.text-bottom {
				align-items: flex-end;
			}
		}

		.description_over_wrapper {
			.underlined-link {
				&:hover,
				&:focus {
					text-decoration: underline 2px;
				}
			}
			margin-left: 16px;
			margin-right: 16px;
			@media screen and (min-width: breakpoints.$breakpoint-md) {
				margin-left: 20px;
				margin-right: 20px;
			}
			@media screen and (min-width: breakpoints.$breakpoint-lg) {
				margin-left: 40px;
				margin-right: 0;
				margin-top: 68px;
				margin-bottom: 68px;
			}
			@media screen and (min-width: breakpoints.$breakpoint-xl) {
				margin-left: 42px;
			}
			@media screen and (min-width: breakpoints.$breakpoint-ul) {
				margin-left: 0;
			}
			@media screen and (max-width: breakpoints.$breakpoint-max-md) {
				display: flex;
				flex-direction: column;
				.bottom-section {
					height: auto;
					display: flex;
					flex-direction: column;
					justify-content: flex-end;
				}
			}

			.bottom-section {
				@media screen and (min-width: breakpoints.$breakpoint-lg) {
					margin-top: 40px;
				}
			}
		}
	}

	.countdown {
		margin: 0 0 32px 0;

		@media screen and (min-width: breakpoints.$breakpoint-sm) {
			margin: 0 0 16px 0;
		}
		@media screen and (min-width: breakpoints.$breakpoint-md) {
			margin: 0 0 16px 0;
		}
	}

	.small-banner {
		.centralized_over {
			img {
				height: 169px;
				@media screen and (min-width: breakpoints.$breakpoint-md) {
					height: 138px;
				}
				@media screen and (min-width: breakpoints.$breakpoint-lg) {
					height: auto;
					max-height: 178px;
				}
				@media screen and (min-width: breakpoints.$breakpoint-xl) {
					max-height: 226px;
				}
				@media screen and (min-width: breakpoints.$breakpoint-ul) {
					max-height: 256px;
				}
			}
		}

		.description_over {
			height: 250px;
			@media screen and (min-width: breakpoints.$breakpoint-md) {
				height: 128px;
			}
			@media screen and (min-width: breakpoints.$breakpoint-lg) {
				height: 100%;
			}

			.description_over_wrapper {
				align-self: center;

				.bottom-section {
					margin-top: 32px;

					@media screen and (min-width: breakpoints.$breakpoint-md) {
						margin-top: 16px;
					}
					@media screen and (min-width: breakpoints.$breakpoint-lg) {
						margin-top: 40px;
					}
				}
			}
		}

		.img-wrapper {
			@media screen and (max-width: breakpoints.$breakpoint-max-sm) {
				min-height: 480px !important;
			}
		}
	}

	.sticker-mobile,
	.background-mobile,
	.img-wrapper-no-sticker-mobile {
		display: none;
		@media screen and (max-width: breakpoints.$breakpoint-max-md) {
			display: block;
		}

		&.only-desktop {
			display: none;
		}
	}

	.sticker-desktop,
	.background-desktop,
	.img-wrapper-no-sticker-desktop {
		display: none;
		@media screen and (min-width: breakpoints.$breakpoint-lg) {
			display: block;
		}

		&.only-desktop {
			display: block;
		}
	}

	&.text-white .description_over {
		:not(button, .countdown:not(.theme-orchid-squares) *) {
			color: colors.$color-white;
		}
	}

	&.text-navy .description_over {
		:not(button) {
			color: colors.$color-navy;
		}
	}

	&.text-blue .description_over {
		:not(button) {
			color: colors.$color-blue;
		}
	}

	&.pretitle-navy .description_over .subtitle {
		color: colors.$color-navy;
	}

	.bg-color-gradient-red {
		@include gradientRed;
	}

	.bg-color-gradient-blue {
		@include gradientBlue;
	}

	.bg-color-gradient-violet {
		@include gradientViolet;
	}

	.bg-color-cloud {
		@include gradientCloud;
	}
	.animated-bg-gradient-red {
		@extend %twinkleAnimation;
	}
}
