.chip {
	.badge-align-left {
		&.badge {
			&.badge--message {
				-webkit-justify-content: flex-start;
				-ms-flex-pack: start;
				justify-content: flex-start;
			}
		}
	}

	.badge-align-right {
		&.badge {
			&.badge--message {
				-webkit-justify-content: flex-end;
				-ms-flex-pack: end;
				justify-content: flex-end;
			}
		}
	}

	& .skinBadgeLink {
		z-index: 999;

		.badge {
			&.badge--message {
				justify-content: flex-start;

				a {
					max-width: none;
					border-bottom: none;

					&.badge__content {
						padding-left: 5px;
						padding-right: 5px;
						cursor: pointer;

						.badge__text {
							letter-spacing: normal;
						}

						&:hover,
						&:focus {
							border: none;
						}
					}
				}
			}
		}
	}
}
