/* These styles are only temporary for demo */
/* In the future we should render sdx html */
@use '~@swisscom/sdx/stylesheets/sdx/variables/colors';
@use 'lib/styles/defs/breakpoints';
@import 'lib/styles/defs/mixins';
@import 'teaser-mixins';

.teaser {
	@media only screen and (min-width: breakpoints.$breakpoint-lg) {
		margin: 12px 0;
	}

	&.rounded-corners {
		.cmp-teaser,
		.img-wrapper,
		.testimonial-bg-img,
		.gradient {
			border-radius: 12px;
		}

		.teaser-badge-left {
			border-radius: 12px 0 0 0;

			&.teaser-badge-ribbon {
				border-radius: 12px;
			}
		}

		.teaser-badge-right {
			border-radius: 0 12px 0 0;

			&.teaser-badge-ribbon {
				border-radius: 12px;
			}
		}

		&:not(.sc-teaser-img-right):not(.sc-teaser-img-left):not(.sc-teaser-img-bottom) {
			.sc-teaserStandard,
			.sc-teaserResProduct,
			.sc-teaserPromotional,
			.sc-teaserAbout,
			.sc-teaserDefault {
				.cmp-image__image {
					border-radius: 12px 12px 0 0;
					overflow: hidden;
				}
			}
		}

		&.sc-teaser-img-right {
			.sc-teaserStandard,
			.sc-teaserDefault {
				.cmp-image__image {
					border-radius: 0 12px 12px 0;
				}
			}
		}

		&.sc-teaser-img-left {
			.sc-teaserStandard,
			.sc-teaserDefault {
				.cmp-image__image {
					border-radius: 12px 0 0 12px;
				}
			}
		}

		.sc-teaserNavigation .cmp-image__image {
			border-radius: 12px 0 0 0;
		}

		&.sc-teaser-img-bottom {
			.sc-teaserStandard,
			.sc-teaserNews {
				.cmp-image__image {
					border-radius: 0 0 12px 12px;
				}
			}
		}
	}

	.bg-color-gradient-violet {
		@include gradientViolet;
	}

	.bg-color-gradient-red {
		@include gradientRed;
	}

	.bg-color-gradient-blue {
		@include gradientBlue;
	}

	.bg-color-cloud {
		@include gradientCloud;
	}
	.animated-bg-gradient-red {
		@extend %twinkleAnimation;
	}
}

.sc-teaserAbout,
.sc-teaserNavigation,
.sc-teaserStandard,
.sc-teaserDevice,
.sc-teaserMultiElement,
.sc-teaserPicture {
	> a {
		text-decoration: none;
	}
}

.sc-teaserMultiElement {
	> a,
	> a.landscape-image {
		.sc-teaser-img-right &,
		.sc-teaser-img-left & {
			@media only screen and (min-width: breakpoints.$breakpoint-lg) {
				display: grid;
				height: 100%;
				min-height: 440px;
				align-items: center;

				.centralized_over {
					position: relative;

					.sticker_holder {
						img {
							width: 100%;
							height: auto;
						}
					}
				}

				.img-wrapper {
					width: 100%;
					height: 100%;
					position: absolute;
				}
			}

			.description_over {
				@media only screen and (min-width: breakpoints.$breakpoint-lg) {
					position: relative;
					width: 100%;
					margin-top: 24px;
				}
				@media only screen and (max-width: breakpoints.$breakpoint-max-md) {
					.title {
						font-size: 28px;
						line-height: 32px;
						letter-spacing: -0.75px;
						margin-bottom: 22px;
					}
					.subtitle {
						font-size: 18px;
						line-height: 24px;
						letter-spacing: -0.1px;
						margin-bottom: 8px;
					}
				}
			}
		}

		@media only screen and (min-width: breakpoints.$breakpoint-lg) {
			.sc-teaser-img-right & {
				grid-template-areas: 'b a';
				grid-template-columns: 60% 40%;

				.centralized_over {
					grid-area: a;
				}

				.description_over {
					grid-area: b;
					padding-right: 8px;
				}
			}
			.sc-teaser-img-left & {
				grid-template-columns: 40% 60%;
			}
		}
	}

	> a.landscape-image {
		.sc-teaser-img-right &,
		.sc-teaser-img-left & {
			@media only screen and (min-width: breakpoints.$breakpoint-md) {
				.centralized_over .sticker_holder {
					img {
						width: 100% !important;
						height: auto !important;
					}
				}
			}
		}
	}

	.countdown-container {
		position: relative;
	}

	.countdown {
		position: absolute;
		z-index: 1;

		&[class*='top-'] {
			top: 40px;
			@media screen and (min-width: breakpoints.$breakpoint-sm) {
				top: 40px;
			}
			@media screen and (min-width: breakpoints.$breakpoint-md) {
				top: 16px;
			}
		}

		&.top-left {
			left: 16px;
		}

		&.top-center {
			margin-left: auto;
			margin-right: auto;
			left: 0;
			right: 0;
			width: fit-content;
		}

		&.top-right {
			right: 16px;
		}

		&.bottom-right {
			bottom: 16px;
			right: 16px;
		}

		&.bottom-left {
			bottom: 16px;
			left: 16px;
		}

		&.bottom-center {
			bottom: 16px;
			margin-left: auto;
			margin-right: auto;
			left: 0;
			right: 0;
			width: fit-content;
		}
	}

	.teaser-banner {
		.countdown {
			&[class*='top-'] {
				@media screen and (min-width: breakpoints.$breakpoint-sm) {
					top: 32px;
				}
				@media screen and (min-width: breakpoints.$breakpoint-md) {
					top: 60px;
				}
				@media screen and (min-width: breakpoints.$breakpoint-lg) {
					top: 16px;
				}
			}
		}
	}

	.landscape-image {
		.countdown {
			&[class*='top-'] {
				@media screen and (min-width: breakpoints.$breakpoint-sm) {
					top: 42px;
				}
				@media screen and (min-width: breakpoints.$breakpoint-md) {
					top: 52px;
				}
			}
		}

		&.teaser-banner {
			.countdown {
				&[class*='top-'] {
					@media screen and (min-width: breakpoints.$breakpoint-sm) {
						top: 32px;
					}
					@media screen and (min-width: breakpoints.$breakpoint-md) {
						top: 16px;
					}
				}
			}
		}
	}
}

.sc-teaser-stage {
	margin-top: -16px;
}

.sc-teaserDefault,
.sc-teaser-img-left,
.sc-teaser-img-right {
	margin-bottom: 24px;

	.cmp-teaser__title {
		color: colors.$color-navy;
		font-size: 22px;
		line-height: 26px;
		margin-bottom: 16px;
	}
}

/* change order, title, image, description */
.sc-teaserDefault,
.sc-teaserNavigation {
	.cmp-image__image {
		display: block;
	}

	.cmp-teaser__title {
		order: 1;
	}

	.cmp-teaser__image {
		order: 2;
	}
}

.sc-teaser-img-left,
.sc-teaser-img-right {
	.cmp-teaser {
		/* clear-fix */
		overflow: hidden;
	}

	.cmp-teaser__title {
		color: colors.$color-navy;
		font-size: 28px;
		line-height: 36px;
		letter-spacing: -0.5px;
	}

	.cmp-teaser__image {
		width: 48%;
	}
}

.sc-teaser-img-left {
	.cmp-teaser__image {
		float: left;
	}
}

.sc-teaser-img-right {
	.cmp-teaser__image {
		float: right;
	}
}

.cmp-teaser_border {
	border: 1px solid colors.$color-gray-tint-7;

	&.no-top-border {
		border-top: 0;
	}
}

.cmp-teaser_image {
	width: 100%;
}

.border-bottom {
	border-bottom: 1px solid colors.$color-gray-tint-7;
}

.cmp-teaser {
	position: relative;

	> a {
		display: block;
		border-bottom: none;
	}

	> a:not([href]) {
		cursor: default;
	}

	.button {
		text-shadow: none;
	}

	.table td,
	.table th {
		white-space: normal;
	}

	.margin-top-40 {
		margin-top: 40px;
	}

	.img-wrapper > .gradient {
		display: none;
		width: 50.2%;
		@media only screen and (max-width: 1920px) {
			width: 56.2%;
		}
		@media only screen and (max-width: breakpoints.$breakpoint-lg) {
			width: 69.2%;
		}
		@media only screen and (max-width: breakpoints.$breakpoint-md) {
			width: 100%;
		}
	}

	h4,
	.pretitle,
	.title {
		.title-align-left & {
			text-align: left;
		}

		.title-align-center & {
			text-align: center;
		}

		.title-align-right & {
			text-align: right;
		}

		&.sc-navy {
			color: var(--headline-color);
		}
	}

	b,
	strong {
		font-weight: 700;
	}

	&.sc-teaserNavigation ul.list.actions,
	&.sc-teaserStandard div.list.actions,
	&.sc-teaserStandard div.teaserStandard-button,
	&.sc-teaserDevice div.list.actions,
	&.sc-teaserDevice div.teaserDevice-button,
	&.sc-teaserAbout div.actions {
		.actions-align-left & {
			position: absolute;
		}

		.actions-align-center & {
			display: flex;
			position: initial;
			justify-content: center;
			gap: 6px;
		}

		.actions-align-right & {
			display: flex;
			position: initial;
			justify-content: flex-end;
			gap: 6px;
		}

		.actions-align-right &,
		.actions-align-center & {
			.icon-arrow-right,
			sdx-icon {
				align-self: center;
			}
		}
	}

	&.sc-teaserResProduct .content-bottom-section {
		.actions-align-left & {
			align-items: flex-start;
		}

		.actions-align-center & {
			align-items: center;

			.font {
				align-self: flex-start;
			}
		}

		.actions-align-right & {
			align-items: flex-end;

			.font {
				align-self: flex-start;
			}
		}
	}

	&.sc-teaserDefault ul.list.actions,
	&.sc-teaserTitleIcon ul.list.actions,
	&.sc-teaserTestimonial ul.list.actions,
	&.sc-teaserNews ul.list.actions {
		.actions-align-left & {
			li {
				display: list-item;
			}
		}

		.actions-align-center & {
			li {
				display: flex;
				justify-content: center;
				gap: 6px;
			}
		}

		.actions-align-right & {
			li {
				display: flex;
				justify-content: flex-end;
				gap: 6px;
			}
		}
	}

	&.sc-teaserMultiElement,
	&.sc-teaserPicture {
		.actions-align-left &,
		.actions-align-center &,
		.actions-align-right & {
			.description_over_wrapper {
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
			}
		}

		.actions-align-left & {
			.description_over_wrapper button {
				align-self: flex-start;
			}
		}

		.actions-align-center & {
			.description_over_wrapper button {
				align-self: center;
			}
		}

		.actions-align-right & {
			.description_over_wrapper button {
				align-self: flex-end;
			}
		}
	}

	&.sc-teaserStandard {
		.icon-align-left & {
			.image-icon-container {
				text-align: left;
			}
		}

		.icon-align-center & {
			.image-icon-container {
				text-align: center;
			}
		}

		.icon-align-right & {
			.image-icon-container {
				text-align: right;
			}
		}

		.sc-teaser-img-left & {
			.image-icon-container {
				padding-right: 0 !important;
			}
		}

		.sc-teaser-img-right & {
			.image-icon-container {
				padding-left: 0 !important;
			}
		}

		.sc-teaser-img-bottom & {
			.image-icon-container {
				padding-top: 0 !important;
			}
		}
	}

	.teaser-badge {
		top: 0;
		font-size: 18px;
		letter-spacing: 0.06px;

		&.teaser-badge-left {
			left: 0;
			transform: none;
		}

		&.teaser-badge-right {
			left: auto;
			right: 0;
			transform: none;
		}
	}

	&.sc-teaserPicture {
		height: 100%;
		width: 100%;

		.img-wrapper {
			background-position: center 50%;
			background-repeat: no-repeat;
			background-size: cover;
			@media only screen and (max-width: breakpoints.$breakpoint-max-xs) {
				min-height: 344px !important;
			}

			&.picture-align-center {
				background-position: center center;
			}

			&.picture-align-top {
				background-position: center top;
			}

			&.picture-align-bottom {
				background-position: center bottom;
			}

			&.picture-align-left {
				background-position: left center;
			}

			&.picture-align-right {
				background-position: right center;
			}
		}
	}

	/* multi element */
	&.sc-teaserMultiElement {
		height: 100%;
		width: 100%;

		.img-wrapper {
			background-position: center 50%;
			background-repeat: no-repeat;
			background-size: cover;
			@media only screen and (max-width: breakpoints.$breakpoint-max-xs) {
				min-height: 400px;
			}

			&.min-height {
				@media only screen and (max-width: breakpoints.$breakpoint-max-sm) {
					min-height: 480px;
				}
				@media only screen and (max-width: breakpoints.$breakpoint-max-xs) {
					min-height: 440px;
				}

				.gradient {
					@media only screen and (max-width: breakpoints.$breakpoint-max-sm) {
						min-height: 480px;
					}
					@media only screen and (max-width: breakpoints.$breakpoint-max-xs) {
						min-height: 440px;
					}
				}
			}
		}

		.sticker-only__500 {
			width: 500px;
			height: 300px;
		}

		.sticker-only__512 {
			width: 512px;
			height: 300px;
		}

		.sticker-only__400 {
			width: 400px;
			height: 300px;
		}

		.sticker-only__256 {
			width: 256px;
			height: 300px;
		}

		img {
			height: 136px;
			width: auto;
			@media only screen and (min-width: breakpoints.$breakpoint-sm) {
				height: 256px;
			}
		}

		.sdx-sticker {
			img {
				@media only screen and (max-width: breakpoints.$breakpoint-max-md) {
					margin-top: 44px;
				}
				@media only screen and (max-width: breakpoints.$breakpoint-max-lg) {
					.aem-GridColumn--default--4 & {
						margin-top: 44px;
					}
				}
			}
		}

		.sticker_holder:not(.sdx-sticker) {
			img {
				margin-top: 2px;
				@media screen and (min-width: breakpoints.$breakpoint-sm) {
					margin-top: 24px;
				}
				@media screen and (min-width: breakpoints.$breakpoint-md) {
					margin-top: 0;
				}
			}

			.logo-image-margin,
			.logo {
				img {
					@media only screen and (max-width: breakpoints.$breakpoint-max-lg) {
						margin-top: 0;
					}
				}
			}
		}

		.logo-image-margin,
		.logo {
			img {
				@media only screen and (max-width: breakpoints.$breakpoint-max-lg) {
					margin-top: 0;
				}
			}
		}

		.logo-above-title {
			.logo-image-margin {
				margin-top: 16px;
			}
		}

		.logo-above-image {
			display: flex;
			flex-direction: column;
			justify-content: center;

			.logo {
				margin-top: 32px;
				@media only screen and (min-width: breakpoints.$breakpoint-sm) {
					margin-top: 16px;
				}
			}

			.logo-image-margin {
				margin-top: 4px;
				margin-bottom: 0px;
			}
		}

		.sticker_holder:not(.sdx-sticker).logo-below-image,
		.sdx-sticker.logo-below-image .sticker-logo {
			display: flex;
			flex-direction: column-reverse;
			justify-content: center;

			.logo-image-margin {
				margin-top: 32px;
				margin-bottom: 4px;
				@media only screen and (min-width: breakpoints.$breakpoint-sm) {
					margin-top: 16px;
				}
			}
		}

		.sdx-sticker.logo-below-image .sticker-mobile .sticker-logo {
			.logo img {
				margin-top: 0;
			}

			@media only screen and (max-width: breakpoints.$breakpoint-max-sm) {
				.logo-image-margin {
					margin-top: 0;
				}
			}
		}

		.sdx-sticker.logo-above-image .sticker-mobile .sticker-logo {
			.logo-image-margin img {
				margin-top: 0;
			}

			.logo {
				margin-top: 0;
			}
		}

		.teaser.aem-GridColumn--default--6 & {
			.sdx-sticker.logo-below-image,
			.sdx-sticker.logo-above-image {
				.sticker-mobile .sticker-logo {
					@media only screen and (max-width: breakpoints.$breakpoint-max-md) {
						margin-top: 44px;
					}
				}
			}

			.sdx-sticker.logo-below-image {
				.sticker-mobile .sticker-logo {
					.logo-image-margin {
						@media only screen and (max-width: breakpoints.$breakpoint-max-md) {
							margin-top: 0;
						}
					}
				}
			}

			.landscape-image {
				.centralized_over .sticker_holder {
					img {
						width: auto;
						height: 172px;
						margin-top: 0;
						@media only screen and (min-width: breakpoints.$breakpoint-sm) {
							height: 178px;
							margin-top: 32px;
						}
						@media only screen and (min-width: breakpoints.$breakpoint-md) {
							height: 219px;
							margin-top: 8px;
						}
						@media only screen and (min-width: breakpoints.$breakpoint-lg) {
							height: 256px;
						}
					}

					&.sdx-sticker img {
						margin-top: 64px;
						@media only screen and (min-width: breakpoints.$breakpoint-sm) {
							margin-top: 56px;
						}
						@media only screen and (min-width: breakpoints.$breakpoint-md) {
							margin-top: 44px;
						}
						@media only screen and (min-width: breakpoints.$breakpoint-lg) {
							margin-top: 0;
						}
					}
				}

				&.landscape-logo .centralized_over .sticker_holder {
					img {
						margin-top: 0;
					}
				}
			}
		}

		.teaser.aem-GridColumn--default--12 & {
			.landscape-image {
				&.landscape-logo {
					.centralized_over {
						.logo-above-image img,
						.logo-below-image img,
						.logo-above-title img {
							height: 172px;
							width: auto;
							@media only screen and (min-width: breakpoints.$breakpoint-sm) {
								height: 256px;
							}
							@media only screen and (min-width: breakpoints.$breakpoint-md) {
								height: 214px;
							}
							@media only screen and (min-width: breakpoints.$breakpoint-lg) {
								height: 256px;
							}
						}

						.logo img {
							margin-top: 0;
						}
					}

					.sdx-sticker {
						.centralized_over {
							.logo-above-image img,
							.logo-below-image img,
							.logo-above-title img {
								margin-top: 44px;
								@media only screen and (min-width: breakpoints.$breakpoint-lg) {
									margin-top: 0;
								}
							}
						}
					}

					.description_over {
						.logo-above-title img {
							margin-top: 0px !important;
						}
					}

					&.teaser-banner {
						.centralized_over {
							@media only screen and (min-width: breakpoints.$breakpoint-md) {
								justify-content: flex-end;
								align-items: center;
							}

							.logo-above-image,
							.logo-below-image {
								width: auto;
							}
						}
					}
				}

				.description_over {
					@media only screen and (max-width: breakpoints.$breakpoint-max-md) {
						width: 100%;
						align-items: flex-start;
						padding-left: 5%;
						padding-right: 5%;
						.description_over_wrapper {
							text-align: left;

							.title {
								width: 100%;
								text-align: left;
							}

							.subtitle {
								display: block;
								margin-bottom: 8px;
							}
						}
					}
					@media only screen and (max-width: breakpoints.$breakpoint-max-xs) {
						width: 100%;
						padding-left: 10%;
						padding-right: 10%;
					}
				}

				&.teaser-banner .centralized_over {
					justify-content: flex-end;
					align-items: flex-start;
					@media only screen and (min-width: breakpoints.$breakpoint-md) {
						align-items: center;
					}

					.sticker_holder {
						margin-right: auto;
						width: auto;
						@media only screen and (min-width: breakpoints.$breakpoint-md) {
							margin-right: 3.8%;
						}
						@media only screen and (min-width: breakpoints.$breakpoint-xl) {
							margin-right: 14.82%;
						}

						&:not(.sdx-sticker) {
							@media only screen and (min-width: breakpoints.$breakpoint-lg) {
								margin-right: 3.8%;
							}
							@media only screen and (min-width: breakpoints.$breakpoint-xl) {
								margin-right: 14.82%;
							}
						}
					}
				}

				&:not(.teaser-banner) {
					.centralized_over .sticker_holder {
						justify-content: center;
						margin-right: 0;
					}
				}

				.sticker_holder.sdx-sticker {
					margin-right: auto;
					@media screen and (min-width: breakpoints.$breakpoint-md) {
						margin-right: 3.8%;
					}
					@media screen and (min-width: breakpoints.$breakpoint-xl) {
						margin-right: 14.82%;
					}
					@media only screen and (min-width: breakpoints.$breakpoint-md) and (max-width: breakpoints.$breakpoint-max-md) {
						align-self: flex-end;
					}
					@media only screen and (max-width: breakpoints.$breakpoint-max-sm) {
						height: 100%;
					}

					img {
						@media only screen and (max-width: breakpoints.$breakpoint-max-md) {
							margin-top: 44px;
						}
						@media only screen and (max-width: breakpoints.$breakpoint-max-sm) {
							margin-top: 56px;
						}
						@media only screen and (max-width: breakpoints.$breakpoint-max-xs) {
							margin-top: 64px;
						}
					}
				}

				img {
					width: auto;
					height: 172px;
					margin-top: 22px;
					@media only screen and (min-width: breakpoints.$breakpoint-sm) {
						height: 256px;
						margin-top: 26px;
					}
					@media only screen and (min-width: breakpoints.$breakpoint-md) {
						height: 214px;
						margin-top: 36px;
					}
					@media only screen and (min-width: breakpoints.$breakpoint-lg) {
						height: 256px;
						margin-top: 36px;
					}
				}

				.description_over .title {
					@media only screen and (min-width: breakpoints.$breakpoint-md) and (max-width: breakpoints.$breakpoint-max-md) {
						font-size: 40px;
					}
				}

				&.teaser-banner {
					.description_over .title {
						@media only screen and (min-width: breakpoints.$breakpoint-sm) and (max-width: breakpoints.$breakpoint-max-sm) {
							max-height: 96px;
						}
						@media only screen and (min-width: breakpoints.$breakpoint-md) and (max-width: breakpoints.$breakpoint-max-md) {
							font-size: 40px;
							line-height: 48px;
							max-height: 96px;
						}
					}

					img {
						@media only screen and (min-width: breakpoints.$breakpoint-md) {
							margin-top: 0 !important;
						}
					}
				}

				&:not(.teaser-banner) {
					img {
						@media only screen and (max-width: breakpoints.$breakpoint-max-xs) {
							margin-top: 0;
						}
					}
				}
			}
		}

		.teaser-banner {
			.teaser.aem-GridColumn--default--12 & {
				.centralized_over .sticker_holder {
					@media only screen and (min-width: breakpoints.$breakpoint-md) {
						display: flex;
						align-items: center;
					}
				}

				img {
					@media only screen and (max-width: breakpoints.$breakpoint-max-md) {
						margin-top: 44px;
					}
				}
			}

			.centralized_over .logo-above-image,
			.centralized_over .logo-below-image {
				.teaser.aem-GridColumn--default--12 & {
					height: 100%;
					width: 170px;
					@media only screen and (min-width: breakpoints.$breakpoint-sm) {
						width: 256px;
					}
					@media only screen and (min-width: breakpoints.$breakpoint-lg) {
						width: 350px;
					}
					@media only screen and (min-width: breakpoints.$breakpoint-xl) {
						width: 368px;
					}
				}

				.teaser.aem-GridColumn--default--6 & {
					.logo img {
						height: 24px !important;
					}
				}
			}

			@media only screen and (max-width: breakpoints.$breakpoint-max-sm) {
				.teaser.aem-GridColumn--default--12 & {
					.centralized_over .logo-above-image {
						justify-content: flex-start;
					}

					.centralized_over .logo-below-image {
						justify-content: flex-end;
					}
				}
			}

			@media only screen and (max-width: breakpoints.$breakpoint-max-xs) {
				.teaser.aem-GridColumn--default--12 .cmp-teaser .centralized_over .logo-above-image .logo-image-margin img {
					margin-top: 0;
				}
			}

			.centralized_over {
				.sticker_holder:not(.sdx-sticker).logo-above-title {
					.teaser & {
						.logo-image-margin {
							margin-top: 32px;
							@media only screen and (min-width: breakpoints.$breakpoint-sm) {
								margin-top: 16px;
							}
						}
					}
				}

				.sticker_holder.sdx-sticker.logo-above-title {
					.teaser & {
						.logo-image-margin {
							@media only screen and (max-width: breakpoints.$breakpoint-max-sm) {
								margin-top: 0;
							}
						}
					}

					.teaser.aem-GridColumn--default--6 & {
						.logo-image-margin {
							@media only screen and (max-width: breakpoints.$breakpoint-max-md) {
								margin-top: 0;
								img {
									margin-top: 44px;
								}
							}
						}
					}
				}

				.logo-above-title {
					display: flex;
					align-items: center;

					.teaser.aem-GridColumn--default--12 & {
						img {
							width: 170px;
							height: 100%;
							@media only screen and (min-width: breakpoints.$breakpoint-sm) {
								width: 300px;
							}
							@media only screen and (min-width: breakpoints.$breakpoint-lg) {
								width: 350px;
							}
							@media only screen and (min-width: breakpoints.$breakpoint-xl) {
								width: 400px;
							}
						}

						@media only screen and (max-width: breakpoints.$breakpoint-max-sm) {
							align-items: flex-start;
						}
					}

					.teaser.aem-GridColumn--default--6 & {
						.logo-image-margin {
							@media only screen and (max-width: breakpoints.$breakpoint-max-xs) {
								margin-top: 32px;
							}
						}
					}
				}
			}

			.description_over .logo-above-title {
				.logo {
					margin-bottom: 8px;
					@media only screen and (min-width: breakpoints.$breakpoint-md) {
						margin-bottom: 16px;
					}

					.teaser.aem-GridColumn--default--6 & {
						@media only screen and (max-width: breakpoints.$breakpoint-max-md) {
							margin-bottom: 8px;
						}
					}

					img {
						@media only screen and (max-width: breakpoints.$breakpoint-max-md) {
							margin-top: 0;
						}

						.teaser.aem-GridColumn--default--6 & {
							height: 24px !important;
						}
					}
				}
			}
		}
	}

	&.sc-teaserMultiElement.sc-teaser-content-banner {
		.img-wrapper[style] {
			min-height: 452px !important;
			@media screen and (min-width: breakpoints.$breakpoint-sm) {
				min-height: 586px !important;
			}
		}

		.img-wrapper[style].has-sticker {
			@media screen and (min-width: breakpoints.$breakpoint-md) {
				min-height: 280px !important;
			}
			@media screen and (min-width: breakpoints.$breakpoint-lg) {
				min-height: 240px !important;
			}
		}

		.img-wrapper[style]:not(.has-sticker) {
			@media screen and (min-width: breakpoints.$breakpoint-md) {
				min-height: 240px !important;
			}
			@media screen and (min-width: breakpoints.$breakpoint-lg) {
				min-height: 240px !important;
			}
		}

		.description_over {
			padding-bottom: 20px;
			padding-left: 24px;

			.title {
				margin-bottom: 8px;
			}

			.title-description p {
				margin-bottom: 0;
			}

			&.description-sdx-sticker {
				@media screen and (min-width: breakpoints.$breakpoint-lg) {
					padding-bottom: 8px;
					top: 50%;
					-ms-transform: translateY(-50%);
					transform: translateY(-50%);
					height: fit-content;
				}
			}

			&:not(.description-sdx-sticker) {
				@media screen and (min-width: breakpoints.$breakpoint-md) {
					padding-bottom: 8px;
					top: 50%;
					-ms-transform: translateY(-50%);
					transform: translateY(-50%);
					height: fit-content;
				}
			}

			@media only screen and (min-width: breakpoints.$breakpoint-md) {
				width: 48%;
			}
			@media only screen and (min-width: breakpoints.$breakpoint-ul) {
				width: 55%;
			}
		}

		.centralized_over,
		.teaser-banner .centralized_over {
			justify-content: center;
			@media only screen and (min-width: breakpoints.$breakpoint-md) {
				justify-content: flex-end;
			}

			.sticker_holder {
				margin-right: 0;

				&:not(.sdx-sticker) {
					@media only screen and (max-width: breakpoints.$breakpoint-max-sm) {
						height: 100%;
					}

					img {
						margin-top: 0;
					}
				}

				&.sdx-sticker {
					margin-right: 0 !important;
					@media only screen and (max-width: breakpoints.$breakpoint-max-sm) {
						height: 100%;
					}
					@media only screen and (max-width: breakpoints.$breakpoint-max-md) {
						.sticker-ribbon-mobile,
						.sticker-mobile {
							display: block;
						}

						.sticker-desktop {
							display: none;
						}
					}
				}

				@media only screen and (max-width: breakpoints.$breakpoint-max-sm) {
					width: 100%;
					display: flex;
					justify-content: center;
				}
				@media screen and (min-width: breakpoints.$breakpoint-md) {
					max-width: 50%;
				}

				img {
					width: auto;
					height: 186px;
					@media only screen and (min-width: breakpoints.$breakpoint-sm) {
						height: 317px;
					}
					@media only screen and (min-width: breakpoints.$breakpoint-md) {
						height: 240px;
						max-width: 460px;
						margin-top: 40px;
					}
					@media only screen and (min-width: breakpoints.$breakpoint-lg) {
						margin-top: 0;
						width: auto;
						max-width: 551px;
					}
					@media only screen and (max-width: breakpoints.$breakpoint-max-md) {
						margin-top: 40px;
					}
				}
			}
		}

		.margin-top-3 {
			margin-top: 0 !important;
		}
	}

	&.cmp-teaser_fullbleed {
		left: 50%;
		margin-left: -50vw;
		margin-right: -50vw;
		width: 100vw;
		right: 50%;

		.page:not(.home-page)
			.teaser.aem-GridColumn--default--12:not(.sc-teaser-text-central-alignment):not(.sc-teaser-text-central-alignment-bottom):not(
				.sc-teaser-text-central-alignment-top
			)
			& {
			.teaser-banner {
				.description_over {
					@media screen and (min-width: breakpoints.$breakpoint-md) and (max-width: breakpoints.$breakpoint-max-lg),
						screen and (min-width: breakpoints.$breakpoint-ul) {
						margin-left: auto;
					}
				}
			}

			.teaser-banner.picture .description_over {
				@media screen and (min-width: breakpoints.$breakpoint-md) and (max-width: breakpoints.$breakpoint-max-md) {
					width: auto;
					margin-left: 32px;
				}
				@media screen and (min-width: breakpoints.$breakpoint-lg) and (max-width: breakpoints.$breakpoint-max-lg) {
					margin-left: 36px;
				}
			}

			@media screen and (min-width: breakpoints.$breakpoint-md) and (max-width: breakpoints.$breakpoint-max-lg),
				screen and (min-width: breakpoints.$breakpoint-ul) {
				.description_over {
					left: 0;
					right: 0;
					margin: 0 auto;
					width: auto;
					max-width: 1380px;

					.description_over_wrapper {
						width: 54%;
					}
				}
			}

			.teaser-banner {
				.description_over {
					@media screen and (min-width: breakpoints.$breakpoint-md) and (max-width: breakpoints.$breakpoint-max-md) {
						margin-left: 32px;
					}
					@media screen and (min-width: breakpoints.$breakpoint-lg) and (max-width: breakpoints.$breakpoint-max-lg) {
						margin-left: 36px;
					}
				}
			}
		}
	}

	.testimonial-desc {
		.sc-teaser-text-central-alignment & {
			h4,
			div.subtitle,
			p {
				text-align: center;
			}
		}
	}

	.description_over {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		padding: 0 24px 24px 24px;
		@media only screen and (min-width: breakpoints.$breakpoint-md) {
			width: 90%;
			padding: 0 32px 40px 32px;
		}
		@media only screen and (min-width: breakpoints.$breakpoint-lg) {
			width: 85%;
			padding: 0 32px 32px 32px;
		}
		@media only screen and (min-width: breakpoints.$breakpoint-xl) {
			width: 66%;
		}
		@media only screen and (max-width: breakpoints.$breakpoint-max-sm) {
			button {
				width: 100%;
			}
		}

		.aem-GridColumn--default--4 & {
			width: 100%;
			@media screen and (min-width: breakpoints.$breakpoint-md) and (max-width: breakpoints.$breakpoint-max-xl) {
				.title {
					font-size: 32px;
					line-height: 40px;
					letter-spacing: -1px;
				}
			}
		}

		.aem-GridColumn--default--6 & {
			@media screen and (min-width: breakpoints.$breakpoint-md) and (max-width: breakpoints.$breakpoint-max-lg) {
				width: 100%;
			}
			@media screen and (min-width: breakpoints.$breakpoint-xl) {
				width: 85%;
			}
		}

		.sc-teaser-text-central-alignment &,
		.sc-teaser-text-central-alignment-bottom &,
		.sc-teaser-text-central-alignment-top & {
			width: 100%;

			.description_over_wrapper {
				text-align: center;
				margin: 0 auto;
				width: 90%;

				> * {
					text-align: center;
				}
			}
		}

		.sc-teaser-text-central-alignment & {
			top: 50%;
			-ms-transform: translateY(-50%);
			transform: translateY(-50%);
			height: fit-content;
			padding: 32px;
		}

		.sc-teaser-text-central-alignment-bottom & {
			bottom: 0;
		}

		.sc-teaser-text-central-alignment-top & {
			top: 32px;
		}

		.subtitle {
			font-size: 18px;
			line-height: 24px;
			letter-spacing: -0.1px;
			margin-bottom: 8px;
			@media only screen and (min-width: breakpoints.$breakpoint-sm) {
				font-size: 24px;
				line-height: 32px;
				margin-bottom: 4px;
			}
			@media only screen and (min-width: breakpoints.$breakpoint-md) {
				letter-spacing: -0.35px;
			}
			@media only screen and (min-width: breakpoints.$breakpoint-lg) {
				line-height: 28px;
				letter-spacing: -0.6px;
			}
		}

		.title {
			font-size: 24px;
			font-weight: 700;
			line-height: 32px;
			letter-spacing: -0.35px;
			width: 100%;
			margin-bottom: 16px;

			@media only screen and (min-width: breakpoints.$breakpoint-sm) {
				font-size: 32px;
				line-height: 40px;
				letter-spacing: -1px;
				margin-bottom: 20px;
			}
			@media only screen and (min-width: breakpoints.$breakpoint-md) {
				font-size: 40px;
				line-height: 48px;
				letter-spacing: -1.2px;
				margin-bottom: 22px;
			}
		}

		.title-description {
			font-size: 24px;
			font-weight: 300;
			line-height: 1.5;
			letter-spacing: -0.96px;
			width: 100%;
			margin-bottom: 24px;

			&.no-action {
				margin-bottom: 0;
			}

			@media only screen and (min-width: breakpoints.$breakpoint-sm) {
				font-size: 32px;
			}
		}

		.subtitle,
		.title,
		.title-description {
			&:empty {
				display: none;
			}
		}

		.description_over_wrapper {
			.title.word-wrap {
				hyphens: auto;
			}
		}
	}

	.landscape-image {
		.description_over {
			.subtitle {
				@media only screen and (min-width: breakpoints.$breakpoint-sm) {
					line-height: 28px;
					letter-spacing: -0.6px;
				}
			}

			.title {
				@media only screen and (min-width: breakpoints.$breakpoint-md) {
					font-size: 32px;
					line-height: 40px;
					letter-spacing: -1px;
				}
				@media only screen and (min-width: breakpoints.$breakpoint-lg) {
					font-size: 40px;
					line-height: 48px;
					letter-spacing: -1.2px;
				}
			}
		}

		&.teaser-banner,
		.teaser.aem-GridColumn--default--6 &.teaser-banner {
			.centralized_over {
				.sticker_holder {
					img {
						@media only screen and (max-width: breakpoints.$breakpoint-max-xs) {
							margin-top: 24px;
						}
					}
				}
			}
		}
	}

	&.sc-teaserPicture {
		.picture,
		.teaser-banner.picture {
			.description_over {
				.teaser.sc-teaser-text-central-alignment &,
				.teaser.sc-teaser-text-central-alignment-bottom &,
				.sc-teaser-text-central-alignment-top & {
					width: 100%;
					margin-left: 0;
				}
			}
		}
	}

	&:not(.sc-teaserStandard) {
		@media only screen and (max-width: breakpoints.$breakpoint-max-xs) {
			.centralized_over .sticker_holder img {
				height: 170px;
			}

			.landscape-image .centralized_over .sticker_holder img {
				height: 172px;
			}
		}
	}

	.centralized_over {
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: flex-start;
		width: 100%;
		top: 0;
		overflow: hidden;

		@media only screen and (min-width: breakpoints.$breakpoint-lg) {
			align-items: center;
		}

		.sticker_holder {
			@media only screen and (max-width: breakpoints.$breakpoint-max-xs) {
				display: flex;
				justify-content: center;
			}

			.sticker-ribbon-mobile {
				display: none;
			}

			&.sdx-sticker {
				.sticker-ribbon-mobile {
					display: none;
					font-size: 16px;
					line-height: 1.5;
					letter-spacing: -0.23px;
					font-weight: 400;
					width: 100%;
					position: absolute;
					top: 0;
					left: 0;
					text-align: center;
					max-height: 40px;
					padding: 8px;
					overflow: hidden;

					sdx-icon {
						margin-right: 4px;
					}

					&.bg-orchid {
						background-color: colors.$color-orchid;
					}

					&.bg-sc-white {
						border: 1.5px solid colors.$color-sc-navy;
						background-color: colors.$color-horizon-tint-2;
						font-weight: 600;
					}

					&.txt-orchid {
						color: colors.$color-orchid;
					}

					&.txt-sc-white {
						color: colors.$color-white;
					}

					&.txt-sc-navy {
						color: colors.$color-sc-navy;
					}
				}

				.sticker-mobile {
					display: none;
				}

				.sticker-desktop {
					display: block;
				}
			}

			@media only screen and (max-width: breakpoints.$breakpoint-max-md) {
				&.sdx-sticker {
					.sticker-ribbon-mobile,
					.sticker-mobile {
						display: block;
					}

					.sticker-desktop {
						display: none;
					}
				}
			}

			@media only screen and (max-width: breakpoints.$breakpoint-max-lg) {
				&.sdx-sticker {
					.aem-GridColumn--default--4 & {
						.sticker-ribbon-mobile,
						.sticker-mobile {
							display: block;
						}

						.sticker-desktop {
							display: none;
						}
					}
				}
			}

			.sticker-mobile {
				display: none;
			}

			.sticker-desktop {
				display: block;
			}
		}
	}

	&.sc-teaserPromotional {
		.teaser-banner .centralized_over {
			.teaser.aem-GridColumn--default--12 & {
				height: auto;
			}
		}
	}

	.teaser-banner {
		.teaser.aem-GridColumn--default--12 & {
			.centralized_over {
				height: 100%;
				@media only screen and (min-width: breakpoints.$breakpoint-lg) {
					justify-content: flex-end;
				}
			}

			.centralized_over .sticker_holder {
				width: 172px;
				margin: 0 auto 14px auto;
				@media only screen and (min-width: breakpoints.$breakpoint-sm) {
					width: 256px;
					margin: 0 auto 18px auto;
					display: flex;
					justify-content: center;
				}
				@media only screen and (min-width: breakpoints.$breakpoint-md) {
					width: 400px;
					height: 400px;
					margin-right: 3.8%;
					display: block;
				}
				@media only screen and (min-width: breakpoints.$breakpoint-lg) {
					margin-left: 0;
				}
				@media only screen and (min-width: breakpoints.$breakpoint-xl) {
					margin: 0 14.82% 0 0;
				}

				&:not(.sdx-sticker) {
					@media only screen and (min-width: breakpoints.$breakpoint-lg) {
						margin-right: 3.8%;
					}
					@media only screen and (min-width: breakpoints.$breakpoint-xl) {
						margin-right: 14.82%;
					}

					img {
						@media only screen and (max-width: breakpoints.$breakpoint-max-sm) {
							margin-top: 16px;
						}
						@media only screen and (max-width: breakpoints.$breakpoint-max-xs) {
							margin-top: 24px;
						}
					}

					.logo-image-margin {
						img {
							margin-top: 0;
						}
					}

					.logo {
						img {
							margin-top: 0;
						}
					}
				}
			}

			.description_over {
				width: 100%;
				display: block;
				padding-left: 4.94%;
				padding-right: 4.94%;

				@media only screen and (min-width: breakpoints.$breakpoint-sm) {
					justify-content: center;
					padding-bottom: 0;
					margin: auto 0;
					padding-bottom: 24px;
				}
				@media only screen and (min-width: breakpoints.$breakpoint-md) {
					height: 100%;
					width: 53.6%;
					margin-left: 3.2%;
					display: flex;
					flex-direction: column;
					padding-left: 0;
					padding-right: 0;
					margin-top: 0;
					margin-bottom: 0;
				}
				@media only screen and (min-width: breakpoints.$breakpoint-lg) {
					width: 50.6%;
					margin-left: 3.8%;
				}
				@media only screen and (min-width: breakpoints.$breakpoint-xl) {
					width: 36%;
					margin-left: 14.82%;
				}

				@media only screen and (max-width: breakpoints.$breakpoint-max-sm) {
					.title-description:not(.show-description-mobile) {
						display: none;
					}
					.subtitle.hide-pretitle-mobile {
						display: none;
					}
				}
				@media only screen and (max-width: breakpoints.$breakpoint-max-xs) {
					.title {
						text-align: left;
					}
					.title,
					button {
						width: 100%;
					}
				}

				.subtitle {
					@media only screen and (min-width: breakpoints.$breakpoint-sm) {
						font-size: 18px;
						line-height: 24px;
						letter-spacing: -0.1px;
					}
					@media only screen and (min-width: breakpoints.$breakpoint-md) {
						font-size: 24px;
						line-height: 28px;
						letter-spacing: -0.6px;
					}
				}

				.title {
					font-size: 24px;
					line-height: 32px;
					letter-spacing: -0.35px;
					max-height: 64px;
					overflow: hidden;
					margin-bottom: 16px;
					@media (min-width: breakpoints.$breakpoint-md) {
						font-size: 40px;
						line-height: 48px;
						letter-spacing: -1.2px;
						max-height: 144px;
						overflow: hidden;
						width: auto;
						margin-left: auto;
						margin-right: auto;
					}
					@media (min-width: breakpoints.$breakpoint-lg) {
						font-size: 54px;
						line-height: 64px;
						letter-spacing: -1.35px;
						max-height: 192px;
						margin-bottom: 19px;
					}
				}
			}

			&.landscape-image {
				.description_over {
					@media only screen and (min-width: breakpoints.$breakpoint-md) and (max-width: breakpoints.$breakpoint-max-lg) {
						width: 50%;
					}

					.subtitle {
						@media only screen and (min-width: breakpoints.$breakpoint-sm) {
							font-size: 24px;
							line-height: 28px;
							letter-spacing: -0.6px;
						}
					}

					.title {
						@media (min-width: breakpoints.$breakpoint-sm) {
							font-size: 40px;
							line-height: 48px;
							letter-spacing: -1.2px;
						}
						@media (min-width: breakpoints.$breakpoint-lg) {
							font-size: 54px;
							line-height: 64px;
							letter-spacing: -1.35px;
						}
					}
				}
			}

			img {
				@media only screen and (min-width: breakpoints.$breakpoint-md) {
					height: auto;
					width: 87.6%;
				}
				@media only screen and (min-width: breakpoints.$breakpoint-xl) {
					height: auto;
					width: 100%;
				}
				@media only screen and (max-width: breakpoints.$breakpoint-max-sm) {
					margin-top: 10px;
				}
				@media only screen and (max-width: breakpoints.$breakpoint-max-xs) {
					width: auto;
					margin-top: 44px;
				}
			}

			&:not(.landscape-image) .countdown-container {
				@media only screen and (min-width: breakpoints.$breakpoint-md) {
					height: auto;
					width: 87.6%;
					img {
						height: auto;
						width: 100%;
					}
				}
			}

			.logo-above-image .logo-image-margin,
			.logo-below-image .logo-image-margin {
				img {
					height: auto;
					width: 100%;
				}
			}
		}

		.teaser.aem-GridColumn--default--12:not(.sc-teaser-text-central-alignment):not(.sc-teaser-text-central-alignment-bottom):not(
				.sc-teaser-text-central-alignment-top
			)
			& {
			//explicit for teaser-banner.picture to hide rules for screens less than 768px
			&.picture {
				.description_over {
					padding-left: 0;
					padding-right: 0;
					width: 64.2%;
					margin-left: 3.2%;
					@media only screen and (min-width: breakpoints.$breakpoint-lg) {
						width: 50.6%;
						margin-left: 3.8%;
					}
					@media only screen and (min-width: breakpoints.$breakpoint-xl) {
						width: 34%;
						margin-left: 14.82%;
					}
					@media only screen and (min-width: breakpoints.$breakpoint-md) {
						top: 49.7%;
						-ms-transform: translateY(-50%);
						transform: translateY(-50%);
					}

					.subtitle {
						font-size: 24px;
						margin-bottom: 14px;
					}

					.title {
						margin-bottom: 16px;

						@media only screen and (max-width: breakpoints.$breakpoint-max-xs) {
							hyphens: manual;
						}
					}
				}
			}
		}

		.teaser.aem-GridColumn--default--12:not(.sc-teaser-text-central-alignment):not(.sc-teaser-text-central-alignment-bottom):not(
				.sc-teaser-text-central-alignment-top
			)
			& {
			.description_over {
				@media only screen and (min-width: breakpoints.$breakpoint-md) {
					align-items: flex-start;
				}
			}
		}
	}

	&.cmp-teaser_fullbleed .teaser-banner {
		.teaser.aem-GridColumn--default--12 & {
			.description_over,
			.centralized_over {
				@media only screen and (min-width: breakpoints.$breakpoint-ul) {
					left: 0;
					right: 0;
					margin: 0 auto;
					width: auto;
					max-width: 1380px;
				}
			}

			.description_over .description_over_wrapper {
				@media only screen and (min-width: breakpoints.$breakpoint-ul) {
					width: 40%;
					margin-left: 12px;
				}
			}

			.centralized_over {
				@media only screen and (min-width: breakpoints.$breakpoint-ul) {
					justify-content: flex-end;
					align-items: center;
				}

				.sticker_holder.sdx-sticker {
					@media only screen and (min-width: breakpoints.$breakpoint-ul) {
						margin-right: 90px;
					}
				}

				.sticker_holder:not(.sdx-sticker) {
					@media only screen and (min-width: breakpoints.$breakpoint-ul) {
						margin-right: 8px;
					}
				}
			}
		}
	}

	&.sc-teaserPromotional .teaser-banner img {
		.teaser.aem-GridColumn--default--12 & {
			@media only screen and (min-width: breakpoints.$breakpoint-md) {
				width: 100%;
			}
			@media only screen and (max-width: breakpoints.$breakpoint-max-xs) {
				width: 100%;
			}
		}
	}
}

// FIXME: Replace with has-light-background
.text-white .cmp-teaser {
	:not(button, .countdown *),
	li.list--link::before,
	.actions > sdx-icon,
	ul.list li::before,
	ol.list li::before {
		color: colors.$color-white;
	}

	li.list--link {
		a {
			color: colors.$color-white;
		}
	}

	.sc-teaserTestimonial-container .testimonial-desc .icon {
		-webkit-text-fill-color: white;
	}
}

.text-navy .cmp-teaser .description_over,
.text-navy .cmp-teaser .sc-teaserTestimonial-container,
.text-navy .cmp-teaser .teaser-standard-container {
	:not(button),
	li.list--link::before,
	.actions > sdx-icon,
	ul.list li::before,
	ol.list li::before {
		color: colors.$color-navy;
	}
}

.pretitle-navy .cmp-teaser .description_over .subtitle,
.pretitle-navy .cmp-teaser .sc-teaserTestimonial-container .subtitle,
.pretitle-navy .cmp-teaser .teaser-standard-container .pretitle {
	color: colors.$color-navy;
}

.icon-white .cmp-teaser.sc-teaserStandard .image-icon-container span,
.icon-white .cmp-teaser.sc-teaserWide .image-icon-container span {
	color: colors.$color-white;
}

.gradient-light .cmp-teaser .img-wrapper > .gradient {
	display: block;
	background: linear-gradient(0deg, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0.2) 49%, rgba(255, 255, 255, 0) 100%);
	@media only screen and (min-width: 769px) {
		background: linear-gradient(
			90deg,
			rgba(255, 255, 255, 0.6) 0%,
			rgba(255, 255, 255, 0.55) 50%,
			rgba(255, 255, 255, 0.3) 84%,
			rgba(255, 255, 255, 0) 100%
		);
	}
}

.gradient-dark .cmp-teaser .img-wrapper > .gradient {
	display: block;
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.2) 49%, rgba(0, 0, 0, 0) 100%);
	@media only screen and (min-width: 769px) {
		background: linear-gradient(90deg, rgba(32, 30, 55, 0.4) 0%, rgba(32, 30, 55, 0.25) 50%, rgba(32, 30, 55, 0.1) 84%, rgba(32, 30, 55, 0) 100%);
	}
}

.teaser-rounded-corners {
	border-radius: 160px;
}

.teaser-badge {
	position: absolute;
	padding: 2px 25px;
	display: inline-block;
	font-size: 16px;
	left: 50%;
	transform: translateX(-50%);
	white-space: nowrap;
	top: 0;
	letter-spacing: 0.06px;

	&.teaser-badge-left {
		left: 0;
		transform: none;
	}

	&.teaser-badge-right {
		left: auto;
		right: 0;
		transform: none;
	}

	&.teaser-badge-turquoise {
		color: colors.$color-white;
		background-color: colors.$color-turquoise;
	}

	&.teaser-badge-navy {
		color: colors.$color-white;
		background-color: colors.$color-navy;
	}

	&.teaser-badge-ribbon {
		font-weight: 500;
		letter-spacing: 0;
		font-size: 16px;
		line-height: 24px;
		top: calc(-13.2px);
		z-index: 1;
		padding: 0 24px;
		border-radius: 12px;
		color: colors.$color-sc-white;
	}
}

.sc-teaserTitleIcon {
	.teaserIcon-container {
		display: flex;
		flex-direction: row;
		padding: 47px 28px 32px 28px;
		height: 100%;

		.aem-GridColumn--default--4 & {
			padding-right: 28px;
		}

		@media only screen and (min-width: breakpoints.$breakpoint-sm) {
			padding-right: 60px;
		}
		@media only screen and (min-width: breakpoints.$breakpoint-md) {
			padding-right: 90px;
		}

		@media screen and (max-width: breakpoints.$breakpoint-max-sm) {
			flex-direction: column;
			align-items: center;
			sdx-icon.teaser-sdx-icon {
				margin: 0 0 24px 0;
			}
		}

		sdx-icon {
			margin-right: 28px;
		}

		sdx-icon.padding-top-34 {
			padding-top: 34px !important;
		}

		&.teaser-icon-pretitle {
			padding-top: 22px;
		}

		.teaser-icon-content {
			display: flex;
			flex-direction: column;

			h4 {
				font-size: 32px;
				line-height: 38px;
				letter-spacing: -0.8px;
				margin-bottom: 12px;
			}

			.subtitle {
				font-size: 18px;
				line-height: 32px;
				letter-spacing: -0.26px;
				margin-bottom: 8px !important;
			}

			div.font {
				margin-bottom: 32px;
			}
		}

		.content-bottom-section {
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
		}
	}
}

.sc-teaserWide {
	.sc-teaser-wide-container {
		display: flex;
		padding: 32px 28px 40px 28px;
		@media screen and (max-width: breakpoints.$breakpoint-max-xs) {
			flex-direction: column;
		}

		.cmp-teaser__image {
			margin-right: 32px;
			@media screen and (max-width: breakpoints.$breakpoint-max-xs) {
				text-align: center;
				margin-right: 0;
			}
		}

		img {
			margin-top: auto;
			margin-bottom: auto;

			&.cmp-image__image {
				width: 48px;
			}
		}

		.image-icon-container {
			text-align: center;
			font-size: 48px;
			margin-right: 28px;
			@media screen and (max-width: breakpoints.$breakpoint-max-xs) {
				margin-bottom: 8px;
				margin-right: 0;
			}
		}

		.teaser-wide-content {
			display: flex;
			flex-direction: column;
			width: 100%;

			@media only screen and (min-width: breakpoints.$breakpoint-lg) {
				flex-direction: row;
				justify-content: space-between;
			}

			h4 {
				letter-spacing: -0.13px;
			}

			.subtitle {
				font-size: 16px;
				line-height: 24px;
				letter-spacing: -0.09px;
			}

			.sc-teaser-wide-button {
				margin-top: 32px;
				@media screen and (min-width: breakpoints.$breakpoint-lg) {
					margin-top: 0;
				}

				a {
					border-bottom: none;
				}
			}

			.teaser-wide-text {
				padding-top: 8px;
				@media only screen and (min-width: breakpoints.$breakpoint-lg) {
					padding-top: 0;
				}
			}
		}
	}
}

.sc-teaserTestimonial {
	.img-wrapper {
		background-position: center 50%;
		background-repeat: no-repeat;
		background-size: cover;
		@media only screen and (max-width: breakpoints.$breakpoint-max-xs) {
			min-height: 344px !important;
		}
	}

	.testimonial-bg-img {
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
	}

	.sc-teaserTestimonial-container {
		display: flex;
		justify-content: flex-end;
		width: 80%;
		margin: 0 auto;
		@media screen and (min-width: breakpoints.$breakpoint-sm) {
			div.subtitle {
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 1;
				overflow: hidden;
			}
			p {
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				overflow: hidden;

				&.sc-teaserTestimonial-quote {
					-webkit-line-clamp: 4;
					margin-bottom: 16px;
				}
			}
		}
		@media screen and (max-width: breakpoints.$breakpoint-max-sm) {
			.testimonial-desc.testimonial-desc {
				margin: 24px 0 0 0;
			}
		}
		@media screen and (max-width: breakpoints.$breakpoint-max-md) {
			flex-direction: column;
			width: 100%;
			.testimonial-desc {
				margin: 16px 48px 0 48px;
				.sc-teaser-newline {
					font-size: 28px;
					line-height: 32px;
					letter-spacing: -0.75px;
					font-weight: 600;
				}
				.icon {
					float: left;
					font-size: 28px;
					margin: 4px 10px 0 0;
				}

				> span > p {
					margin-bottom: 32px;
				}
			}

			.cmp-teaser_image-container.cmp-teaser_image-container {
				margin-top: 32px;
			}
		}

		@media screen and (min-width: breakpoints.$breakpoint-lg) {
			height: 409px;
			.testimonial-desc {
				margin: 10px 0 0 32px;

				.icon {
					float: left;
					font-size: 40px;
					margin: 6px 10px 0 0;
				}
			}
		}

		.cmp-teaser_image-container {
			align-self: center;
			flex: 0 0 auto;
			width: 206px;
			height: 206px;
			overflow: hidden;
			display: inline-block;
			position: relative;
			margin-top: -16px;

			@media screen and (max-width: breakpoints.$breakpoint-max-md) {
				width: 206px;
				height: 206px;
			}
			.cmp-teaser_image {
				height: 100%;

				img {
					margin: 0 auto;
					display: block;
					height: 100%;
					width: 100%;
					object-fit: cover;
					border-radius: 50%;
				}
			}
		}
	}
}

.sc-teaserNavigation {
	a {
		overflow: hidden;
		transition: background-color 0.5s ease;
		display: flex;
		height: 100%;

		&:hover {
			background-color: colors.$color-horizon;
		}

		ul.list.actions {
			margin-bottom: 0;
			position: absolute;
			bottom: 24px;
			font-weight: 400;

			&:not(.navigation-no-img) li {
				margin-left: 0;
			}

			&.navigation-no-img {
				left: 0;
				margin-left: 22px;

				li[class*='icon-']::before {
					margin-left: -15px;
				}
			}

			margin-left: 16px;
			@media screen and (min-width: breakpoints.$breakpoint-lg) {
				margin-left: 0;
			}

			li {
				font-weight: 400;
				margin-bottom: 0;

				&[class*='icon-']::before {
					margin-left: -15px;
					@media screen and (min-width: breakpoints.$breakpoint-lg) {
						margin-left: 0;
					}
				}
			}
		}

		.no-description {
			display: flex;

			.cmp-teaser__image {
				margin: auto;
			}
		}

		.margin-bottom-dynamic {
			margin-bottom: 24px;

			.sc-teaser-space-between-desc-link & {
				margin-bottom: 42px;
			}
		}
	}
}

.sc-teaser-img-left .sc-teaserDefault {
	.cmp-teaser__image {
		margin: 0 32px 0 0;
		@media screen and (max-width: breakpoints.$breakpoint-max-md) {
			float: none;
			margin: 0;
			width: 100%;
		}
	}

	ul.list {
		margin-left: 0;

		li[class*='icon-'] {
			margin-left: 0;

			&::before {
				margin-left: 0;
			}
		}
	}
}

.sc-teaserPromotional {
	padding: 27px 16px 32px 16px;

	.teaser-banner .centralized_over .sticker_holder {
		.teaser.aem-GridColumn--default--12 & {
			margin-right: -16%;
		}
	}

	h4 {
		letter-spacing: -0.75px;
	}

	.button-full-width {
		width: 100%;
	}

	.sdx-sticker-circle-container {
		position: relative;

		.sticker_holder.sdx-sticker {
			width: 469px;
			height: 200px;
		}
	}

	.sc-teaserPromotional-content {
		&.sc-teaserPromotional-with-sticker {
			@media screen and (min-width: breakpoints.$breakpoint-lg) {
				padding-top: 150px;
			}
		}

		.sc-teaserPromotional-footer {
			display: flex;
			justify-content: space-between;

			.sc-teaserPromotional-price {
				display: flex;
				flex-direction: column;
			}

			.button {
				max-height: 48px;
				align-self: flex-end;
			}

			a {
				border-bottom: none;
			}
		}
	}
}

.sc-teaserNews {
	padding: 36px 16px 32px 16px;

	h4 {
		letter-spacing: -0.75px;
		color: colors.$color-navy;
	}

	p:empty {
		display: none;
	}

	ul.list li a {
		letter-spacing: -0.1px;
		color: colors.$color-file-word;
	}

	.cmp-teaser__image {
		margin-bottom: 0;
	}
}

.cmp-card-border {
	border-top: 4px solid colors.$color-navy;
}

.sc-teaserStandard,
.sc-teaserDefault,
.sc-teaserTitleIcon {
	.link-align-bottom {
		position: absolute;
		bottom: 24px;
	}

	ul.list {
		margin-bottom: 0;

		&.actions li {
			margin-bottom: 0;
		}

		.sc-teaser-img-left & {
			left: 48%;
		}
	}

	.margin-bottom-64 {
		margin-bottom: 64px;
	}

	.margin-bottom-46 {
		margin-bottom: 46px;
	}

	.cmp-image__image {
		display: flex;
	}
}

.sc-teaserStandard {
	display: flex;
	flex-direction: column;

	&.teaser-clickable {
		display: block;
	}

	&.teaser-hover {
		transition:
			border-color 200ms ease-in-out 0s,
			box-shadow 200ms ease-in-out 0s;

		&:hover {
			border-color: var(--sdx-color-gray-tint-4);
			box-shadow: color-mix(in srgb, var(--sdx-color-gray) 16%, transparent) 0px 4px 16px 0px;
		}
	}

	&:not(.teaser-clickable),
	.standard-combined-clickable {
		@include image-positions();
	}

	.sc-teaser-img-right &:not(.teaser-clickable).teaser-style-img-adjust-position,
	.sc-teaser-img-left &:not(.teaser-clickable).teaser-style-img-adjust-position,
	.sc-teaser-img-left &.teaser-style-img-adjust-position .standard-combined-clickable,
	.sc-teaser-img-right &.teaser-style-img-adjust-position .standard-combined-clickable {
		@media screen and (max-width: breakpoints.$breakpoint-max-sm) {
			flex-direction: column;
			.centralized_over {
				width: 100% !important;
				height: auto !important;
			}
			.zero-padding.teaser-standard-container[class*='padding-h-'] {
				padding-left: 0 !important;
				padding-right: 0 !important;
			}
		}
		@media screen and (min-width: breakpoints.$breakpoint-md) {
			.zero-padding.teaser-standard-container[class*='padding-v-'] {
				padding-top: 0 !important;
			}
			.sticker_holder {
				align-self: flex-start;
			}
		}
	}

	.sc-teaser-img-left &:not(.teaser-clickable).teaser-style-img-adjust-position,
	.sc-teaser-img-left &.teaser-style-img-adjust-position .standard-combined-clickable {
		.zero-padding.teaser-standard-container[class*='padding-h-'] {
			padding-right: 0 !important;
		}
	}

	.sc-teaser-img-right &:not(.teaser-clickable).teaser-style-img-adjust-position,
	.sc-teaser-img-right &.teaser-style-img-adjust-position .standard-combined-clickable {
		.zero-padding.teaser-standard-container[class*='padding-h-'] {
			padding-left: 0 !important;
		}
	}

	.standard-combined-clickable {
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	.teaser-standard-container {
		height: 100%;
		display: flex;
		flex-direction: column;

		.content-bottom-section,
		.call-me-back-container {
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			margin-top: 24px;

			&.add-text-left {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
			}

			.add-text-right {
				display: flex;
			}

			div.list.actions > a,
			.teaserStandard-button > a {
				text-decoration: none;
			}

			a .action-text:focus,
			a .action-text:hover {
				text-decoration: underline;
				text-decoration-thickness: 2px;
			}
		}
	}

	sdx-sticker-circle {
		.aem-GridColumn--default--3 & {
			.text-h1.text-compact {
				line-height: 42px;
			}

			.text-h2.text-compact {
				line-height: 34px;
			}
		}
	}

	& > a .content-bottom-section {
		.action-text:focus,
		.action-text:hover {
			text-decoration: underline;
			text-decoration-thickness: 2px;
		}
	}

	.additional-desc-right {
		display: inline-block;
	}

	.teaserStandard-button:not(:last-of-type) {
		margin-bottom: 16px;
	}

	.image-icon-container {
		text-align: center;
		font-size: 120px;
		@media screen and (min-width: breakpoints.$breakpoint-sm) {
			font-size: 140px;
		}
	}

	.button-full-width {
		width: 100%;
	}

	.centralized_over {
		position: relative;
		overflow: initial;

		.sticker_holder:not(.sticker-only) {
			display: flex;
			justify-content: center;
			overflow-x: hidden;
			width: 100%;
			height: 100%;
		}

		.sticker-only {
			width: 469px;
			height: 40px;
			@media screen and (min-width: breakpoints.$breakpoint-lg) {
				height: 200px;
			}
		}

		img {
			width: 100%;
			object-fit: cover;
		}

		.sdx-sticker img {
			height: 469px;
			width: auto;
		}
	}

	.subtitle {
		font-size: 18px;
	}

	.pretitle {
		font-size: 18px;
		font-weight: 400;
		letter-spacing: -0.6px;
		width: 100%;
	}

	.teaser-badge {
		z-index: 1;
	}

	.modCallFromNet {
		color: #333;
	}

	.gradient-title {
		background: radial-gradient(110% 220% at 0% 140%, #13a3f4 0%, rgba(22, 158, 255, 0.5) 40%, rgba(16, 136, 222, 0) 100%),
			radial-gradient(290% 800% at 220% -440%, #dd1122 30%, #001155 100%);
		border-radius: 12px 12px 0 0;
		box-shadow: 0px -1px 8px rgba(0, 0, 0, 0.16);
	}
}

.sc-teaserDevice {
	@media screen and (min-width: breakpoints.$breakpoint-lg) {
		flex-direction: column;

		.teaser-device-container {
			padding-top: 0 !important;
		}
	}

	small {
		display: block;
		line-height: 1.3;
	}

	.teaser-badge {
		z-index: 1;
	}

	&.teaser-hover {
		transition: background-color 0.5s ease;

		&:hover {
			background-color: colors.$color-horizon !important;
			box-shadow: 0px 4px 16px rgba(8, 106, 219, 0.5) !important;
			transition: 0.5s ease;
		}
	}

	.device-clickable {
		display: flex;
		flex-direction: row;

		@media screen and (min-width: breakpoints.$breakpoint-lg) {
			flex-direction: column;
			height: 100%;
		}
	}

	.teaser-device-container {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;

		h4 {
			margin-bottom: 16px;
		}

		.content-bottom-section {
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;

			&.add-text-left {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
			}

			.add-text-right {
				display: flex;
			}

			div.list.actions > a,
			.teaserStandard-button > a {
				text-decoration: none;
			}

			a .action-text:focus,
			a .action-text:hover {
				text-decoration: underline;
				text-decoration-thickness: 2px;
			}
		}
	}

	& > a .content-bottom-section {
		.action-text:focus,
		.action-text:hover {
			text-decoration: underline;
			text-decoration-thickness: 2px;
		}
	}

	.teaserDevice-button:not(:last-of-type) {
		margin-bottom: 16px;
	}

	.button-full-width {
		width: 100%;
	}

	.centralized_over {
		position: relative;
		overflow: initial;
		width: auto;
		@media screen and (min-width: breakpoints.$breakpoint-lg) {
			padding-bottom: 0px !important;
		}

		img {
			width: auto;
			height: 104px;
			@media screen and (min-width: breakpoints.$breakpoint-md) {
				height: 160px;
			}
		}
	}

	.pretitle {
		font-size: 18px;
		font-weight: 400;
		letter-spacing: -0.6px;
		width: 100%;
	}
}

.teaser-standard-container {
	:not(.teaserStandard-button:not(button)) {
		transition: color 200ms ease-in-out 0s;
		.hover-navy-text:hover & {
			color: colors.$color-navy;
		}

		.hover-white-text:hover & {
			color: colors.$color-white;
		}

		.hover-grey-text:hover & {
			color: colors.$color-gray;
		}

		.hover-blue-text:hover & {
			color: colors.$color-blue;
		}
	}
}

.sc-teaserAbout .underlined-link,
.sc-teaserPicture .underlined-link,
.sc-teaserMultiElement .underlined-link,
.sc-teaserStandard .underlined-link {
	text-decoration: underline;

	&:hover,
	&:focus {
		text-decoration-thickness: 2px;
	}
}

.sc-teaser-newline {
	display: block;
}

.sc-teaserResProduct {
	display: flex;
	flex-direction: column;

	.button-full-width {
		width: 100%;
	}

	.content-container {
		height: 100%;
		display: flex;
		flex-direction: column;

		&.padding-top-40 {
			padding-top: 40px !important;
		}

		.content-bottom-section {
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			margin-top: 24px;

			ul.list,
			ul.list li {
				margin-bottom: 0;
			}
		}

		.table {
			width: calc(100% + 16px);
			margin-left: -8px;

			tbody {
				font-size: 18px;
				line-height: 24px;
				letter-spacing: -0.1px;
				font-weight: 400;
			}

			td:first-child {
				padding-left: 8px;
			}

			td {
				padding-top: 16px;
				padding-bottom: 16px;
			}
		}
	}

	ul.list {
		font-weight: 400;
	}

	.teaserStandard-button:not(:last-of-type) {
		margin-bottom: 16px;
	}
}

.sc-teaserResProduct {
	li.link-icon {
		margin-left: 0px;

		& a {
			vertical-align: top;
		}
	}

	li.link-icon::before {
		display: none !important;
	}
}

.sc-teaserStandard,
.sc-teaserDevice {
	.content-bottom-section {
		.link-icon .action-text {
			vertical-align: top;
		}

		.list.actions:not(:last-of-type) {
			margin-bottom: 8px;
		}
	}
}

.teaser-fixed-height-image {
	.aem-GridColumn--default--4 & {
		.cmp-teaser__image,
		.teaser-standard-fixed-image {
			overflow: hidden;
			display: flex;
			justify-content: center;

			img {
				width: auto;
				height: 152px;
				@media only screen and (min-width: breakpoints.$breakpoint-sm) {
					height: 312px;
				}
				@media only screen and (min-width: breakpoints.$breakpoint-md) {
					height: 224px;
				}
			}
		}
	}

	.aem-GridColumn--default--3 & {
		.cmp-teaser__image,
		.teaser-standard-fixed-image {
			overflow: hidden;
			display: flex;
			justify-content: center;

			img {
				width: auto;
				height: 152px;
				@media only screen and (min-width: breakpoints.$breakpoint-sm) {
					height: 312px;
				}
				@media only screen and (min-width: breakpoints.$breakpoint-md) {
					height: 112px;
				}
				@media only screen and (min-width: breakpoints.$breakpoint-lg) {
					height: 144px;
				}
				@media only screen and (min-width: breakpoints.$breakpoint-xl) {
					height: 168px;
				}
			}
		}
	}
}
